<div class="col-md-12 bg-primary" style="height: 5px">&nbsp;</div>
<div class="container">
  <div class="row">
  <div class="col-md-12 py-3">
    <div class="d-flex justify-content-center py-2">
      <img  src="./assets/images/Taaj-Logo.svg" alt="TAAJPAY" width="150">
    </div>
  <h2 class="text-center text-secondary py-2">We are Under Maintenance... </h2>
  <p class="text-center py-2"> We apologize for the inconvenience. The website is under maintenance right now. <br> We will be online as soon as possible. Please check back again in a little while.  <br>Thank you for your patience. </p>
  </div>
  </div>
</div>


<!--Under Maintances IMG-->
<div class="container">
  <div class="row">
  <div class="col-md-6 mt-3">
    <div class="d-flex justify-content-center">
      <img class="" src="./assets/img/under-maintenance.png" alt="TAAJPAY" width="400">
    </div>
  </div>

    <!-- CONTACT US  & TIMER-->
    <div class="col-md-6 mt-3">

      <!-- counter -->
      <div class="col-md-12 align-self-center justify-content-center mx-auto">
        <div class="row mt-5">
        <div class="col-md-12">
          <h5> We will be back in...</h5>
        </div>
        <div class="col-md-3" style="border-right:1px solid #d2d2d2">
          <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="isLoading"></mat-progress-spinner>
       <h1> {{countDown?.remainingPeriod.nrOfDays}}</h1>
          <small>Days remaining</small>
        </div>
          <div class="col-md-3" style="border-right:1px solid #d2d2d2">
            <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="isLoading"></mat-progress-spinner>
            <h1> {{countDown?.remainingPeriod.nrOfHours}}</h1>
            <small>Hours remaining</small>
          </div>
          <div class="col-md-3" style="border-right:1px solid #d2d2d2">
            <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="isLoading"></mat-progress-spinner>
            <h1> {{countDown?.remainingPeriod.nrOfMinutes}}</h1>
            <small>Minutes remaining</small>
          </div>
          <div class="col-md-3">
            <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="isLoading"></mat-progress-spinner>
            <h1> {{countDown?.remainingPeriod.nrOfSeconds}}</h1>
            <small>Seconds remaining</small>
          </div>
          </div>


<!--  FOR ENQUIRY -->
<!--<div class="row py-4 mt-5">-->
<!--  <p class="d-inline mx-2"><u>For Enquiry</u></p>-->
<!--  <div class="d-inline mx-2">-->
<!--    <ul class="list-unstyled">-->
<!--      <li class="mb-3"><i class="fa fa-envelope"></i> <a href="mailto:customerservice@safaripay.com"> customerservice@safaripay.com</a> </li>-->
<!--    </ul>-->
<!--  </div>-->
<!--</div>-->

    </div>
  </div>
  </div>
</div>



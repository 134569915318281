import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomersComponent } from './customers.component';
import { AuthGuard } from '../../shared/auth.gaurd';
import { CustomerResolver } from '../customers/shared/customer.resolver';
import { SenderAddInfoViewResolver } from '../remittance/shared/senderadditionalview.resolver';
import { MynotificationResolver } from './shared/mynotification.resolver';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {OtpverificationComponent} from './components/otpverification/otpverification.component';
import {SignupBankidComponent} from "./components/signup-bankid/signup-bankid.component";
import {MigrateonlinecustomerComponent} from "./components/migrateonlinecustomer/migrateonlinecustomer.component";
import {
  TwoFactorAuthenticationComponent
} from "./components/twofactorauthentication/two-factor-authentication.component";

const customerRoutes: Routes = [
  {
    path: 'customers',
    component: CustomersComponent,
    children: [
      {
        path: 'signup',
        loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule),
      },
      {
        path: 'signupcompleted',
        loadChildren: () => import('./components/signupcompleted/signupcompleted.module').then(m => m.SignupcompletedModule),
      },
      {
        path: 'signupemailverify',
        loadChildren: () => import('./components/signupemailverify/signupemailverify.module').then(m => m.SignupemailverifyModule),
      },
      {
        path: 'signupemailverified',
        loadChildren: () => import('./components/signupemailverified/signupemailverified.module').then(m => m.SignupemailverifiedModule),
      },
      {
        path: 'signupemailnotverified',
        loadChildren: () => import('./components/signupemailnotverified/signupemailnotverified.module').then(m => m.SignupemailnotverifiedModule),
      },
      {
        path: 'changepassword',
        loadChildren: () => import('../../../app/modules/customers/components/changepassword/changepassword.module').then(m => m.ChangepasswordModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mydetails',
        loadChildren: () => import('../../../app/modules/customers/components/mydetails/mydetails.module').then(m => m.MydetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mynotifications',
        loadChildren: () => import('../../../app/modules/customers/components/my-notifications/my-notifications.module').then(m => m.MyNotificationsModule),
        resolve: { customerNotifications: MynotificationResolver},
        canActivate: [AuthGuard]
      },
      {
        path: 'forgotpassword',
        loadChildren: () => import('./components/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule)
      },
      {
        path: 'forgotpasswordsent',
        loadChildren: () => import('./components/forgotpasswordsendmail/forgotpasswordsendmail.module').then(m => m.ForgotpasswordsendmailModule)
      },
      {
        path: 'resetpassword',
        loadChildren: () => import('./components/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule)
      },
      {
        path: 'resetpassworddone',
        loadChildren: () => import('./components/resetpassworddone/resetpassworddone.module').then(m => m.ResetpassworddoneModule)
      },
      {
        path: 'resendverifyemail',
        loadChildren: () => import('./components/resendverifyemail/resendverifyemail.module').then(m => m.ResendverifyemailModule )
      },
      {
        path: 'resetexpirypassword',
        loadChildren: () => import('./components/resetexpiredpassword/resetexpiredpassword.module').then(m => m.ResetexpiredpasswordModule)
      },
      {
        path: 'otpverification',
        component: OtpverificationComponent,
        resolve: {customerUser: CustomerResolver}
      },
      {
        path: 'signup-bankid',
        children: [
          {
            path: '**',
            component: SignupBankidComponent,
            // canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'migrateonlinecustomer',
        component: MigrateonlinecustomerComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'twofactorauthentication',
        component: TwoFactorAuthenticationComponent,
        resolve: {customerUser: CustomerResolver},
        canActivate: [AuthGuard]
      }
      ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(customerRoutes)
    ],
  exports: [
    RouterModule
  ]
})
export class CustomersRoutingModule { }

import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RemittanceService } from './remittance.service';
import {AppDataService} from '../../../shared/app-data.service';
import {PayingCountry} from '../../../models/payingcountry';
import {RemittanceSubType, RemittanceType} from '../../../models/remittancetype';
import {SendingCountry} from '../../../models/sendingcountry';
import { from } from 'rxjs';

@Injectable()
export class AddRecipientViewResolver implements Resolve<any> {

  selectedPayingCountry: PayingCountry;
  selectedRemittanceType: RemittanceType;
  selectedRemittanceSubType: RemittanceSubType;
  selectedSendingCountry: SendingCountry;
  
    constructor(private remittanceService: RemittanceService, private appDataService: AppDataService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

      this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
      this.selectedPayingCountry = this.appDataService.getSelectedPayingCountry();
      this.selectedRemittanceType = this.appDataService.getSelectedRemittanceType();
      this.selectedRemittanceSubType = this.appDataService.getSelectedRemittanceSubType();
      
        return from(this.remittanceService.getAddRecipientView_Api(this.selectedSendingCountry.country.countryId, this.selectedPayingCountry.country.countryId
        , this.selectedRemittanceType.remittanceTypeId, this.selectedRemittanceSubType.remittanceSubTypeId));
    }
}

<div>
  <h1 matDialogTitle><img src="assets/img/check.png" height="30" width="30" i18n/>Success</h1>

  <mat-dialog-content>
      {{data.message}}
  </mat-dialog-content>

  <mat-dialog-actions>
      <button mat-raised-button matDialogClose i18n>Close</button>
  </mat-dialog-actions>
</div>

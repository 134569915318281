import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { User } from '../../models/user';
import { AuthService } from '../../shared/auth.service';
import { Utilities } from '../../shared/utilities';
import { ApiResponse } from '../../models/apiresponse';
import { Subscription } from 'rxjs';
import { SendingCountry } from '../../models/sendingcountry';
import { AppDataService } from '../../shared/app-data.service';
import { RegExesContUs } from '../../shared/regexes';
import { CustomValidators } from '../../shared/CustomValidators';
import { RegExes } from '../../shared/regexes';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorComponent } from '../../components/error/error.component';
import { SuccessComponent } from '../../components/success/success.component';
import { GoogleAnalyticsService } from '../../shared/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
declare let fbq: Function;

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit, AfterViewInit {

  name: string;
  showHide: Boolean;
  form: FormGroup;
  disableSubmitButton = false;
  selectedSenderCountrySubscription: Subscription;
  selectedSendingCountry: SendingCountry;
  content = { 'location': '', 'number': '', 'email': '' };
  isBrowser: boolean;
  contentResult: any;  
  constructor(private fb: FormBuilder, private appDataService: AppDataService,
    private router: Router, private route: ActivatedRoute, private authService: AuthService,
    public dialog: MatDialog, private titleService: Title, private meta: Meta, private googleAnalyticsService: GoogleAnalyticsService, @Inject(PLATFORM_ID) platformId: Object) {
    this.showHide = false;
    this.titleService.setTitle('Contact Us | Taaj');
    this.meta.updateTag({
      name: 'description',
      content: 'If you are looking to get in contact with Taaj, find out which support number to call for your country now, or feel free to drop us an email.'});
    this.isBrowser = isPlatformBrowser(platformId);
  }

  changeShowStatus() {
    this.showHide = !this.showHide;
  }
  ngOnInit() {
    this.form = this.fb.group({
      sendFrom: [null],
      contactName: [null, [Validators.required, Validators.pattern(RegExesContUs.contactName), Validators.minLength(4), Validators.maxLength(50), Validators.pattern(RegExes.validEntry)]],
      contactEmail: [null, [Validators.email]],
      contactPhone: [null, [Validators.required, Validators.pattern(RegExesContUs.contactPhone), Validators.maxLength(50)]],
      contactMessage: [null, [Validators.required, Validators.pattern(RegExesContUs.contactMessage), Validators.maxLength(1000)]]
    });

    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
        if (this.contentResult) {
          this.content = this.contentResult.find(list => list.sendingCountryId === this.selectedSendingCountry.country.countryId);
        }
        if(!this.content){
          this.content = this.contentResult.find(list => list.sendingCountryId === null);   
        }
      }
    );
    this.appDataService.getSiteContent_Api('contactus')
      .then((apiResp: ApiResponse) => {
        if (apiResp.err) {
          console.log(apiResp.err);
          return;
        }
        if (apiResp.result) {
          this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
          this.contentResult = apiResp.result.contactUs;
          this.content = this.contentResult.find(list => list.sendingCountryId === (this.selectedSendingCountry ? this.selectedSendingCountry.country.countryId : null));
        }
        if(!this.content){
          this.content = this.contentResult.find(list => list.sendingCountryId === null);   
        }
      })
      .catch((apiException: ApiResponse) => {
        console.log(apiException);
      });

    if (this.isBrowser) {
      this.loadFacebookPixelCode();
    }
  }

  loadFacebookPixelCode() {
    fbq('track', 'PageView');
    fbq('track', 'Contact');
  }
  contactUs() {
    if (this.form.valid) {
      this.disableSubmitButton = true;
      const formValues = this.form.value;
      const subject = 'Taaj web portal customer inquiry';
      const contactName = formValues.contactName;
      const contactEmail = formValues.contactEmail;
      const contactPhone = formValues.contactPhone;
      const contactMessage = formValues.contactMessage;
      this.authService.sendEmail(subject, contactName, contactEmail, contactPhone, contactMessage)
        .then((apiResp: ApiResponse) => {
          if (apiResp.err) {
            this.disableSubmitButton = false;
            this.dialog.open(ErrorComponent, {
              data: {
                message: apiResp.err.message.toString()
              }
            });
            return;
          }
          if (apiResp.result) {
            this.form.reset();
            this.disableSubmitButton = false;
            this.dialog.open(SuccessComponent, {
              data: {
                message: 'Message sent successfully'
              }
            });
          }
        })
        .catch((apiException: ApiResponse) => {
          this.disableSubmitButton = false;
          this.dialog.open(ErrorComponent, {
            data: {
              message: (apiException && apiException.err && apiException.err.message) ? apiException.err.message.toString() : apiException
            }
          });
        });
    }
  }


  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
  }

}

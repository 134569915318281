import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RemittanceService } from './remittance.service';
import { from } from 'rxjs';

@Injectable()
export class SenderAddInfoViewResolver implements Resolve<any> {
    constructor(private remittanceService: RemittanceService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return from(this.remittanceService.getSenderAddInfoView_Api());
    }
}

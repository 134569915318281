import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class Covid19Component implements OnInit {

  constructor( private titleService: Title, private meta: Meta) { 
    this.titleService.setTitle( "Update on COVID-19 | Taaj" );
    this.meta.updateTag({ name: 'description', content: 'Taaj is with you 24/7 to assist you and continue to support you and your loved ones during COVID-19 restrictions. Learn more now about how we can help.'});
  }

  ngOnInit() {
  }

}

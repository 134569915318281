<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>



<div class="position-relative overflow-hidden bg-light">
  <div class="col-md-12 mx-auto">
    <h2 class="p-4 text-center" i18n>Enter OTP</h2>
  </div>
</div>
<div class="container my-5">
  <div class="row">
    <div class="col-md-6 my-5 py-3 bg-light" style="box-shadow: 0 10px 9px 0 rgba(0, 0, 0, 0.2); border-radius: 5px;">
      <div class="d-flex justify-content-center">
        <img src="../../../../../assets/img/Icons/lock.png" class="img-fluid" alt="Forgot password" width="100">
      </div>
      <form name="login-form" [formGroup]="form" *ngIf="form" id="login-form" class="form-horizontal ng-pristine ng-invalid ng-touched my-4" novalidate autocomplete="off"
            (ngSubmit)="sendOtpVerification()">
        <div class="form-group">
          <label class="control-label col-md-12 d-flex justify-content-center control-label" i18n>Please Enter the code we just sent you.
            <span class="asteriskField">*</span>
          </label>
          <div class="col-md-6 mx-auto controls">
            <input #otp type="password" name="otp" id="otp" class="form-control" i18n-placeholder="Enter OTP *" autofocus formControlName="otp"
                   required>
            <app-showerror [control]="form.get('otp')"></app-showerror>
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-2 mx-auto"></div>
          <div class="col-md-6 mx-auto controls Signup">
            <button type="submit" name="login" id="regularButton" class="btn btn-success btn-lg btn-block" [disabled]="!form.valid || disableSubmitButton" i18n>Submit</button>
          </div>
          <div class="col-md-2 mx-auto" *ngIf="canResendOtp">
            <button [disabled]="disabled" class="mt-3 float-right" style="color: #007bff; text-decoration: none;background-color: transparent;" (click)="resendOtp()">Resend OTP?</button><br>
          </div>
        </div>
      </form>

    </div>
    <div class="col-md-2"></div>
    <div class="col-md-4 py-5">
      <ul class="list-group">
        <li class="list-group-item list-group-item-success"><a i18n>Need Help?</a></li>
        <li class="list-group-item greencolor"><a routerLink="/help" i18n>Customer Support 24/7</a></li>
        <li class="list-group-item"><a routerLink="/faqs" title="FAQ's" i18n>FAQ's</a></li>
        <li class="list-group-item greencolor"><a routerLink="/contactus" title="Contact us" i18n>Contact us</a></li>

      </ul>
    </div>
  </div>
</div>

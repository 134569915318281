import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { PayingCountry } from '../..//models/payingcountry';
import { ActivatedRoute, Router } from '@angular/router';
import { checkCountryIdValue, instantPayingCountries } from '../../shared/constants';
import { SendingCountry } from '../../models/sendingcountry';
import { AppDataService } from '../../shared/app-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from '../error/error.component';
import { Subscription } from 'rxjs';
import { HomeComponent } from '../home/home.component';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { jquery } from 'jquery';
import {User} from '../../models/user';
import {UserSessionService} from '../../shared/usersession.service';
import {Utilities} from "../../shared/utilities";
import {environment} from "../../../environments/environment";
declare var $: any;
declare let fbq: Function;

@Component({
  selector: 'app-homeclient',
  templateUrl: './homeclient.component.html',
  styleUrls: ['./homeclient.component.css']
})
export class HomeclientComponent implements OnInit {
  isBrowser: boolean;

  @ViewChild(HomeComponent) homeparent;

  payingCountriesList: PayingCountry[];
  selectedSendingCountry: SendingCountry;
  instantPayingCountriesList: PayingCountry[];
  pCountryId: any;
  selectedSenderCountrySubscription: Subscription;
  message: any;
  showProgress: boolean;
  instantPayingCountry: PayingCountry[];
  currentUserSendingCountryDoesNotExists = false;
  currentUserSubscription: Subscription;
  currentUser: User;
  sendingCountryList: SendingCountry[];

  constructor(private route: ActivatedRoute, private appDataService: AppDataService, private router: Router,
              public dialog: MatDialog, @Inject(PLATFORM_ID) platformId: Object, private userSessionService: UserSessionService) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

  ngOnInit() {
    this.currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
    this.currentUserSubscription = this.userSessionService.currentUserSubject.subscribe(
      (value: User) => {
        this.currentUser = value;
      });
    this.showProgress = true;
    this.InstantDeliverCountryList();
    if(!this.currentUser) {
      this.selectedSendingCountry = null;
    }
      this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
        (value: SendingCountry) => {
          this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
          this.payingCountriesList = this.selectedSendingCountry.payingCountries;
        },
        (err) => {
          this.dialog.open(ErrorComponent, {
            data: {
              message: err.toString()
            }
          });
        }
      );

    // Reinitializing bootstrap corousal on route change
    if (this.isBrowser) {
      $(document).ready(function() {
        $('.carousel').carousel();
       });
    }
  }

  InstantDeliverCountryList() {
    this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
    if (this.selectedSendingCountry) {
      this.showProgress = false;
      this.payingCountriesList = this.selectedSendingCountry.payingCountries;
      this.instantPayingCountriesList = [];
      instantPayingCountries.forEach(pCountry => {
        this.instantPayingCountry = this.payingCountriesList.filter(list => list.country.countryId === pCountry.pCountryId);
          this.instantPayingCountriesList.push(this.instantPayingCountry[0]);
      });
     // this.instantPayingCountriesList = this.payingCountriesList.slice(0, 6);
      return;
    } else {
      setTimeout(() => {
        this.InstantDeliverCountryList();
      }, 1000);
    }
  }

  selectedInstantPayingCountry(instantPayingCountry) {
    this.currentUserSendingCountryDoesNotExists = false;
    if (this.currentUser) {
      this.currentUserSendingCountryDoesNotExists = true;
      this.sendingCountryList = this.appDataService.getSendingCountriesList();
      this.sendingCountryList.forEach(sCountry => {
        if (sCountry.country.countryId === this.currentUser.countryId) {
          this.currentUserSendingCountryDoesNotExists = false;
        }
      });
    }
    if (this.currentUserSendingCountryDoesNotExists) {
      this.sendingCountryList = [];
      this.dialog.open(ErrorComponent, {
        data: {
          message: 'Remittances from your registered sending country is temporarily stopped! Please check back later.'
        }
      });
      return;
    }
    this.pCountryId = instantPayingCountry.country.countryId;
    this.appDataService.getPayingCountryRemittanceTypes_Api(this.selectedSendingCountry.country.countryId, this.pCountryId)
      .then(pCountry => {
        this.appDataService.setSelectedPayingCountry(pCountry);
        setTimeout(() => {
          this.router.navigate(['/send-money/' + pCountry.country.countryName.toLowerCase().replace(/[ ,�]+/g, '-') + '']);
        }, 1000);
      })
      .catch(err => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      });
  }

  onClickAppStoreButton() {
    fbq('track', 'PageView');
    fbq('track', 'ViewContent');
    console.log('App store button clicked');
  }

  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
  }

}

import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomersRoutingModule } from './customers-routing.module';
import { CustomersComponent } from './customers.component';
import { CustomerService } from './customers.service';
import { CustomerResolver } from './shared/customer.resolver';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
import { MynotificationResolver } from './shared/mynotification.resolver';
import { MatDialogModule } from '@angular/material/dialog';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {RouterModule} from '@angular/router';
import {DeactivateuseraccountComponent} from './components/deactivateuseraccount/deactivateuseraccount.component';

import { RestrictcopyDirective } from '../../shared/restrictcopy.directive';
import { OtpverificationComponent } from './components/otpverification/otpverification.component';
import { NgxLoadingModule } from 'ngx-loading';
import { TwoFactorAuthenticationComponent } from "./components/twofactorauthentication/two-factor-authentication.component";
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {SharedModule} from "../../shared/shared.module";
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CustomersRoutingModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        NgxLoadingModule,
        MatDialogModule,
        MatStepperModule,
        MatInputModule,
        MatRadioModule,
        SharedModule
    ],
  declarations: [
    CustomersComponent,
    DeactivateuseraccountComponent,
    OtpverificationComponent,
    RestrictcopyDirective,
    TwoFactorAuthenticationComponent
  ],
  providers: [CustomerService, CustomerResolver, MynotificationResolver, {
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: environment.reCaptchaSiteKey } as RecaptchaSettings,
  }
  ],
  entryComponents: [
  ],
})
export class CustomersModule { }

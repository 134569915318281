import { Injectable } from '@angular/core';
import { Recipient } from '../model/recipient';
import { ApiService } from '../../../shared/api.service';
import { RemittanceRequest } from '../model/remittancerequest';
import { AuthService } from '../../../shared/auth.service';
import { Utilities } from '../../../shared/utilities';
import { AppDataService } from '../../../shared/app-data.service';
import { ApiResponse } from '../../../models/apiresponse';
import { Customer } from '../../customers/model/customer';
import { remittanceSubTypes, checkCountryIdValue } from '../../../shared/constants';
import { Remittance } from '../model/remittance';
import { DuplicateRemittance } from '../model/duplicateRemittance';
import { RemittanceOrmRequest } from '../model/remittanceOrmRequest';
import { customerStates } from '../../../shared/constants';
import { User } from '../../../models/user';
import {environment} from "../../../../environments/environment";

@Injectable()
export class RemittanceService {
  private remittanceRequest: RemittanceRequest;
  private selectedRemittances: Remittance[];
  private guid: string;

  constructor(private appDataService: AppDataService, private apiService: ApiService, private authService: AuthService) {
    this.remittanceRequest = this.appDataService.app_data.remittanceRequest || {};
    this.selectedRemittances = this.appDataService.app_data.selectedRemittances || [];
  }

  getRemittanceRequest(): RemittanceRequest {
    return this.remittanceRequest;
  }

  getSelectedRemittances(): Remittance[] {
    return this.selectedRemittances;
  }

  setSelectedRemittances(remittances: Remittance[]) {
    this.selectedRemittances = remittances;
    this.appDataService.set_app_data('selectedRemittances', remittances);
  }

  setRemittanceRequest(guid: string, senderIsSanctioned: boolean, senderCustomer: Customer, remittances: Remittance[], selectedSendingCountryId: number, orderNumber: string, senderMobileBlackListed: boolean, senderNameBlackListed: boolean, senderNameMobileBlackListed: boolean, senderNameCountryBlackListed: boolean, promotionDiscount: number, ormPromotionConfigId: number) {
    this.remittanceRequest = {
      guid: guid,
      senderIsSanctioned: senderIsSanctioned,
      senderId: senderCustomer.customerId,
      senderFirstName: senderCustomer.firstName.trim(),
      senderMiddleName: senderCustomer.middleName ? senderCustomer.middleName : '',
      senderLastName: senderCustomer.lastName.trim(),
      senderMobile: senderCustomer.mobile.toString(),
      senderAddress: senderCustomer.address ? senderCustomer.address : '0',
      senderPostCode: senderCustomer.postCode ? senderCustomer.postCode : '0',
      senderCity: senderCustomer.city ? senderCustomer.city : '0',
      senderCountryId: senderCustomer.countryId,
      senderDateOfBirth: senderCustomer.dateOfBirth ? senderCustomer.dateOfBirth : '',
      senderDocuments: senderCustomer.documents ? senderCustomer.documents : [],
      senderNationalId: senderCustomer.nationalId ? senderCustomer.nationalId : '',
      senderNationalityId: senderCustomer.nationalityId,
      senderBuildingNumber: senderCustomer.buildingNo ? senderCustomer.buildingNo : '0',
      senderState: senderCustomer.state ? senderCustomer.state : '',
      senderPlaceOfBirth: senderCustomer.placeOfBirth ? senderCustomer.placeOfBirth : '',
      senderOccupationId: senderCustomer.occupationId,
      senderSourceOfFundId: senderCustomer.sourceOfFundId,
      remittances: remittances,
      remittanceRequestTypeId: 5,
      paymentTypeId: 1,
      paymentGatewayId: 3,
      isSingleRemittance: true,
      byPassMultipleRemittancesCheck: false,
      resType: 'json',
      selectedSendingCountryId: selectedSendingCountryId,
      orderNumber: orderNumber,
      senderMobileBlackListed: senderMobileBlackListed,
      senderNameBlackListed: senderNameBlackListed,
      senderNameMobileBlackListed: senderNameMobileBlackListed,
      senderNameCountryBlackListed: senderNameCountryBlackListed,
      promotionDiscount: promotionDiscount,
      ormPromotionConfigId: ormPromotionConfigId
    };
    this.appDataService.set_app_data('remittanceRequest', this.remittanceRequest);
  }

  /*  API CALLS*/
  getOrmTransactions_Api(userId: number, numberOfDays: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getormtransactions');
      const payload = { userId: userId, numberOfDays: numberOfDays };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getAddRecipientView_Api(sendingCountryId: number, payingCountryId: number, remittanceTypeId: number, remittanceSubTypeId: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getaddrecipientview');
      const payload = { sendingCountryId: sendingCountryId, payingCountryId: payingCountryId, remittanceTypeId: remittanceTypeId, remittanceSubTypeId: remittanceSubTypeId};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getAddRemittanceView_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getaddremittanceview');
      const payload = {};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  isCustomerInSanctionedList_Api(firstName: string, middleName: string, lastName: string, mobile: string, dateOfBirth: string, customerId: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('customersanctioned');
      const payload = { firstName: firstName, middleName: middleName, lastName: lastName, mobile: mobile, dateOfBirth: dateOfBirth, customerId: customerId};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  viewReceipt_Api(remittanceId: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('viewreceipt');
      const payload = { remittanceId: remittanceId };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getRemittancePurposes_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getremittancepurposes');
      const payload = {};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getReceiverLastRemittanceDetails_Api(senderId: number, receiverId: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getreceiverlastremittancedetails');
      const payload = { senderId: senderId, receiverId: receiverId };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getORMRemittanceByGuid_Api(guid: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getormremittancebyguid');
      const payload = { guid: guid };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  addRecipient_Api(recipient: Recipient): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('addrecipient');
      const payload = {
        senderId: recipient.senderId, receiverId: recipient.receiverId,
        firstName: recipient.receiverFirstName, middleName: recipient.receiverMiddleName, lastName: recipient.receiverLastName,
        mobileNo: recipient.receiverMobile, customerRelationshipId: recipient.customerRelationshipId, remittancePurposeId: recipient.remittancePurposeId,
        city: recipient.receiverCity, countryId: this.appDataService.getSelectedPayingCountry().country.countryId,
        remittanceTypeId: recipient.remittanceTypeId, amount: recipient.amount,
        remittanceSubTypeId: recipient.remittanceSubTypeId ? recipient.remittanceSubTypeId : ((recipient.remittanceTypeId === 1 || recipient.remittanceTypeId === 4) && (checkCountryIdValue.indexOf(this.appDataService.getSelectedPayingCountry().country.countryId) > -1)) ? remittanceSubTypes.SOMALIA : null
        , receiverEMoneyAccount: recipient.bankAccountNo, bankName: recipient.bankName, bankCode: recipient.bankCode
      };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  updateSenderAdditionalInfo_Api(senderCustomer: Customer) {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('updatesenderadditionalinfo');
      const payload = {
        customerId: senderCustomer.customerId, firstName: senderCustomer.firstName,
        middleName: senderCustomer.middleName, lastName: senderCustomer.lastName,
        mobile: senderCustomer.mobile, email: senderCustomer.email, postCode: senderCustomer.postCode,
        buildingNo: senderCustomer.buildingNo, address: senderCustomer.address, city: senderCustomer.city,
        countryId: senderCustomer.countryId, dateOfBirth: senderCustomer.dateOfBirth, isActive: senderCustomer.isActive,
        isSenderAddInfoAvailable: 1, senderDocuments: senderCustomer.documents,
        nationalId: senderCustomer.nationalId, nationalityId: senderCustomer.nationalityId,
        isSenderDocInfoAvailable: senderCustomer.isSenderDocInfoAvailable, state: senderCustomer.state,
        placeOfBirth: senderCustomer.placeOfBirth, occupationId: senderCustomer.occupationId,
        sourceOfFundId: senderCustomer.sourceOfFundId, isSenderAddInfoRequired: senderCustomer.isSenderAddInfoRequired,
        countryStateId: senderCustomer.countryStateId
      };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  updateSenderVerificationInfo_Api(senderCustomer: Customer) {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('updatesenderverificationinfo');
      const payload = {
        customerId: senderCustomer.customerId,
        isSenderAddInfoAvailable: senderCustomer.isSenderAddInfoAvailable,
        senderDocuments: senderCustomer.documents,
        isSenderDocInfoAvailable: 1, customerStateId: customerStates.not_verified
      };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getDocumentTypes_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getdoctypes');
      const payload = {};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  uploadSenderDocument(doc: File, documentSetGUID: string) {
    return new Promise<any>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('uploadsenderdocument');
      const fd = new FormData();
      fd.append('documentSetGUID', documentSetGUID);
      fd.append('files[]', doc, doc.name);
      this.apiService.sendHttpPostRequest_FormData(endpoint, fd)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getSenderAddInfoView_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getsenderaddinfoview');
      const payload = { };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  validateCustomerNationalId_Api(nationalityId: number, nationalId: string) {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('validatecustomernationalid');
      const payload = { senderNationalityId: nationalityId, senderNationalId: nationalId };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  verifymmtaccount_Api(rMobile: string, remittanceType: number, remittanceSubTypeId: number, countryCode: string, actionId: number) {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('verifymmtaccount');
      const payload = { rMobile: rMobile, remittanceType: remittanceType, remittanceSubTypeId: remittanceSubTypeId, countryCode: countryCode, actionId: actionId };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getaddresses_Api(streetPostalCode: string, houseNumber: string) {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getaddresses');
      const payload = { streetPostalCode: streetPostalCode, houseNumber: houseNumber };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getOrmTransferReceipt_Api(orderNumber: string, tranNo: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('generatetransferreceipt');
      const payload = { orderNumber: orderNumber, remittanceId: tranNo };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp) => {
          if (resp.err) {
            resolve(resp);
          }
          if (resp) {
            resolve(resp);
          }
        }).catch(err => {
          reject(err);
        });
    });
  }

  getOrmCustomerLimits_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getormcustomerlimits');
      const payload = { };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getIdVeriSdkToken_Api(documentSetGUID: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getidverisdktokenfrompartner');
      const payload = { documentSetGUID: documentSetGUID};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  verifyDocuments_Api(params: any): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('verifydocuments');
      const payload = { documentSetGUID: params.documentSetGUID, partnerDocTypeId: params.partnerDocTypeId, partnerDocument: params.partnerDocument,
      customerId: params.customerId, primaryIsDefault: params.primaryIsDefault,
        secondaryIsDefault: params.secondaryIsDefault, updateSenderVerificationInfo: 1};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  verifyBankAccount_Api(number: string, remittanceTypeId: number, remittanceSubTypeId: number, countryCode: string, bankCode: string, actionId: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getbankaccountinfo');
      const payload = { number: number, remittanceTypeId: remittanceTypeId, remittanceSubTypeId: remittanceSubTypeId, countryCode: countryCode, bankCode, actionId};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getOrmRequestsByDateForUser_Api(userId: number, dateCreated: Date): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getormrequestsbydateforuser');
      const payload = { userId, dateCreated };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async checkForDuplicateRemittances(remittanceRequest: RemittanceRequest): Promise<DuplicateRemittance[]> {
    if (remittanceRequest.byPassMultipleRemittancesCheck) {
      return [] as DuplicateRemittance[];
    }

    const currentUser: User = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));

    const sameDayRequestsResult = await this.getOrmRequestsByDateForUser_Api(currentUser.userId, new Date());
    if (sameDayRequestsResult.err) {
      throw new Error(sameDayRequestsResult.err);
    }


    const { remittances } = remittanceRequest;
    let duplicateRemittances: DuplicateRemittance[] = [];
    const sameDayOrmRequests = sameDayRequestsResult.result;
    remittances.forEach((remittance: Remittance) => {
      const { receiverFullName, receiverMobile, amount, remittanceSubTypeId } = remittance;

      sameDayOrmRequests.forEach((sameDayOrmRequest: RemittanceOrmRequest) => {
        const existingRemittanceRequest: RemittanceRequest = JSON.parse(sameDayOrmRequest.remittanceRequest);
        const duplicateRemits = existingRemittanceRequest.remittances.filter((newRemittance: Remittance) =>
          Utilities.trimString(newRemittance.receiverFullName).toLowerCase() === Utilities.trimString(receiverFullName).toLowerCase() &&
          newRemittance.receiverMobile === receiverMobile &&
          newRemittance.amount === amount && newRemittance.remittanceSubTypeId === remittanceSubTypeId);

        if (duplicateRemits.length) {
          duplicateRemittances = [...duplicateRemittances, ...duplicateRemits.map((remit: Remittance): DuplicateRemittance =>
            new DuplicateRemittance(sameDayOrmRequest.orderNumber, remit.receiverFullName, remit.receiverMobile, remit.transferAmount,
              remit.sCurrencyCode, remit.receivingAmount, remit.pCurrencyCode, new Date(sameDayOrmRequest.dateCreated).toDateString(), sameDayOrmRequest.ormState)
          )];
        }
      });
    });
    return duplicateRemittances as DuplicateRemittance[];
  }

  ormAccountVerification_Api(defaultValue: string, remittanceType: number, remittanceSubTypeId: number, countryCode: string, bankCode, receiverMobile, actionId: number) {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('ormaccountverification');
 const payload = { defaultValue: defaultValue, remittanceType: remittanceType, remittanceSubTypeId: remittanceSubTypeId, countryCode: countryCode, bankCode: bankCode, receiverMobile: receiverMobile, actionId: actionId};
      if (this.appDataService.getSelectedRemittanceSubType()?.remittanceSubTypeId == 13 || this.appDataService.getSelectedRemittanceSubType()?.remittanceSubTypeId == 19) {
          payload['routingTag'] = this.appDataService.getSelectedBankType()?.branchCode;
      }
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getAciPaymentStatusByGuid_Api(guid: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getacipaymentstatusbyguid');
      const payload = { guid: guid };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

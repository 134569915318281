import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Inject, PLATFORM_ID } from '@angular/core';
import { Router, Data, ActivatedRoute, Params, NavigationEnd, NavigationStart, RoutesRecognized } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SendingCountry } from '../../models/sendingcountry';
import { PayingCountry } from '../../models/payingcountry';
import { AppDataService } from '../../shared/app-data.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessComponent } from '../success/success.component';
import { ErrorComponent } from '../error/error.component';
import { ApiResponse } from '../../models/apiresponse';
import { AuthService } from '../../shared/auth.service';
import { UserSessionService } from '../../../../src/app/shared/usersession.service';
import { User } from '../../../../src/app/models/user';
import { environment } from '../../../../src/environments/environment';
import { Utilities } from '../../../../src/app/shared/utilities';
import { isPlatformBrowser } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { NotificationsComponent } from '../notifications/notifications.component';
import { CustomerService } from '../../../../src/app/modules/customers/customers.service';
declare var $: any;
declare let fbq: Function;

@Component({
  selector: 'app-homegeneral',
  templateUrl: './homegeneral.component.html',
  styleUrls: ['./homegeneral.component.css']
})
export class HomegeneralComponent implements OnInit, OnDestroy {
  form: FormGroup;
  selectedSendingCountry: SendingCountry;
  selectedSenderCountrySubscription: Subscription;
  payingCountriesList: PayingCountry[];
  selectedPayingCountry: PayingCountry;
  selectedPayingCountrySubscription: Subscription;
  showProgress: boolean;
  imgPath = '';
  pCountryId: any;
  sendingCountryList: SendingCountry[];
  imgPath2: string;
  currentUserSubscription: Subscription;
  currentUser: User;
  disableSubmitButton: boolean;
  loadingSendingCountries: boolean;
  isBrowser: boolean;
  previousUrl: string;
  currentUserSendingCountryDoesNotExists = false;
  sci: number;
  pci: number;
  @ViewChild('ramadanBannerEU') ramadanBannerEU: TemplateRef<any>;
  @ViewChild('ramadanBannerCAUsa') ramadanBannerCAUsa: TemplateRef<any>;

  constructor(private fb: FormBuilder, private userSessionService: UserSessionService,
              private appDataService: AppDataService, private customerService: CustomerService,
              private route: ActivatedRoute, private authService: AuthService, private activatedRoute: ActivatedRoute,
              private router: Router, private titleService: Title, public dialog: MatDialog, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.titleService.setTitle( 'Online Money Transfer | Send Money Abroad | Taaj Money Transfer Limited' );
  }

  ngOnInit() {
    this.appDataService.delete_app_data_key('selectedSendingCountry', null);
    this.currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
    this.currentUserSubscription = this.userSessionService.currentUserSubject.subscribe(
      (value: User) => {
        this.currentUser = value;
      });
    this.showProgress = false;
    this.form = this.fb.group({
      ddlPayingCountry: [this.selectedPayingCountry ? this.selectedPayingCountry.country.countryId : null, Validators.required]
    });
    if (this.appDataService.getIsAfterLogin()) {
      this.openNotifications();
      this.appDataService.setIsAfterLogin(false);
    }
    this.imgPath2 = 'assets/images/flagsTaaj/XYZ.png';
    this.imgPath = 'assets/images/flagsTaaj/XYZ.png';
    if (this.currentUser) {
      this.currentUserSendingCountryDoesNotExists = true;
    }
    this.loadingSendingCountries = true;
    this.appDataService.getSendingCountriesList_Api()
      .then(value => {
        this.loadingSendingCountries = false;
        this.sendingCountryList = value;
        this.imgPath2 = 'assets/images/flagsTaaj/XYZ.png';
        if (this.currentUser) {
          this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
          this.sendingCountryList.forEach(sCountry => {
            if (sCountry.country.countryId === this.currentUser.countryId) {
              this.currentUserSendingCountryDoesNotExists = false;
            }
          });
        }
        if (this.currentUserSendingCountryDoesNotExists) {
          this.sendingCountryList = [];
          this.imgPath2 = 'assets/images/flagsTaaj/XYZ.png';
          this.dialog.open(ErrorComponent, {
            data: {
              message: 'Remittances from your registered sending country is temporarily stopped! Please check back later.'
            }
          });
        }
        if (this.activatedRoute.snapshot.queryParams['sci'] && this.activatedRoute.snapshot.queryParams['pci']) {
          setTimeout(() => {
            this.navigateToCalculator();
          }, 1000);
        }
      })
      .catch(err => {
        this.loadingSendingCountries = false;
        console.log('Loading countries failed-- Err is --' + err);
      });
    this.route.data
      .subscribe(
        (data: Data) => {
          if (data.sendingCountry) {
            this.selectedSendingCountry = data.sendingCountry;
            this.payingCountriesList = this.selectedSendingCountry.payingCountries;
            this.selectedPayingCountry = null;
            this.showProgress = false;
            this.imgPath2 = this.selectedSendingCountry ? this.selectedSendingCountry.country.flagUrl : '';
            this.imgPath = 'assets/images/flagsTaaj/XYZ.png';
            this.form.patchValue({
              ddlPayingCountry: this.selectedPayingCountry ? this.selectedPayingCountry.country.countryId : null
            });
          }
        }
      );
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
        this.payingCountriesList = this.selectedSendingCountry.payingCountries;
        this.selectedPayingCountry = null;
        this.showProgress = false;
        // this.imgPath2 = this.selectedSendingCountry ? this.selectedSendingCountry.country.flagUrl : '';
        this.imgPath2 = this.selectedSendingCountry ? 'assets/images/flagsTaaj/' + this.selectedSendingCountry.country.countryName + '.png' : '';
        this.imgPath = 'assets/images/flagsTaaj/XYZ.png';
        this.form.patchValue({
          ddlPayingCountry: this.selectedPayingCountry ? this.selectedPayingCountry.country.countryId : null
        });
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
    this.selectedPayingCountrySubscription = this.appDataService.selectedPayingCountrySubject.subscribe(
      (value: PayingCountry) => {
        this.selectedPayingCountry = this.appDataService.getSelectedPayingCountry();
        // this.imgPath = this.selectedPayingCountry ? this.selectedPayingCountry.country.flagUrl : ''; Temporary comment
        this.imgPath = this.selectedPayingCountry  ? 'assets/images/flagsTaaj/' + this.selectedPayingCountry.country.countryName + '.png' : '';
        this.form.patchValue({
          ddlPayingCountry: this.selectedPayingCountry ? this.selectedPayingCountry.country.countryId : null
        });
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
      this.previousUrl = e[0].urlAfterRedirects; // previous url
      if (this.isBrowser && this.previousUrl === '/customers/signup' && this.currentUser) {
        console.log('----Google conversion code executed----');
        this.loadGoogleConversionScript();
      }
    });
    if (this.isBrowser) {
      this.loadFacebookPixelCode();
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.sci = params['sci'];
      this.pci = params['pci'];
    });
  }

  loadFacebookPixelCode() {
    fbq('track', 'PageView');
  }

  loadGoogleConversionScript() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-646681753/LPj4CNXcos4BEJmprrQC'});";
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
    if (this.selectedPayingCountrySubscription) {
      this.selectedPayingCountrySubscription.unsubscribe();
    }
  }

  onSelectSendingCountry(event): void {
    const sCid = this.sendingCountryList[event.target.selectedIndex].country.countryId;
    this.appDataService.setSelectedSendingCountry(this.sendingCountryList[event.target.selectedIndex]);
  }

  onSelectPayingCountry(event): void {
    this.disableSubmitButton = true;
    // if selected paying country is first option then blank flag set and button disable
    if (event.target.selectedIndex === 0) {
      this.imgPath = 'assets/images/flagsTaaj/XYZ.png';
      return;
    }
    this.pCountryId = this.payingCountriesList[event.target.selectedIndex - 1].country.countryId;
    this.appDataService.getPayingCountryRemittanceTypes_Api(this.selectedSendingCountry.country.countryId, this.pCountryId)
      .then(pCountry => {
        this.appDataService.setSelectedPayingCountry(pCountry);
        this.disableSubmitButton = false;
      })
      .catch(err => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      });
  }

  isActiveUserSession() {
    if (localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString())) {
      if (localStorage.getItem(Utilities.encryptPayLoad2('serverLastActionDateTime',environment.clientKey).toString())) {
        const serverLastActionDateTime = new Date(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('serverLastActionDateTime',environment.clientKey).toString()),environment.clientKey).toString());
        const localStorageExpiryDateTime = Utilities.getLocalStorageExpiryDateTime(serverLastActionDateTime,
          environment.localStorageValidityInMinutes);
        const currentDateTime = new Date();
        if (currentDateTime > localStorageExpiryDateTime) {
          this.authService.isActiveUserSession_Api()
            .then((apiResp: ApiResponse) => {
              if (!(apiResp.result && apiResp.result.length > 0 && apiResp.result[0].isActive === 1)) {
                this.currentUser = null;
              } else {
                this.currentUser = JSON.parse(localStorage.getItem('currentUser').toString());
              }
            })
            .catch((apiException: ApiResponse) => {
              this.currentUser = null;
            });
        } else {
          this.currentUser = JSON.parse(localStorage.getItem('currentUser').toString());
        }
      } else {
        this.currentUser = null;
      }
    }
  }

  start() {
    // console.log(this.pCountryId);
    if (this.form.valid) {
      this.loadFacebookPixelSearchCode();
      if (this.currentUserSendingCountryDoesNotExists) {
        this.dialog.open(ErrorComponent, {
          data: {
            message: 'Remittances from your registered sending country is temporarily stopped! Please check back later.'
          }
        });
        return;
      }
      this.showProgress = false;
      this.router.navigate(['/send-money/' +  this.selectedPayingCountry.country.countryName.toLowerCase().replace(/[ ,�]+/g, '-') + '']);
    }
  }

  loadFacebookPixelSearchCode() {
    fbq('track', 'PageView');
    fbq('track', 'Search');
  }

  onCloseModal() {
    this.dialog.closeAll();
  }

  navigateToCalculator() {
    const sSendingCountry = this.sendingCountryList.find(list => list.country.countryId === Number(this.sci));
    this.appDataService.setSelectedSendingCountry(sSendingCountry);
    setTimeout(() => {
      this.pCountryId = Number(this.pci);
      this.appDataService.getPayingCountryRemittanceTypes_Api(this.selectedSendingCountry.country.countryId, this.pCountryId)
        .then(pCountry => {
          this.appDataService.setSelectedPayingCountry(pCountry);
          setTimeout(() => {
            this.router.navigate(['/send-money/' + pCountry.country.countryName.toLowerCase().replace(/[ ,�]+/g, '-') + '']);
          }, 1000);
        })
        .catch(err => {
          this.dialog.open(ErrorComponent, {
            data: {
              message: err.toString()
            }
          });
        });
    }, 1000);
  }

  ngAfterViewInit() {
  if (this.isBrowser) {
    document.body.scrollTop = 0;
  }
  }

  openEURamadanBanner() {
  }

  openCAUsaRamadanBanner() {
  }

  openNotifications() {
    this.customerService.getCustomerNotification().then((apiResp: ApiResponse) => {
      if (apiResp && apiResp.result && apiResp.result.length > 0) {
        apiResp.result.forEach(element => {
          if (element.readAt == null && !element.expired) {
            this.dialog.open(NotificationsComponent, {
              panelClass: 'custom-dialog-container',
              data: element
            });
          }
        });
      }
    });
  }
}

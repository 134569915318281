<div class="shadow-sm p-3 mb-5 bg-light rounded">
  <h1 class="myaccounth5 py-3" i18n style="color:#1b72ba">Welcome Back, {{ currentUser ? currentUser.firstName: ''}}</h1>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-4">
      <div class="card shadow-sm p-3 mb-5 customerReg rounded">
        <div class="card-body p-1">
          <h5 class="card-title text-dark">Customer Reg No :</h5>
          <div class="card-text">
            <h4 i18n>{{ currentUser.customerId ? currentUser.customerId.toString() : ''}}</h4>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-4">
      <div class="card shadow-sm p-3 mb-5 lastlogn rounded">
        <div class="card-body p-1">
          <h5 class="card-title text-dark">Last Login :</h5>
          <div class="card-text">
            <h4 i18n>{{currentUser ? this.lastLgn: ''}}</h4>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-4">
      <div class="card shadow-sm p-3 mb-5 mbleCrd rounded">
        <div class="card-body p-1">
          <h5 class="card-title text-dark">Mobile No :</h5>
          <div class="card-text">
            <h4 i18n>{{currentUser ? currentUser.mobile:''}}</h4>
          </div>
        </div>
      </div>

    </div>
    </div>
</div>

<div>
   <a routerLink="/customers/mydetails"  class="btn btn-outline-dark btn-sm ml-3 login-btn" href="/customers/mydetails"> View Profile</a>
</div>

<span mat-dialog-title>Duplicate remittances found</span>
<table class="table-hover responstable tableGridHeader" style="font-size: 13px; border-radius: 0px;">
  <tr>
    <th i18n>Recipient Name</th>
    <th i18n>Recipient Mobile</th>
    <th i18n>Sending Amount</th>
    <th i18n>Receiving Amount</th>
    <th i18n>Tran. Date</th>
    <th i18n>Tran. Status</th>
  </tr>
  <tr *ngFor="let remittance of remittances;">
    <td>{{remittance.receiverFullName}}</td>
    <td>{{remittance.receiverMobile}}</td>
    <td>{{remittance.transferAmount}} {{remittance.sCurrencyCode}}</td>
    <td>{{remittance.receivingAmount}} {{remittance.pCurrencyCode}}</td>
    <td >{{remittance.transactionDate}}</td>
    <td >{{remittance.transactionStatus}}</td>
  </tr>
</table>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Continue</button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>

<ng-template #selectCountryModal>
  <div id="selectCountryModal">
    <div mat-dialog-content>
      <div class="container">
        <div class="row" style="background-color: #ffffff;">
          <div class="col-md-12 px-0">
            <form [formGroup]="form" *ngIf="form" id="countrylist-form" class="form-horizontal top-on-mobile" novalidate
              autocomplete="off" (ngSubmit)="start()" style="padding: 0px 15px;">
              <div class="form-row align-items-center calculator">
                <div class="my-1">
                  <div class="input-group">
                    <select id="sendingFrom" class="custom-select custom-select-lg" name="ddlSelectCountry"
                      formControlName="ddlSelectCountry" (change)="onSelectSendingCountry($event)">
                      <option [ngValue]="null" [selected]>Select Your Country</option>
                      <option *ngFor="let sendFromCountry of sendingCountryList"
                        value="{{sendFromCountry?.country?.countryId}}">
                        {{sendFromCountry?.country?.countryName}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
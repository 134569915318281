import { Component, OnInit, AfterViewInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
  selector: 'app-howitworks',
  templateUrl: './howitworks.component.html',
  styleUrls: ['./howitworks.component.css'],
 
})
export class HowitworksComponent implements OnInit, AfterViewInit {

  constructor( private titleService: Title, private meta: Meta, private router: Router) {

  }

  ngOnInit() {
    if (this.router.url === '/HowItWorks') {
      this.titleService.setTitle('How It Works - 6 Easy Steps | Taaj');
      this.meta.updateTag({
        name: 'description',
        content: 'Planning to send money to family or friends? Check how Taaj Works & transfer money with easy steps. Follow the steps and enjoy unlimited online transfers.'});
    }
  }
  ngAfterViewInit() {

  }
}

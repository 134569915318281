import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import DisableDevtool from 'disable-devtool';

if (environment.production) {
  enableProdMode();
}
if (environment.disableDevTool) {
  DisableDevtool({
    url: 'logout'
  });
}

document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
});

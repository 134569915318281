// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  apiProtocol: 'https',
  apiServerIP: 'apisandbox.taaj.app',
  apiServerPort: '443',
  // apiServerIP: '10.2.5.9',
  // apiServerPort: '3000', 
  // apiServerIP: '167.114.24.251',
  // apiServerPort: '96',
  // apiServerIP: '54.39.24.130',
  // apiServerPort: '96',
  localStorageValidityInMinutes: 10,
  logoutsessiontime: 900, // In Seconds
  systemId: 67,
  isReCaptchaEnabled: true,
  reCaptchaSiteKey: '6LdBOY4mAAAAAMiPlbZLFwfdblBj7r5cf_jr_6ls',
  disableDevTool : false,
  // addSecretKeyAndTimestamp: true,
  clientKey: 'MAkqFysABAMGiKBgEBJREExw'
};

export const RegExes = {
  //firstName : '^[a-zA-Z.  ]*$',
  firstName : '^(?! |.*  )[a-zA-Z\/  ]*$',
  middleName : '^(?! |.*  )[a-zA-Z\/  ]*$',
  lastName : '^(?! |.*  )[a-zA-Z\/  ]*$',
  mobileNo : '^[1-9][0-9]*$',
  validEntry : '^(?! |.*  ).{1,50}$',
  // mobileNo : '^[1-9][0-9]{9,12}$'
}


export const RegExesContUs = {
  contactName : '^[a-zA-Z.  ]*$',
  contactPhone : '^[0-9]*$',
  contactMessage : '^(?! |.*  )[a-zA-Z.,  ]*$',
}

import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { PayingCountry } from '../models/payingcountry';
import { AppDataService } from './app-data.service';
import {SendingCountry} from "../models/sendingcountry";

@Injectable()
export class PayingCountryResolver implements Resolve<PayingCountry> {
  constructor(private appDataService: AppDataService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PayingCountry> {
    if (this.appDataService.getSelectedPayingCountry()) {
      return of(this.appDataService.getSelectedPayingCountry());
    } else {
      return from(new Promise<PayingCountry>((resolve, reject) => {
        const selectedSendingCountry: SendingCountry = this.appDataService.getSelectedSendingCountry();
        if (selectedSendingCountry) {
          this.appDataService.getPayingCountryRemittanceTypes_Api
          (selectedSendingCountry.country.countryId, selectedSendingCountry.payingCountries[0].country.countryId)
            .then(payingCountry => {
              this.appDataService.setSelectedPayingCountry(payingCountry);
              resolve(payingCountry);
            })
            .catch(function(err) {
              reject(err);
            });
        } else {
          resolve(null);
        }
      }));
    }
  }
}

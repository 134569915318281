import {Component, OnInit, Inject, AfterViewInit, PLATFORM_ID} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from '../../../../models/apiresponse';
import { AuthService } from '../../../../shared/auth.service';
import { Router } from '@angular/router';
import { ErrorComponent } from '../../../../components/error/error.component';
import { SuccessComponent } from '../../../../components/success/success.component';

@Component({
  selector: 'app-deactivateuseraccount',
  templateUrl: './deactivateuseraccount.component.html',
  styleUrls: ['./deactivateuseraccount.component.css']
})
export class DeactivateuseraccountComponent implements OnInit, AfterViewInit  {
  public loadingDeletionAction = false;
  constructor(public dialogRef: MatDialogRef<DeactivateuseraccountComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private authService: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onDeactivateUserAccount(){
    this.loadingDeletionAction = true;
    this.authService.deactivateUserAccount_Api()
      .then((resp: ApiResponse) => {
        this.dialogRef.close();
	
        if(resp.err) {
          this.dialog.open(ErrorComponent, {
            data: {
              message: resp.err.message.toString()
            }
          });
        } else {
          this.dialog.open(SuccessComponent, {
            data: {
              message: resp.result
            }
          });
          if (this.router.url === '/') {
            this.router.navigateByUrl('');
          } else {
            this.router.navigateByUrl('/');
          }
        }
      })
      .catch((err: ApiResponse) => {
        this.dialogRef.close();
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.err.message.toString()
          }
        });
      });
  }

  ngAfterViewInit() {

  }
}

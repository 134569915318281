<ng-template [ngIf]="!isDashboard">
  <router-outlet></router-outlet>
</ng-template>

<ng-template [ngIf]="isDashboard">
  <nav class="navbar navbar-dark bg-dark flex-md-nowrap p-0 hmBtn-left ">
    <a class="navbar-brand mr-0 px-2" routerLink="/customers/dashboard" >Dashboard</a>
    <a class="navbar-brand mr-0 px-2" routerLink="/" >Send Remittance</a>
    <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse"
            data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!--  <ul class="navbar-nav px-3">-->
    <!--    <li class="nav-item text-nowrap">-->
    <!--      <a class="nav-link" href="#">Sign out</a>-->
    <!--    </li>-->
    <!--  </ul>-->
  </nav>


  <div class="container-fluid">
    <div class="row">
      <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <div class="sidebar-sticky">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link" routerLink="/remittance/transactionhistory" i18n routerLinkActive="active-link"
                 [routerLinkActiveOptions]="{exact:true}" > <i class="fa fa-list px-1"></i>
                My Transfer
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/remittance/recipientlist" i18n routerLinkActive="active-link"
                 [routerLinkActiveOptions]="{exact:true}" >
                <i class="fa fa-file-text px-1"></i>
                My Recipients
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/customers/mynotifications" i18n routerLinkActive="active-link"
                 [routerLinkActiveOptions]="{exact:true}" >
                <i class="fa fa-bell px-1"></i>
                My Notifications
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/remittance/mylimits" i18n routerLinkActive="active-link"
                 [routerLinkActiveOptions]="{exact:true}" >
                <i class="fa fa-tachometer px-1"></i>
                My Limits
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/customers/mydetails" i18n routerLinkActive="active-link"
                 [routerLinkActiveOptions]="{exact:true}" >
                <i class="fa fa-address-book px-1"></i>
                My Details
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/customers/changepassword" i18n routerLinkActive="active-link"
                 [routerLinkActiveOptions]="{exact:true}" >
                <i class="fa fa-key px-1"></i>
                Change Password
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/remittance/senderverification" i18n
                 [class.red-color]="currentUser.isSecondaryDocRequired == 1 || currentUser.isSenderDocInfoAvailable != 1" >
                <i class="fa fa-id-card px-1"></i>
                Upload Documents
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/customers/twofactorauthentication" i18n routerLinkActive="active-link"
                 [routerLinkActiveOptions]="{exact:true}">
                <i class="fa fa-lock fa-lg px-1"></i>
                Two Factor Authentication
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link pointerCursor" (click)="onLogout()" i18n>
                <i class="fa fa-sign-out px-1"></i>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
        <div class="table-responsive">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
  </div>

</ng-template>

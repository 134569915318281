import { Component, OnDestroy, OnInit, Input, AfterViewInit } from '@angular/core';
import { RemittanceService } from '../../shared/remittance.service';
import { Subscription } from 'rxjs';
import { ApiResponse } from '../../../../models/apiresponse';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SuccessComponent } from '../../../../components/success/success.component';
import { ErrorComponent } from '../../../../components/error/error.component';
import { SendingCountry } from '../../../../models/sendingcountry';
import { AppDataService } from '../../../../shared/app-data.service';

@Component({
  selector: 'app-reprinttransferreceipt',
  templateUrl: './reprinttransferreceipt.component.html'
})
export class ReprinttransferreceiptComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() remittanceId;
  @Input() tranStatus;
  receipt: any;
  paramsSubscription: Subscription;
  selectedSenderCountrySubscription: Subscription;
  selectedSendingCountry: SendingCountry;

  constructor(private remittanceService: RemittanceService,
    private route: ActivatedRoute, private router: Router,
    private appDataService: AppDataService,
    // public activeModal: NgbActiveModal,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.remittanceService.viewReceipt_Api(this.remittanceId)
      .then((apiResp: ApiResponse) => {
        if (apiResp.err) {
          this.dialog.open(ErrorComponent, {
            data: {
              message: apiResp.err.message.toString()
            }
          });
          return;
        }
        if (apiResp.result) {
          apiResp.result.forEach(value => {
            this.receipt = value;
          });
        }
      })
      .catch((apiException: ApiResponse) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: (apiException && apiException.err && apiException.err.message) ? apiException.err.message.toString() : apiException
          }
        });
      });
      this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
      }
    );
  }

  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
  }

  print(printSectionId: string) {
    let popupWindow;
    const e1 = document.getElementById(printSectionId);
    const innerContents = e1.innerHTML;
    popupWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWindow.document.open();
    popupWindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" /></head>' +
      '<body onload="window.print()">' + innerContents + '</html>');
    popupWindow.document.close();
  }

  close() {
    this.router.navigate(['/']);
  }
  dialoge(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {

  }
}

import { Component, OnInit } from '@angular/core';
import {User} from '../../../../models/user';
import {Utilities} from '../../../../shared/utilities';
import {environment} from '../../../../../environments/environment';
import {ApiResponse} from '../../../../models/apiresponse';
import {AppDataService} from '../../../../shared/app-data.service';
import {AuthService} from '../../../../shared/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserSessionService} from '../../../../shared/usersession.service';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  currentUser: User;
  lastLgn: any;
  constructor(private appDataService: AppDataService, private authService: AuthService, private router: Router,
              private userSessionService: UserSessionService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
    this.lastLgn =  this.currentUser ? moment(this.currentUser.lastLogin).format('DD-MMM-YYYY, h:mm:ss A') : null;
   }
}

// moment(date).format('DD-MMM-YYYY');
// dateIssue: formValues.sIssueDate ? moment(formValues.sIssueDate).format('DD-MMM-YYYY') : null,

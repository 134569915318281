import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router, RoutesRecognized } from '@angular/router';
import { Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { SendingCountry } from '../models/sendingcountry';
import { PayingCountry } from '../models/payingcountry';
import { RemittanceSubType, RemittanceType } from '../models/remittancetype';
import { Currency } from '../models/currency';
import { Country } from '../models/country';
import { ApiService } from './api.service';
import { Utilities } from '../shared/utilities';
import { User } from '../models/user';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Remittance } from '../modules/remittance/model/remittance';
import {ErrorComponent} from "../components/error/error.component";
import {environment} from "../../environments/environment";

@Injectable()
export class AppDataService {
  app_data: any;
  private sendingCountriesList: SendingCountry[];
  private selectedSendingCountry: SendingCountry;
  selectedSendingCountrySubject: Subject<SendingCountry> = new Subject();

  private payingCountriesList: PayingCountry[];
  private selectedPayingCountry: PayingCountry;
  selectedPayingCountrySubject: Subject<PayingCountry> = new Subject();

  private remittanceTypeList: RemittanceType[];
  private selectedRemittanceType: RemittanceType;
  selectedRemittanceTypeSubject: Subject<RemittanceType> = new Subject();

  private selectedBankType: any[];
  selectedBankTypeSubject: Subject<any[]> = new Subject();

  private selectedRemittanceSubType: RemittanceSubType;
  selectedRemittanceSubTypeSubject: Subject<RemittanceSubType> = new Subject();

  private sendingCurrencyList: Currency[];
  private selectedSendingCurrency: Currency;
  selectedSendingCurrencySubject: Subject<Currency> = new Subject();

  private payingCurrencyList: Currency[];
  private selectedPayingCurrency: Currency;
  selectedPayingCurrencySubject: Subject<Currency> = new Subject();

  private matchedBlacklistedEntity: any;
  matchedBlacklistedEntitySubject: Subject<any> = new Subject();

  private selectedAirtimeNumber: number;
  selectedAirtimeNumberSubject: Subject<number> = new Subject();
  airtimeRemittanceSubTypes: RemittanceSubType[] = [];
  mobileMoneyRemitType: RemittanceType;
  modifyRemittanceTypeList: any;
  currentUser: User;

  paramUrlAustralia: any;
  isAfterLogin: boolean = false;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router,) {
    this.init();
  }
  init() {
    this.app_data = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString()),environment.clientKey)) || {};
    if (Object.keys(this.app_data).length) {
      for (const key in this.app_data) {
        if (this.app_data[key]) {
          this[key] = this.app_data[key];
        }
      }
    }
    // Temporary queryparam code added for checking australia
    this.route.queryParams
      .subscribe(params => {
        this.paramUrlAustralia = params.au;
      });
  }
  set_app_data(prop, value) {
    this.app_data[prop] = value;
    localStorage.setItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString(),
      Utilities.encryptPayLoad(JSON.stringify(this.app_data),environment.clientKey).toString());
  }
  delete_app_data_key(prop, value) {
    delete this.app_data[prop];
    localStorage.setItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString(),
      Utilities.encryptPayLoad(JSON.stringify(this.app_data),environment.clientKey).toString());
  }
  delete_app_data() {
    localStorage.removeItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString());
  }
  getSendingCountriesList(): SendingCountry[] {
    return this.sendingCountriesList;
  }

  getSelectedSendingCountry(): SendingCountry {
    return this.selectedSendingCountry;
  }

  getPayingCountriesList(): PayingCountry[] {
    return this.payingCountriesList;
  }

  getSelectedPayingCountry(): PayingCountry {
    return this.selectedPayingCountry;
  }

  getRemittanceTypeList(): RemittanceType[] {
    return this.remittanceTypeList;
  }

  getSelectedRemittanceType(): RemittanceType {
    return this.selectedRemittanceType;
  }

  getSelectedBankType(): any[any] {
    return this.selectedBankType;
  }

  getMatchedBlacklistEntity(): any {
    return this.matchedBlacklistedEntity;
  }

  getSelectedRemittanceSubType(): RemittanceSubType {
    return this.selectedRemittanceSubType;
  }

  getSendingCurrencyList(): Currency[] {
    return this.sendingCurrencyList;
  }

  getPayingCurrencyList(): Currency[] {
    return this.payingCurrencyList;
  }

  getSelectedSendingCurrency(): Currency {
    return this.selectedSendingCurrency;
  }

  getSelectedPayingCurrency(): Currency {
    return this.selectedPayingCurrency;
  }

  getSelectedAirtimeNumber(): Number {
    return this.selectedAirtimeNumber;
  }

  getIsAfterLogin(): boolean {
    return this.isAfterLogin;
  }

  setIsAfterLogin(login: boolean): boolean {
    this.isAfterLogin = login;
    return this.isAfterLogin;
  }

  setSelectedSendingCountry(sendingCountry: SendingCountry) {
    if (sendingCountry) {
      this.getSendingCountryConfig_Api(sendingCountry, this.selectedPayingCountry ? this.selectedPayingCountry.country.countryId : null)
      .then(sCountry => {
        let selectedPCountry: number;
        this.selectedSendingCountry = sCountry;
        if (this.selectedSendingCountry) {
          this.set_app_data('selectedSendingCountry', this.selectedSendingCountry);
        }
        this.sendingCurrencyList = this.selectedSendingCountry.currencies;
        this.setSelectedSendingCurrency(this.selectedSendingCountry.currencies[0]);
        if (sendingCountry.payingCountries && this.router.url === '/calculator') {
          this.payingCountriesList = sendingCountry.payingCountries;
          if (this.selectedSendingCountry.payingCountries.length > 1) {
            selectedPCountry = this.selectedSendingCountry.payingCountries[0].country.countryId;
            this.payingCountriesList.forEach(pCountry => {
              if (pCountry.country.countryId === 211) {
                selectedPCountry = pCountry.country.countryId;
              }
            });
            this.getPayingCountryRemittanceTypes_Api(this.selectedSendingCountry.country.countryId,
              selectedPCountry)
              .then(pCountry => {
                this.setSelectedPayingCountry(pCountry);
              }, function (err) {
                console.log(err);
                this.selectedPayingCountry = null;
                this.remittanceTypeList = null;
                this.selectedRemittanceType = null;
                this.selectedRemittanceSubType = null;
                this.payingCurrencyList = null;
                this.selectedPayingCurrency = null;
                this.selectedBankType = null;
                this.matchedBlacklistedEntity = null;
                this.delete_app_data_key('selectedPayingCountry', null);
                this.delete_app_data_key('selectedPayingCurrency', null);
                this.delete_app_data_key('selectedRemittanceType', null);
                this.delete_app_data_key('selectedRemittanceSubType', null);
                this.delete_app_data_key('calculation', null);
                this.delete_app_data_key('selectedBankType', null);
                this.delete_app_data_key('matchedBlacklistedEntity', null);
              });
          } else {
            this.selectedPayingCountry = null;
            this.remittanceTypeList = null;
            this.selectedRemittanceType = null;
            this.selectedRemittanceSubType = null;
            this.payingCurrencyList = null;
            this.selectedPayingCurrency = null;
            this.selectedBankType = null;
            this.matchedBlacklistedEntity = null;
            this.delete_app_data_key('selectedPayingCountry', null);
            this.delete_app_data_key('selectedPayingCurrency', null);
            this.delete_app_data_key('selectedRemittanceType', null);
            this.delete_app_data_key('selectedRemittanceSubType', null);
            this.delete_app_data_key('calculation', null);
            this.delete_app_data_key('selectedBankType', null);
            this.delete_app_data_key('matchedBlacklistedEntity', null);
          }
        } else {
          this.payingCountriesList = null;
          this.selectedPayingCountry = null;
          this.remittanceTypeList = null;
          this.selectedRemittanceType = null;
          this.selectedRemittanceSubType = null;
          this.payingCurrencyList = null;
          this.selectedPayingCurrency = null;
          this.selectedBankType = null;
          this.matchedBlacklistedEntity = null;
          this.delete_app_data_key('selectedPayingCountry', null);
          this.delete_app_data_key('selectedPayingCurrency', null);
          this.delete_app_data_key('selectedRemittanceType', null);
          this.delete_app_data_key('selectedRemittanceSubType', null);
          this.delete_app_data_key('calculation', null);
          this.delete_app_data_key('selectedBankType', null);
          this.delete_app_data_key('matchedBlacklistedEntity', null);
        }
        this.selectedSendingCountrySubject.next(this.selectedSendingCountry);
      }, function (err) {
        console.log(err);
        this.selectedSendingCountry = sendingCountry ? sendingCountry : null;
        if (this.selectedSendingCountry) {
          this.set_app_data('selectedSendingCountry', this.selectedSendingCountry);
        }
        this.sendingCurrencyList = null;
        this.selectedSendingCurrency = null;
        this.delete_app_data_key('selectedSendingCurrency', null);
        this.payingCountriesList = null;
        this.selectedPayingCountry = null;
        this.remittanceTypeList = null;
        this.selectedRemittanceType = null;
        this.selectedRemittanceSubType = null;
        this.payingCurrencyList = null;
        this.selectedPayingCurrency = null;
        this.selectedBankType = null;
        this.matchedBlacklistedEntity = null;
        this.delete_app_data_key('selectedPayingCountry', null);
        this.delete_app_data_key('selectedPayingCurrency', null);
        this.delete_app_data_key('selectedRemittanceType', null);
        this.delete_app_data_key('selectedRemittanceSubType', null);
        this.delete_app_data_key('calculation', null);
        this.delete_app_data_key('selectedBankType', null);
        this.delete_app_data_key('matchedBlacklistedEntity', null);

        this.selectedSendingCountrySubject.next(this.selectedSendingCountry);
      });
    } else {
      this.router.navigate(['/']);
    }
  }

  setSelectedPayingCountry(payingCountry: PayingCountry) {
    this.selectedPayingCountry = payingCountry;
    this.set_app_data('selectedPayingCountry', payingCountry);

    if (this.selectedPayingCountry.remittanceTypes) {
      this.remittanceTypeList = this.selectedPayingCountry.remittanceTypes;
    }
    this.selectedPayingCountrySubject.next(this.selectedPayingCountry);
    this.setSelectedRemittanceType(this.selectedPayingCountry.remittanceTypes[0]);
    if (this.router.url === '/calculator') {
    this.setSelectedRemittanceSubType(this.selectedPayingCountry.remittanceTypes[0].remittanceSubTypes[0]);
    }
  }

  setSelectedRemittanceType(remittanceType: RemittanceType) {
    this.set_app_data('selectedRemittanceType', remittanceType);
    this.selectedRemittanceType = remittanceType;
    this.selectedRemittanceTypeSubject.next(this.selectedRemittanceType);
    if (this.router.url !== '/calculator') {
      if (remittanceType.remittanceSubTypes && remittanceType.remittanceSubTypes[0]) {
        this.setSelectedRemittanceSubType(remittanceType.remittanceSubTypes[0]);
      } else {
        this.setSelectedRemittanceSubType(null);
      }
    }
  }

  setSelectedBankType(bankType: any) {
    this.set_app_data('selectedBankType', bankType);
    this.selectedBankType = bankType;
    this.selectedBankTypeSubject.next(this.selectedBankType);
  }

  setMatchedBlacklistEntity(blacklistEntity: any) {
    this.set_app_data('matchedBlacklistedEntity', blacklistEntity);
    this.matchedBlacklistedEntity = blacklistEntity;
    this.matchedBlacklistedEntitySubject.next(this.matchedBlacklistedEntity);
  }

  setAirtimeNumber(airtimeNumber: number) {
    this.set_app_data('selectedAirtimeNumber', airtimeNumber);
    this.selectedAirtimeNumber = airtimeNumber;
    this.selectedAirtimeNumberSubject.next(this.selectedAirtimeNumber);
  }

  setSelectedRemittanceSubType(remittanceSubType: RemittanceSubType) {
    this.set_app_data('selectedRemittanceSubType', remittanceSubType);
    this.selectedRemittanceSubType = remittanceSubType;
    this.selectedRemittanceSubTypeSubject.next(this.selectedRemittanceSubType);
    this.payingCurrencyList = [];
    this.selectedPayingCountry.currencies.forEach(currency => {
      if (currency.currencyId !== 271
        && (currency.remittanceTypeId === this.selectedRemittanceType.remittanceTypeId || !currency.remittanceTypeId)
        && (currency.remittanceSubTypeId === (this.selectedRemittanceSubType ? this.selectedRemittanceSubType.remittanceSubTypeId : null) || !currency.remittanceSubTypeId)) {
        this.setSelectedPayingCurrency(currency);
        this.payingCurrencyList.push(currency);
      }
    });
    if (!this.payingCurrencyList || this.payingCurrencyList.length === 0) {
      this.selectedPayingCountry.currencies.forEach(currency => {
        if (currency.currencyId === 271) {
          this.setSelectedPayingCurrency(currency);
          this.payingCurrencyList.push(currency);
        }
      });
    }
  }

  setSelectedSendingCurrency(currency: Currency) {
    this.set_app_data('selectedSendingCurrency', currency);
    this.selectedSendingCurrency = currency;
    this.selectedSendingCurrencySubject.next(this.selectedSendingCurrency);
  }

  setSelectedPayingCurrency(currency: Currency) {
    this.set_app_data('selectedPayingCurrency', currency);
    this.selectedPayingCurrency = currency;
    this.selectedPayingCurrencySubject.next(this.selectedPayingCurrency);
  }

  // On page load adding AirTime Recharge option to remittance list if DT one or Waafi Call present in remittance sub types
  modifyRemittanceType(remittanceTypes) {
    this.airtimeRemittanceSubTypes = [];
    if (remittanceTypes.find(list => list.remittanceTypeId === 1)) {
      this.mobileMoneyRemitType = remittanceTypes.find(list => list.remittanceTypeId === 1);
      this.mobileMoneyRemitType.remittanceSubTypes.forEach(rsubType => {
        if (rsubType.remittanceSubTypeId === 3 || rsubType.remittanceSubTypeId === 7) {
          this.airtimeRemittanceSubTypes.push(rsubType);
          this.mobileMoneyRemitType.remittanceSubTypes = this.mobileMoneyRemitType.remittanceSubTypes.filter(list => list.remittanceSubTypeId !== rsubType.remittanceSubTypeId);
        }
      });
      if (this.airtimeRemittanceSubTypes.length > 0) {
        remittanceTypes.push(new RemittanceType(1, 'AirTime Recharge', this.airtimeRemittanceSubTypes));
      }
      if (this.mobileMoneyRemitType.remittanceSubTypes.length === 0) {
        remittanceTypes = remittanceTypes.filter(list => list.remittanceType !== 'Mobile Money');
      }
    }
    return remittanceTypes;
  }

  /*  API CALLS*/

  getSendingCountriesList_Api(): Promise<SendingCountry[]> {
    let userLocCountryCode = '';
    return new Promise<SendingCountry[]>(async (resolve, reject) => {
      try {
        const userLoc = await this.getIPLocation_Api();
        if (userLoc) {
          userLocCountryCode = userLoc.country_code;
        }
      } catch(e) {
        console.log(e);
      }
      // const endpoint = Utilities.getEndpointUrlByApiName('getsendingcountries');
      const endpoint = Utilities.getEndpointUrlByApiName('filtersendingcountriesbydomain');
      const payload = {};
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers };
      this.apiService.sendHttpPostRequest(endpoint, payload, options)
        .then(resp => {
          if (resp.err) {
            reject(resp);
          }
          if (resp.result) {
            this.sendingCountriesList = [];
            resp.result.forEach(value => {
              const scountry: Country = new Country(value.countryId, value.countryName, value.countryCode, value.sendingCountryCallingCode, value.flagUrl, value.mobileMinLength, value.mobileMaxLength);
              const scurrencies: Currency[] = value.currencies;
              const payingCountries: PayingCountry[] = [];
              if (value.payingCountries) {
                value.payingCountries.forEach(pvalue => {
                  const pcountry: Country = new Country(pvalue.countryId,
                    pvalue.countryName, pvalue.countryCode, pvalue.countryCallingCode, pvalue.flagUrl, pvalue.mobileMinLength, pvalue.mobileMaxLength);
                  const pcurrencies: Currency[] = [];
                  const remittanceTypes: RemittanceType[] = [];
                  const config = null;
                  payingCountries.push(new PayingCountry(pcountry, pcurrencies, remittanceTypes, config));
                });
              }
              this.sendingCountriesList.push(new SendingCountry(scountry, scurrencies, payingCountries, value.isDefault));
            });
            if (this.sendingCountriesList && localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString())) {
              this.sendingCountriesList.forEach(sendingCountry => {
                if (sendingCountry.country.countryId === JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).countryId) {
                  this.setSelectedSendingCountry(sendingCountry);
                }
              });
            } else if (this.sendingCountriesList) {
              let defaultCountryExists: boolean;
              let sendingCountrySet: boolean;
              this.sendingCountriesList.forEach(sendingCountry => {
                if (sendingCountry.isDefault === 1) {
                  this.setSelectedSendingCountry(sendingCountry);
                  defaultCountryExists = true;
                  sendingCountrySet = true;
                }
                if (sendingCountry.country.countryCode === userLocCountryCode) {
                  this.setSelectedSendingCountry(sendingCountry);
                  defaultCountryExists = true;
                  sendingCountrySet = true;
                }
              });
              if (!defaultCountryExists) {
                this.sendingCountriesList.forEach(sendingCountry => {
                  if (sendingCountry.country.countryId === 129) {
                    this.setSelectedSendingCountry(sendingCountry);
                    sendingCountrySet = true;
                  }
                });
              }
              if (!sendingCountrySet) {
                this.setSelectedSendingCountry(this.sendingCountriesList[0]);
              }
            }
          }
          this.set_app_data('sendingCountriesList', this.sendingCountriesList);
          resolve(this.sendingCountriesList);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getSendingCountryConfig_Api(selectedSendingCountry: SendingCountry, pCountryId: number): Promise<SendingCountry> {
    return new Promise<SendingCountry>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getsendingcountryconfig');
      const payload = { sCountryId: selectedSendingCountry.country.countryId, pCountryId: pCountryId};
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers };
      this.apiService.sendHttpPostRequest(endpoint, payload, options)
        .then(resp => {
          if (resp.err) {
            reject(resp);
          }
          if (resp.result) {
            resp.result.forEach(value => {
              if (value.countryId === selectedSendingCountry.country.countryId) {
                selectedSendingCountry.currencies = value.currencies;
                const payingCountries: PayingCountry[] = [];
                if (value.payingCountries) {
                  value.payingCountries.forEach(pvalue => {
                    const pcountry: Country = new Country(pvalue.countryId,
                      pvalue.countryName, pvalue.countryCode, pvalue.countryCallingCode, pvalue.flagUrl, pvalue.mobileMinLength, pvalue.mobileMaxLength);
                    const pcurrencies: Currency[] = [];
                    const remittanceTypes: RemittanceType[] = [];
                    const config = null;
                    payingCountries.push(new PayingCountry(pcountry, pcurrencies, remittanceTypes, config));
                  });
                }
                selectedSendingCountry.payingCountries = payingCountries;
              }
            });
          }
          resolve(selectedSendingCountry);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getSendingCountriesListForCalc_Api(): Promise<SendingCountry[]> {
    return new Promise<SendingCountry[]>((resolve, reject) => {
      //const endpoint = Utilities.getEndpointUrlByApiName('getsendingcountries');
      const endpoint = Utilities.getEndpointUrlByApiName('filtersendingcountriesbydomain');
      const payload = {};
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers };
      this.apiService.sendHttpPostRequest(endpoint, payload, options)
        .then(resp => {
          if (resp.err) {
            reject(resp);
          }
          if (resp.result) {
            this.sendingCountriesList = [];
            resp.result.forEach(value => {
              const scountry: Country = new Country(value.countryId, value.countryName, value.countryCode, value.sendingCountryCallingCode, value.flagUrl, value.mobileMinLength, value.mobileMaxLength);
              const scurrencies: Currency[] = value.currencies;
              const payingCountries: PayingCountry[] = [];
              if (value.payingCountries) {
                value.payingCountries.forEach(pvalue => {
                  const pcountry: Country = new Country(pvalue.countryId,
                    pvalue.countryName, pvalue.countryCode, pvalue.countryCallingCode, pvalue.flagUrl, pvalue.mobileMinLength, pvalue.mobileMaxLength);
                  const pcurrencies: Currency[] = [];
                  const remittanceTypes: RemittanceType[] = [];
                  const config = null;
                  payingCountries.push(new PayingCountry(pcountry, pcurrencies, remittanceTypes, config));
                });
              }
              this.sendingCountriesList.push(new SendingCountry(scountry, scurrencies, payingCountries, value.isDefault));
            });
          }

          let sendingCountry: SendingCountry;
          if (this.selectedSendingCountry) {
            sendingCountry = this.sendingCountriesList.find(list => list.country.countryId === this.selectedSendingCountry.country.countryId);
          } else {
            this.router.navigate(['/']);
          }

          if (sendingCountry) {
            this.getSendingCountryConfig_Api(sendingCountry, this.selectedPayingCountry ? this.selectedPayingCountry.country.countryId : null)
              .then(sCountry => {
                this.selectedSendingCountry = sCountry;
                sCountry.currencies.forEach( currency => {
                  if (this.selectedSendingCurrency.currencyId === currency.currencyId && this.selectedSendingCurrency.exchangeRateUsd !== currency.exchangeRateUsd) {
                    console.log("--Currency Change--");
                    this.setSelectedSendingCurrency(sCountry.currencies[0]);
                    this.set_app_data('selectedSendingCountry', sCountry);
                  }
                });
                this.selectedSendingCountrySubject.next(this.selectedSendingCountry);
                resolve(this.sendingCountriesList);
              }, function (err) {
                console.log(err);
              });
          } else {
            resolve(this.sendingCountriesList);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getPayingCountryRemittanceTypes_Api(sCountryId: number, pCountryId: number): Promise<PayingCountry> {
    return new Promise<PayingCountry>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getpayingcountryremittancetypes');
      const payload = { sCountryId: sCountryId.toString(), pCountryId: pCountryId.toString() };
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers };
      this.apiService.sendHttpPostRequest(endpoint, payload, options)
        .then(resp => {
          if (resp.err) {
            reject(resp);
          }
          if (resp.result) {
            resp.result.forEach(value => {
              let payingCountry: PayingCountry;
              const pcountry: Country = new Country(value.countryId, value.countryName, value.countryCode, value.payingCountryCallingCode, value.flagUrl, value.mobileMinLength, value.mobileMaxLength);
              const pcurrencies: Currency[] = value.currencies;
              this.modifyRemittanceTypeList = this.modifyRemittanceType(value.remittanceTypes);
              const remittanceTypes: RemittanceType[] = this.modifyRemittanceTypeList;
              const config = value.config;
              payingCountry = new PayingCountry(pcountry, pcurrencies, remittanceTypes, config);
              if (this.selectedSendingCountry.country.countryId == 39) {
                this.getSendingCountryConfig_Api(this.selectedSendingCountry, payingCountry.country.countryId)
                  .then(sCountry => {
                    sCountry.currencies.forEach( currency => {
                      if (this.selectedSendingCurrency.currencyId === currency.currencyId && this.selectedSendingCurrency.exchangeRateUsd !== currency.exchangeRateUsd) {
                        console.log("--Currency ex rate Change--");
                        this.setSelectedSendingCurrency(sCountry.currencies[0]);
                        this.set_app_data('selectedSendingCountry', sCountry);
                      }
                    });
                    resolve(payingCountry);
                  }, function (err) {
                    console.log(err);
                  });
              } else {
                resolve(payingCountry);
              }
            });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getPayingCountryServiceFees_Api(sCountryId: number, pCountryId: number, remittanceTypeId: Number, amount: number, remittanceSubTypeId: Number): Promise<number> {
    const minimumAmount = (amount >= 1 ? amount : 1);
    return new Promise<number>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getservicefees');
      const payload = {
        sCountryId: sCountryId.toString(), pCountryId: pCountryId.toString(), remittanceTypeId: remittanceTypeId.toString()
        , amount: minimumAmount.toString(), remittanceSubTypeId: remittanceSubTypeId
      };
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers };
      this.apiService.sendHttpPostRequest(endpoint, payload, options)
        .then(resp => {
          if (resp.err) {
            reject(resp);
          }
          if (resp.result) {
            resp.result.forEach(value => {
              let serviceFee: number;
              if (value && value.isPercentage) {
                serviceFee = (+value.serviceFee * amount) / 100;
              } else {
                serviceFee = +value.serviceFee;
              }
              resolve(serviceFee);
            });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getCalculatedAmountAndFees_Api(sCountryId: number, pCountryId: number, remittanceTypeId: Number, remittanceSubTypeId: Number, amount: number, sCurrency: Currency, pCurrency: Currency, direction: string, userId: number, promoCode: string): Promise<number> {
    const minimumAmount = (amount >= 1 ? amount : 1);
    const payingCurrency = { currencyCode: pCurrency.currencyCode, exchangeRateUsd: pCurrency.exchangeRateUsd}
    const sendingCurrency = { currencyCode: sCurrency.currencyCode, exchangeRateUsd: sCurrency.exchangeRateUsd}
    return new Promise<number>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getcalculatedamountandfees');
      const payload = {
        sCountryId: sCountryId, pCountryId: pCountryId, remittanceTypeId: remittanceTypeId,
        remittanceSubTypeId: remittanceSubTypeId, amount: minimumAmount, sCurrency: sendingCurrency, pCurrency: payingCurrency, direction: direction, userId: userId ? userId : null, ormPromotionCode: promoCode
      };
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers };
      this.apiService.sendHttpPostRequest(endpoint, payload, options)
        .then(resp => {
          if (resp.err) {
            reject(resp.err);
          }
          if (resp.result) {
            resolve(resp.result[0]);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  validateMinAndMaxAmount_Api(sCountryId: number, pCountryId: number, remittanceTypeId: Number, remittanceSubTypeId: Number, amount: number, sCurrency: Currency, pCurrency: Currency, direction: string): Promise<any> {
    const minimumAmount = (amount >= 1 ? amount : 1);
    const payingCurrency = { currencyCode: pCurrency.currencyCode, exchangeRateUsd: pCurrency.exchangeRateUsd}
    const sendingCurrency = { currencyCode: sCurrency.currencyCode, exchangeRateUsd: sCurrency.exchangeRateUsd}
    return new Promise<any>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('validateminandmaxamount');
      const payload = {
        sCountryId: sCountryId, pCountryId: pCountryId, remittanceTypeId: remittanceTypeId,
        remittanceSubTypeId: remittanceSubTypeId, amount: minimumAmount, sCurrency: sendingCurrency, pCurrency: payingCurrency, direction: direction };
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers };
      this.apiService.sendHttpPostRequest(endpoint, payload, options)
        .then(resp => {
          if (resp.err) {
            reject(resp.err);
          }
          if (resp.result) {
            resolve(resp.result[0]);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getNoOfAvailableZeroCommRemits(userId: number): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getnoofavailablezerocommremits');
      const payload = {
        userId
      };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          if (resp.err) {
            return reject(resp);
          }
          resolve(resp.result[0]);
        })
        .catch(reject);
    });
  }

  getServiceNotifications(sCountryId: number, pCountryId: number, remittanceTypeId: Number, remittanceSubTypeId: Number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getservicenotifications');
      const payload = {
        sCountryId: sCountryId, pCountryId: pCountryId, remittanceTypeId: remittanceTypeId,
        remittanceSubTypeId: remittanceSubTypeId };
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers };
      this.apiService.sendHttpPostRequest(endpoint, payload, options)
        .then(resp => {
          if (resp.err) {
            return reject(resp);
          }
          resolve(resp);
        })
        .catch(reject);
    });
  }
  isUserBlacklisted_Api(firstName: String, middleName: String, lastName: String, mobile: String, countryId: Number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('checkblacklistcustomer');
      const payload = { mobile: mobile, firstName: firstName, middleName: middleName,  lastName: lastName, countryId: countryId };
      var blackListedResp;
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => { //console.log( 'API-', resp)
          if (resp.result != null) {
            for (let i = 0; i < resp.result[0].blackListedCustomer.length; i++) {
              if (resp.result[0].blackListedCustomer[i].blocked) {
                blackListedResp = {
                  isBlackListed: true,
                  blackListedCustomerError: resp.result[0].blackListedCustomerError,
                  approvalRequired: resp.result[0].blackListedCustomer[i].approvalRequired
                };
                resolve(blackListedResp);
              }
            }
          }
          if (resp.err) {
            return resolve(resp);
          }
          blackListedResp = {
            isBlackListed: false,
            blackListedCustomerError: resp.result[0].blackListedCustomerError,
            approvalRequired: resp.result[0].blackListedCustomer[0] ? resp.result[0].blackListedCustomer[0].approvalRequired : false
          };
          resolve(blackListedResp);
        })
        .catch(reject);
    });
  }
  checkIP_Api(countryId: Number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('checkip');
      const payload = { countryId: countryId };
      var checkIPResp;
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          if (resp.result != null) {
            checkIPResp = {
              isValid: true
            };
            resolve(checkIPResp);
          }
          checkIPResp = {
            isValid: false,
            checkIPError: resp.err
          };
          resolve(checkIPResp);
        })
        .catch(reject);
    });
  }
  getCountryStates_Api(sCountryId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getcountrystates');
      const payload = {
        sCountryId: sCountryId };
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers: headers };
      this.apiService.sendHttpPostRequest(endpoint, payload, options)
        .then(resp => {
          if (resp.err) {
            return reject(resp);
          }
          resolve(resp);
        })
        .catch(reject);
    });
  }
  redirectByDomain_Api(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('redirectbydomain');
      const payload = { };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          if (resp.err) {
            return reject(resp);
          }
          resolve(resp);
        })
        .catch(reject);
    });
  }

  getSiteContent_Api(fileName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.getSiteContent(fileName)
        .then(resp => {
          if (resp.err) {
            return reject(resp);
          }
          resolve(resp);
        })
        .catch(reject);
    });
  }

  checkLocation_Api(countryId: Number, latitude: any, longitude: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('checklocation');
      const payload = { countryId: countryId, lat: latitude, lng: longitude };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          if (resp.err) {
            return reject(resp);
          }
          resolve(resp);
        })
        .catch(reject);
    });
  }

  getIPLocation_Api(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getiplocation');
      const payload = { };
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          if (resp.err) {
            return reject(resp);
          }
          resolve(resp.result[0]);
        })
        .catch(reject);
    });
  }
}

import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SendingCountry } from '../models/sendingcountry';
import { AppDataService } from './app-data.service';
import { from } from 'rxjs';

@Injectable()
export class SendingCountryForCalcResolver  {
  constructor(private appDataService: AppDataService) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SendingCountry> {
       return from(new Promise<SendingCountry>((resolve, reject) => {
         this.appDataService.getSendingCountriesListForCalc_Api()
           .then(sendingCountryList => {
             resolve(this.appDataService.getSelectedSendingCountry());
           })
           .catch(function(err) {
             reject(err);
           });
       }));
  }
}

import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {User} from '../../models/user';
import {Event, NavigationEnd, Router} from '@angular/router';
import {isPlatformBrowser, Location} from '@angular/common';
import {AuthService} from '../../shared/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {ApiResponse} from '../../models/apiresponse';
import {ErrorComponent} from '../../components/error/error.component';
import {UserSessionService} from '../../shared/usersession.service';
import {Utilities} from "../../shared/utilities";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-remittance',
  templateUrl: './remittance.component.html'
})
export class RemittanceComponent implements OnInit {
  isDashboard = false;
  isBrowser: boolean;
  route: string;
  currentUser: User;

  constructor(private router: Router, @Inject(PLATFORM_ID) platformId: Object, location: Location, private authService: AuthService, public dialog: MatDialog, private userSessionService: UserSessionService) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.router.events.subscribe((event: Event) => {
      this.route = location.path();
      switch (true) {
        case event instanceof NavigationEnd: {
          this.isDashboard = false;
          if (this.isBrowser) {
            this.route = location.path();
            if (this.route === '/remittance/senderverification' || this.route === '/remittance/transactionhistory' || this.route === '/remittance/recipientlist' || this.route === '/remittance/mylimits') {
              this.isDashboard = true;
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
  }

  onLogout() {
    this.authService.logout_Api()
      .then((resp: ApiResponse) => {
        if (this.router.url === '/') {
          this.router.navigateByUrl('');
        } else {
          this.router.navigateByUrl('/');
        }
      })
      .catch((err: ApiResponse) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.err.message.toString()
          }
        });
      });
  }
}

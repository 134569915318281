import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import { AuthService } from './auth.service';

export class CustomValidators {

  constructor(private authService: AuthService) {
  }

  static uniqueEmail(c: FormControl): Promise<ValidationErrors> {
    const message = {
      'uniqueEmail': {
        'message': 'The email is already registered'
      }
    };

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(c.value === 'test@test.com' ? message : null);
      }, 1000);
    });
  }

  static telephoneNumber(c: FormControl): ValidationErrors {
    const isValidPhoneNumber = /^\d{3,3}-\d{3,3}-\d{3,3}$/.test(c.value);
    const message = {
      'telephoneNumber': {
        'message': 'The phone number must be valid (XXX-XXX-XXX, where X is a digit)'
      }
    };
    return isValidPhoneNumber ? null : message;
  }

  static validPasswordPattern(control: FormControl): ValidationErrors {
    const hasUpperCase = /[A-Z]/.test(control.value);
    const hasLowerCase = /[a-z]/.test(control.value);
    const hasNumberCase = /[0-9]/.test(control.value);
    const uppercaseMessage = {
      'noUppercase': {
        'message': 'Your password must contain at least one uppercase.'
      }
    };
    const lowercaseMessage = {
      'noLowercase': {
        'message': 'Your password must contain at least one lowercase.'
      }
    };
    const numbercaseMessage = {
      'noNumberCase': {
        'message': 'Your password must contain at least one number.'
      }
    };
    if (!hasUpperCase) {
      return uppercaseMessage;
    }
    if (!hasLowerCase) {
      return lowercaseMessage;
    }
    if (!hasNumberCase) {
      return numbercaseMessage;
    }
  }

  static restrictDomains(control: FormControl): ValidationErrors {
    var restrictEmailRegex =
      /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.(?!cin$|co$|con$|cim$|ocm$|cod$|ocl$|fom$|om$)[A-Za-z]{2,6}/.test(
        control.value
      );

    const restrictDomainMessage = {
      emailRegexMsg: {
        message: "Input does not match pattern",
      },
    };
    if (!restrictEmailRegex) {
      return restrictDomainMessage;
    }
  }

  static validateExt(c: FormControl): ValidationErrors {
    const message = {
      'attachments': {
        'message': 'Attachment should be gif, bmp, jpg, jpeg, png, pdf format'
      }
    };
    return message;
  }

  static validateFileSize(c: FormControl): ValidationErrors {
    const message = {
      'attachments': {
        'message': 'Attachment size should be < 5MB'
      }
    };
    return message;
  }


  static telephoneNumbers(form: FormGroup): ValidationErrors {

    const message = {
      'telephoneNumbers': {
        'message': 'At least one telephone number must be entered'
      }
    };

    const phoneNumbers = <FormArray>form.get('phoneNumbers');
    const hasPhoneNumbers = phoneNumbers && Object.keys(phoneNumbers.controls).length > 0;

    return hasPhoneNumbers ? null : message;
  }

  static matchingPasswords(password, confirmPassword) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[password];
      const passwordConfirmationInput = group.controls[confirmPassword];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalentPassword: true});
      }
    };
  }

  static confirmMobileNumbers(mobileNo, confirmMobileNo) {
    return (group: FormGroup) => {
      const mobileNumberInput = group.controls[mobileNo];
      const mobileNumberConfirmationInput = group.controls[confirmMobileNo];
      if (mobileNumberInput.value !== mobileNumberConfirmationInput.value) {
        return mobileNumberConfirmationInput.setErrors({notEquivalentConfirmMobileNumber: true});
      }
    };
  }

  static matchingAccountNo(mmtAccountNo, mmtAccountNoConfirm) {
    return (group: FormGroup) => {
      const accountNoInput = group.controls[mmtAccountNo];
      const accountNoConfirmationInput = group.controls[mmtAccountNoConfirm];
      if (accountNoInput.value !== accountNoConfirmationInput.value) {
        return accountNoConfirmationInput.setErrors({notEquivalentAccountNo: true});
      }
    };
  }

  static validateIssueExpiryDates(issueDate, expiryDate) {
    return (group: FormGroup) => {
      const issDate = group.controls[issueDate];
      const expDate = group.controls[expiryDate];
      if (issDate.value > expDate.value) {
        return expDate.setErrors({expiryGreaterThanIssue: true});
      }
    };
  }

  static validateMobileLength(countryCallingCode: string, minLen: number, maxLen: number) {
    return function (input: FormControl) {
      let completeMobileNo = countryCallingCode + input.value;
      completeMobileNo = completeMobileNo.trim();
      return (completeMobileNo.length < minLen || completeMobileNo.length > maxLen) ?
        {invalidMobileLength: true} :
        null;
    };
  }

  static matchingDocumentType(pDocumentType, sDocumentType) {
    return (group: FormGroup) => {
      const pDocType = group.controls[pDocumentType];
      const sDocType = group.controls[sDocumentType];
      if (pDocType.value === sDocType.value && pDocType.value && sDocType.value) {
        return sDocType.setErrors({duplicateDocTypes: true});
      } else {
        return null;
      }
    };
  }

  static validateBankCode(regExp: string[]) {
    return function (input: FormControl) {
      if(input.dirty || input.touched) {
        let bankCode = input.value;
        bankCode = bankCode ? bankCode.trim() : null;
        if(!regExp)
          return null;

        if(Array.isArray(regExp)) {
          for(var i=0; i < regExp.length; i++) {
            var exp = regExp[i];
            var rExp = new RegExp(exp);
            if(rExp.test(bankCode)) {
              return null;
            }
          }
        } else {
          var rExp = new RegExp(regExp);
          if(rExp.test(bankCode)) {
            return null;
          }
        }
        return { pattern: true };
      }
      return null;
    };
  }

  static restrictPOBox(control: FormControl): ValidationErrors {
    var restrictPOBoxRegex =
      /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i
        .test(
        control.value
      );

    const restrictPOBoxMessage = {
      POBoxRegexMsg: {
        message: "Invalid address. P. O. Box detected. Please correct the address.",
      },
    };
    if (!restrictPOBoxRegex) {
      return restrictPOBoxMessage;
    }
  }
}


import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {Utilities} from "./utilities";
import {environment} from "../../environments/environment";

@Injectable()
export class UserSessionService {
    currentUserSubject: Subject<User> = new Subject();

    constructor(private cookieService: CookieService, private router: Router) {}
    expireUserSession() {
      this.cookieService.delete('encrypt');
      this.cookieService.delete('token');
      localStorage.removeItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString());
      localStorage.removeItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString());
      localStorage.removeItem(Utilities.encryptPayLoad2('serverLastActionDateTime',environment.clientKey).toString());
      this.currentUserSubject.next(null);
      this.router.navigate(['/login']);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { ApiResponse } from '../../models/apiresponse';
import {ErrorComponent} from "../error/error.component";
import {ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import {SendingCountry} from "../../models/sendingcountry";
import { Subscription } from 'rxjs';
import {AppDataService} from "../../shared/app-data.service";

@Component({
  selector: 'app-migration',
  templateUrl: './migration.component.html',
  styleUrls: ['./migration.component.css']
})
export class MigrationComponent implements OnInit {

  selectedSenderCountrySubscription: Subscription;
  selectedSendingCountry: SendingCountry;
  content = { 'pageTitle': '', 'pageContent': '' };
  sCountryId: number;
  contentResult: any;

  constructor(private authService: AuthService, public thisDialogRef: MatDialogRef<MigrationComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private router: Router,public dialog: MatDialog, private appDataService: AppDataService,private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.sCountryId = Number(params.scid);
      });
    this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
        if (this.contentResult) {
          this.content = this.contentResult.find(list => list.sendingCountryId === this.selectedSendingCountry.country.countryId);
          if (!this.content) {
            this.content = this.contentResult.find(list => list.sendingCountryId === null);
          }
        }
      }
    );
    this.sCountryId = this.sCountryId ? this.sCountryId : this.selectedSendingCountry ? this.selectedSendingCountry.country.countryId : null;
    this.appDataService.getSiteContent_Api('terms')
      .then((apiResp: ApiResponse) => {
        if (apiResp.err) {
          console.log(apiResp.err);
          return;
        }
        if (apiResp.result) {
          this.contentResult  = apiResp.result.terms;
          this.content = this.contentResult.find(list => list.sendingCountryId === this.sCountryId);
          if (!this.content) {
            this.content = this.contentResult.find(list => list.sendingCountryId === null);
          }
        }
      })
      .catch((apiException: ApiResponse) => {
        console.log(apiException);
      });
  }

  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
  }

  migrateOrmUser() {
    const payload = {};
    this.authService.migrateOrmUser_Api(payload).then((apiResp:ApiResponse) => {
      if (apiResp.err) {
        this.dialog.open(ErrorComponent, {
          data: {
            message: apiResp.err.message.toString()
          }
        });
      }
      this.thisDialogRef.close();
      if (this.router.url === '/') {
        this.router.navigateByUrl('');
      } else {
        this.router.navigateByUrl('/');
      }
    })
  }

  logout() {
    this.authService.logout_Api()
      .then((resp: ApiResponse) => {
        this.thisDialogRef.close();
        if (this.router.url === '/') {
          this.router.navigateByUrl('');
        } else {
          this.router.navigateByUrl('/');
        }
      })
      .catch((err: ApiResponse) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.err.message.toString()
          }
        });
      });
  }

}

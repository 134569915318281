import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomegeneralComponent } from './homegeneral.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HomegeneralComponent],
  imports: [
    RouterModule.forChild([
      {
          path: '',
          component: HomegeneralComponent
      }
  ]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],
  exports: [HomegeneralComponent]
})
export class HomegeneralModule { }

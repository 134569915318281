import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemittanceComponent } from './remittance.component';
import { RemittanceRoutingModule } from './remittance-routing.module';
import { RemittanceService } from './shared/remittance.service';
import { ReprinttransferreceiptComponent } from './components/reprinttransferreceipt/reprinttransferreceipt.component';
import { PaymentGatewayService } from './shared/paymentgateway.service';
import { DuplicateRemittanesDialogComponent } from './components/duplicateRemittanesDialog/duplicateRemittanesDialog.component';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { APP_DATE_FORMATS, NEW_APP_DATE_FORMATS } from '../../shared/constants';
import { AppDateAdapter } from '../../shared/date-adapter';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { TransferreceiptComponent } from './components/transferreceipt/transferreceipt.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AddRecipientViewResolver } from './shared/addrecipientview.resolver';
import { CustomerResolver } from '../customers/shared/customer.resolver';
import { PaymentmethodsResolver } from './shared/paymentmethods.resolver';
import { SavedCardsResolver } from './shared/savedcards.resolver';
import { SenderAddInfoViewResolver } from './shared/senderadditionalview.resolver';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  imports: [
    CommonModule,
    RemittanceRoutingModule, MatDialogModule, MatButtonModule, MatExpansionModule, MatFormFieldModule, MatCardModule
  ],
  declarations: [
    RemittanceComponent,
    ReprinttransferreceiptComponent,
    DuplicateRemittanesDialogComponent,
    TransferreceiptComponent,
    CheckoutComponent
    ],
  entryComponents: [],
  providers: [ RemittanceService, PaymentGatewayService, AddRecipientViewResolver, CustomerResolver, PaymentmethodsResolver, SavedCardsResolver , SenderAddInfoViewResolver,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: NEW_APP_DATE_FORMATS}
    ]
})
export class RemittanceModule { }

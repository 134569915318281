import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RemittanceService } from '../../shared/remittance.service';
import { RemittanceRequest } from '../../model/remittancerequest';
import { environment } from '../../../../../environments/environment';
import { CalculationService } from '../../../../shared/calculation.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  isBrowser: boolean;
  shopperResultUrl: string;
  paymentMethod: any;
  disabled: any;
  remittanceRequest: RemittanceRequest;
  currency: any;
  amount: any;
  orderNumber: any;

  constructor(@Inject(PLATFORM_ID) platformId: Object, private remittanceService: RemittanceService,
              private route: ActivatedRoute, private router: Router, private calculation: CalculationService) {
  }

  ngOnInit() {
    const remittance = this.remittanceService.getRemittanceRequest();
    const calculation = this.calculation.getLastCalculation();

    this.shopperResultUrl = environment.apiProtocol + '://' + environment.apiServerIP + ':' + environment.apiServerPort + '/acipayment/' + remittance.guid;
    this.amount = calculation.totalSendAmount;
    this.currency = remittance.remittances[0].sCurrencyCode;
    this.orderNumber = remittance.orderNumber;
    this.paymentMethod = remittance.remittances[0].payment[0].paymentMethodCode.toUpperCase();

    this.disabled = true;
  }
  }

<ngx-loading [show]="loadingDeletionAction" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
<div>
  <h1 matDialogTitle><img src="../../../../../assets/img/error.png" height="30" width="30" i18n/> Delete Account</h1>

  <mat-dialog-content class="mb-2">
    <p class="mb-1 font-weight-bold"><u>English</u></p>
    You are about to permanently delete your account. If you do, all your content will be removed within period defined in our Terms & Conditions.
    Caution: This action cannot be reversed.
  </mat-dialog-content>

  <mat-dialog-content>
    <p class="mb-1 font-weight-bold"><u>Somali</u></p>
    Salaam Mudane/Marwo.
    FIIRO GAAR AH
    Haddii aad riixdo/gujiso DELETE. Waxaa si kama danbeys ah kaaga  xirmi doona akoonka kaaga furan Taaj
    oo aadan dib danbe u isticmaali doonin ee ma sidaa ayaad rabtaa? Haddii aad daneyneyso in aad lacag ku
    dirsato Taaj ha delete gareyn akoonka si uu kuugu furaado.
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="warn"  (click)="onDeactivateUserAccount()" i18n>Yes</button>
    <button mat-raised-button matDialogClose i18n>No</button>
  </mat-dialog-actions>
</div>

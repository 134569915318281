<div class="home ismobile">
  <div class="col-md-4 pull-right mr-5" style="margin-bottom: -300px;">
    <form [formGroup]="form" *ngIf="form" id="countrylist-form" class="form-horizontal top-on-mobile" novalidate
          autocomplete="off" (ngSubmit)="start()" style="position: relative; top: 4rem; padding: 0px 15px;">
      <div class="form-row align-items-center calculator ">
        <h3 class="text-center mb-4 mx-auto font-weight-bold primayrColor ">Let's get started</h3>
        <div class="dividerGry mx-auto"></div>
        <div class="col-md-12 my-1">
          <label for="sendingFrom" class="from-title">Sending from</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text country-flag"><img alt="flag" src="{{imgPath2}}"></div>
            </div>
            <ng-template [ngIf]="currentUser">
              <select id="sendingFrom" class="custom-select custom-select-lg" (change)="onSelectSendingCountry($event)"
                      [disabled]="true">
                <option *ngFor="let sendFromCountry of sendingCountryList"
                        value="{{sendFromCountry?.country?.countryId}}"
                        [selected]="sendFromCountry?.country?.countryId === selectedSendingCountry?.country?.countryId">
                  {{sendFromCountry?.country?.countryName}}
                </option>
              </select> 
              <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="loadingSendingCountries"
                                    class="whiteprogress" style="width: 25px;height: 25px;position: absolute;right: 35px;top: 10px;">
              </mat-progress-spinner>
            </ng-template>
            <ng-template [ngIf]="!currentUser">
              <select id="sendingFrom" class="custom-select custom-select-lg" (change)="onSelectSendingCountry($event)">
                <option *ngFor="let sendFromCountry of sendingCountryList"
                        value="{{sendFromCountry?.country?.countryId}}"
                        [selected]="sendFromCountry?.country?.countryId === selectedSendingCountry?.country?.countryId">
                  {{sendFromCountry?.country?.countryName}}
                </option>
              </select>
              <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="loadingSendingCountries"
                                    class="whiteprogress" style="width: 25px;height: 25px;position: absolute;right: 35px;top: 10px;">
              </mat-progress-spinner>
            </ng-template>
          </div>
        </div>
        <div class="col-md-12 my-1">
          <label for="sendingTo" class="from-title">Sending to</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text country-flag"><img alt="flag" src="{{imgPath}}"></div>
            </div>
            <select id="sendingTo" class="custom-select custom-select-lg" name="ddlPayingCountry"
                    formControlName="ddlPayingCountry" (change)="onSelectPayingCountry($event)">
              <option [ngValue]="null" [selected]>Sending to...</option>
              <option *ngFor="let payingCountry of payingCountriesList"
                      value="{{payingCountry?.country?.countryId}}" [selected]>
                {{payingCountry?.country?.countryName}}</option>
            </select>
            <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="showProgress"
                                  class="whiteprogress">
            </mat-progress-spinner>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <button type="submit" name="countrylist" [disabled]="(!form.valid || disableSubmitButton)" id="country-button"
                  class="btn btn-warning btn-lg btn-block">Check Rates</button>
        </div>  
      </div> 
    </form>
  </div>
</div>

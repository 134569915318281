import { FormGroup } from '@angular/forms';
import * as crypto from 'crypto-js';
import { siteLanguages } from './constants';
import { siteRegions } from './constants'

export class Utilities {

  public static isErrorVisible(form: FormGroup, field: string, validator: string) {
    return form.controls[field].errors && (form.controls[field].errors[validator]
      || (form.controls[field].errors[validator] && parseInt(form.controls[field].errors[validator]['actualLength'])
        < parseInt(form.controls[field].errors[validator]['requiredLength']))) && form.controls[field].touched;
  }
  public static removeDots(str: string): string {
    return str.replace(/[.]/g, '');
  }
  public static trimString(str: string): string {
    if (str) {
      str = str.replace(/\s\s+/g, ' ');
      str = str.trim();
    }
    return str;
  }
  public static matchingPasswords(password, confirmPassword) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[password];
      const passwordConfirmationInput = group.controls[confirmPassword];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      }
    };
  }
  public static generateGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  public static isFormValid() {
    return true;
  }
  public static encryptPayLoad(payload: string, userToken: string): any {
    return crypto.AES.encrypt(payload, userToken).toString();
  }
  public static dycryptPayLoad(payload: string, userToken: string): any {
    if (typeof payload !== 'string') {
      return payload;
    } else {
      try {
        return JSON.parse(crypto.AES.decrypt(payload, userToken).toString(crypto.enc.Utf8));
      } catch (e) {
        console.log(e);
      }
    }
  }
  public static decryptPayLoad2(payload: string, key: string): any {
    if (typeof payload !== 'string') {
      return payload;
    } else {
      try {
        return crypto.AES.decrypt(payload, key).toString(crypto.enc.Utf8)
      } catch (e) {
        console.log(e);
      }
    }
  }
  public static encryptPayLoad2(payload: string, userToken: string): any {
    const hash = crypto.HmacSHA256(payload, userToken).toString();
    return hash;
  }
  public static getEndpointUrlByApiName(apiName: string): string {
    let endPointUrl: string;
    switch (apiName) {
      case 'getsendingcountries':
      case 'getsendingcountryconfig':
      case 'getpayingcountryremittancetypes':
      case 'getservicefees':
      case 'getcalculatedamountandfees':
      case 'getallservicefeesbycountries':
      case 'validateminandmaxamount':
      case 'getservicenotifications':
      case 'getcountrystates':
      case 'redirectbydomain':
      case 'filtersendingcountriesbydomain':
      case 'checkip':
      case 'checklocation':
      case 'getiplocation':
        endPointUrl = '/orm/lookups/' + apiName;
        break;
      case 'createToken':
      case 'login':
      case 'logout':
      case 'getUserToken':
      case 'loginotpverification':
      case 'sendsmsotptoenableappauth':
      case 'sendormotpbyemail':
      case 'ormotpverification':
        endPointUrl = '/' + apiName;
        break;
      case 'adduser':
      case 'verifyemail':
      case 'getusertoken':
      case 'getcustbyuserid':
      case 'updatepassword':
      case 'getcurrentuser':
      case 'forgotpassword':
      case 'checkemailisregistered':
      case 'resetpassword':
      case 'registerbankidcustomer':
      case 'isactiveusersession':
      case 'resendverificationemail':
      case 'signupbankid':
      case 'loginbankid':
      case 'migrateonlinecustomer':
      case 'contactusbyemail':
      case 'getcustnotifications':
      case 'checkblacklistcustomer':
      case 'migrateormuser':
      case 'deactivateormuseraccount':
      case 'checkdocumentexpiry':
        endPointUrl = '/orm/usermanagement/' + apiName;
        break;
      case 'getormtransactions':
      case 'getaddrecipientview':
      case 'getaddremittanceview':
      case 'customersanctioned':
      case 'viewreceipt':
      case 'registeronlinepayment':
      case 'getpaymentmethodsview':
      case 'getremittancepurposes':
      case 'getreceiverlastremittancedetails':
      case 'getormremittancebyguid':
      case 'addrecipient':
      case 'updatesenderadditionalinfo':
      case 'updatesenderverificationinfo':
      case 'getdoctypes':
      case 'getsenderaddinfoview':
      case 'validatecustomernationalid':
      case 'verifymmtaccount':
      case 'getaddresses':
      case 'getnoofavailablezerocommremits':
      case 'getormcustomerlimits':
      case 'getidverisdktokenfrompartner':
      case 'verifydocuments':
      case 'getbankaccountinfo':
      case 'generatetransferreceipt':
      case 'getormrequestsbydateforuser':
      case 'ormaccountverification':
      case 'getcustomersavedcards':
      case 'getacipaymentstatusbyguid':
        endPointUrl = '/orm/remittancemanagement/' + apiName;
        break;
      case 'uploadsenderdocument':
        endPointUrl = '/fileManagement/action/uploadfiles';
        break;
      case 'getbankidloginurl':
        endPointUrl = '/bankid/no/oidc/login';
        break;
      case 'initiatepayment':
        endPointUrl = '/orm/payments/' + apiName;
        break;
      case 'updateSystemNotificationByAlert':
      case 'checkmaintenancemode' :
        endPointUrl = '/systemnotificationsmanagement/action/' + apiName;
        /*     case 'getaddresses':
            endPointUrl = '/customermanagement/action/' + apiName;
            break; */
        break;
    }
    return endPointUrl;
  }

  public static generateAlphanumericString(length) {
    var chars = '23456789ABCDEFGHJ23456789KLMNPQRST23456789UVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  public static setDecimals(number, precision) {
    const precisionMultiplier = Math.pow(10, precision);
    number = parseFloat(number);
    return Math.round(number * precisionMultiplier) / precisionMultiplier;
  }

  public static splitFullName(fullName) {
    const name = {
      firstName: null,
      lastName: null
    };

    if (fullName) {
      fullName = (fullName.trim()).toLowerCase();
    }
    if (!fullName) {
      return name;
    }

    const names = fullName.split(' ');
    name.firstName = names[0];
    if (names.length > 1) {
      name.lastName = names.slice(1, names.length).join(' ');
    }

    return name;
  }

  public static splitFullName2(fullName) {
    const name = {
      firstName: null,
      lastName: null
    };

    if (fullName) {
      fullName = (fullName.trim()).toLowerCase();
    }
    if (!fullName) {
      return name;
    }

    const names = fullName.split(' ');
    name.firstName = names.slice(0, names.length - 1).join(' ');
    name.lastName = names[names.length - 1];
    return name;
  }


  public static getLocalStorageExpiryDateTime(serverLastActionDateTime: Date, add_minutes: number): Date {
    return new Date(serverLastActionDateTime.getTime() + (add_minutes * 60000));
  }

  public getServiceFeeForAmount(serviceFees, sCountryId, pCountryId, amount, remittanceTypeId, remittanceSubTypeId, sParentBranchId, sBranchId, pParentBranchId, pBranchId) {
    var branch2BranchListRemitSubType = [];
    var branch2AllBranchesListRemitSubType = [];
    var allBranches2BranchListRemitSubType = [];

    var branch2BranchListRemitType = [];
    var branch2AllBranchesListRemitType = [];
    var allBranches2BranchListRemitType = [];

    var branch2BranchList = [];
    var branch2AllBranchesList = [];
    var allBranches2BranchList = [];

    var parentBranch2ParentBranchListRemitSubType = [];
    var parentBranch2AllParentBranchesListRemitSubType = [];
    var allParentBranches2ParentBranchListRemitSubType = [];

    var parentBranch2ParentBranchListRemitType = [];
    var parentBranch2AllParentBranchesListRemitType = [];
    var allParentBranches2ParentBranchListRemitType = [];

    var parentBranch2ParentBranchList = [];
    var parentBranch2AllParentBranchesList = [];
    var allParentBranches2ParentBranchList = [];

    var country2CountryListRemitSubType = [];
    var country2AllListRemitSubType = [];
    var all2CountryListRemitSubType = [];
    var all2AllListRemitSubType = [];

    var country2CountryListRemitType = [];
    var country2AllListRemitType = [];
    var all2CountryListRemitType = [];
    var all2AllListRemitType = [];

    var country2CountryList = [];
    var country2AllList = [];
    var all2CountryList = [];
    var all2AllList = [];

    var bigAmount = 1000000000000000000;
    serviceFees.forEach(function (serviceFee) {
      if (serviceFee.isActive == 0)
        return;

      var amountTo = serviceFee.amountTo ? serviceFee.amountTo + 1 : bigAmount;

      // START - remittance sub type
      // branch Level - remittance sub type
      // specific branch to specific branch for a specific remittance sub type
      if (serviceFee['sBranchId'] === sBranchId && serviceFee['pBranchId'] === pBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        branch2BranchListRemitSubType.push(serviceFee);
        return;
      }
      // specific branch to all branches for a specific remittance sub type
      if (serviceFee['sBranchId'] === sBranchId && !serviceFee['pBranchId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        branch2AllBranchesListRemitSubType.push(serviceFee);
        return;
      }
      // all branches to specific branch for a specific remittance sub type
      if (!serviceFee['sBranchId'] && serviceFee['pBranchId'] === pBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        allBranches2BranchListRemitSubType.push(serviceFee);
        return;
      }

      // parent branch Level - remittance sub type
      // specific parent branch to specific parent branch for a specific remittance sub type
      if (serviceFee['sParentBranchId'] === sParentBranchId && serviceFee['pParentBranchId'] === pParentBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        parentBranch2ParentBranchListRemitSubType.push(serviceFee);
        return;
      }
      // specific parent branch to all parent branches for a specific remittance sub type
      if (serviceFee['sParentBranchId'] === sParentBranchId && !serviceFee['pParentBranchId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        parentBranch2AllParentBranchesListRemitSubType.push(serviceFee);
        return;
      }
      // all parent branches to specific parent branch for a specific remittance sub type
      if (!serviceFee['sParentBranchId'] && serviceFee['pParentBranchId'] === pParentBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        allParentBranches2ParentBranchListRemitSubType.push(serviceFee);
        return;
      }

      // country Level - remittance sub type
      // all to all countries for a specific remittance sub type
      if (!serviceFee['sCountryId'] && !serviceFee['pCountryId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        all2AllListRemitSubType.push(serviceFee);
        return;
      }
      // specific country to specific country for a specific remittance sub type
      if (serviceFee['sCountryId'] === sCountryId && serviceFee['pCountryId'] === pCountryId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        country2CountryListRemitSubType.push(serviceFee);
        return;
      }
      // specific country to all for a specific remittance sub type
      if (serviceFee['sCountryId'] === sCountryId && !serviceFee['pCountryId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        country2AllListRemitSubType.push(serviceFee);
        return;
      }
      // all countries to specific country for a specific remittance sub type
      if (!serviceFee['sCountryId'] && serviceFee['pCountryId'] === pCountryId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == remittanceSubTypeId) {
        all2CountryListRemitSubType.push(serviceFee);
        return;
      }
      // END - remittance sub type

      // START - remittance type
      // branch Level - remittance type
      // specific branch to specific branch for a specific remittance type
      if (serviceFee['sBranchId'] === sBranchId && serviceFee['pBranchId'] === pBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        branch2BranchListRemitType.push(serviceFee);
        return;
      }
      // specific branch to all branches for a specific remittance type
      if (serviceFee['sBranchId'] === sBranchId && !serviceFee['pBranchId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        branch2AllBranchesListRemitType.push(serviceFee);
        return;
      }
      // all branches to specific branch for a specific remittance type
      if (!serviceFee['sBranchId'] && serviceFee['pBranchId'] === pBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        allBranches2BranchListRemitType.push(serviceFee);
        return;
      }

      //parent level - remittance type
      // specific parent branch to specific parent branch for a specific remittance type
      if (serviceFee['sParentBranchId'] === sParentBranchId && serviceFee['pParentBranchId'] === pParentBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        parentBranch2ParentBranchListRemitType.push(serviceFee);
        return;
      }
      // specific parent branch to all parent branches for a specific remittance type
      if (serviceFee['sParentBranchId'] === sParentBranchId && !serviceFee['pParentBranchId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        parentBranch2AllParentBranchesListRemitType.push(serviceFee);
        return;
      }
      // all parent branches to specific parent branch for a specific remittance type
      if (!serviceFee['sParentBranchId'] && serviceFee['pParentBranchId'] === pParentBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        allParentBranches2ParentBranchListRemitType.push(serviceFee);
        return;
      }

      // country level - remittance type
      // all to all countries for a specific remittance type
      if (!serviceFee['sCountryId'] && !serviceFee['pCountryId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        all2AllListRemitType.push(serviceFee);
        return;
      }
      // specific country to specific country for a specific remittance type
      if (serviceFee['sCountryId'] === sCountryId && serviceFee['pCountryId'] === pCountryId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        country2CountryListRemitType.push(serviceFee);
        return;
      }
      // specific country to all for a specific remittance type
      if (serviceFee['sCountryId'] === sCountryId && !serviceFee['pCountryId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        country2AllListRemitType.push(serviceFee);
        return;
      }
      // all countries to specific country for a specific remittance type
      if (!serviceFee['sCountryId'] && serviceFee['pCountryId'] === pCountryId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == remittanceTypeId && serviceFee.remittanceSubTypeId == null) {
        all2CountryListRemitType.push(serviceFee);
        return;
      }
      // END - remittance type

      // START - all remittance type and sub type
      // branch level - all remittance type and sub type
      // specific branch to specific branch for all remittance types and for all remittance sub types
      if (serviceFee['sBranchId'] === sBranchId && serviceFee['pBranchId'] === pBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        branch2BranchList.push(serviceFee);
        return;
      }
      // specific branch to all branches for all remittance types and for all remittance sub types
      if (serviceFee['sBranchId'] === sBranchId && !serviceFee['pBranchId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        branch2AllBranchesList.push(serviceFee);
        return;
      }
      // all branches to specific branch for a all remittance types and for all remittance sub types
      if (!serviceFee['sBranchId'] && serviceFee['pBranchId'] === pBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        allBranches2BranchList.push(serviceFee);
        return;
      }

      // parent level - all remittance type and sub type
      // specific parent branch to specific parent branch for all remittance types and for all remittance sub types
      if (serviceFee['sParentBranchId'] === sParentBranchId && serviceFee['pParentBranchId'] === pParentBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        parentBranch2ParentBranchList.push(serviceFee);
        return;
      }
      // specific parent branch to all parent branches for all remittance types and for all remittance sub types
      if (serviceFee['sParentBranchId'] === sParentBranchId && !serviceFee['pParentBranchId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        parentBranch2AllParentBranchesList.push(serviceFee);
        return;
      }
      // all parent branches to specific parent branch for a all remittance types and for all remittance sub types
      if (!serviceFee['sParentBranchId'] && serviceFee['pParentBranchId'] === pParentBranchId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        allParentBranches2ParentBranchList.push(serviceFee);
        return;
      }

      // country level - all remittance type and sub type
      // all to all countries and for all remittance types and for all remittance sub types
      if (!serviceFee['sCountryId'] && !serviceFee['pCountryId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        all2AllList.push(serviceFee);
        return;
      }
      // specific country to specific country for all remittance types and for all remittance sub types
      if (serviceFee['sCountryId'] === sCountryId && serviceFee['pCountryId'] === pCountryId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        country2CountryList.push(serviceFee);
        return;
      }
      // specific country to all countries for all remittance types and for all remittance sub types
      if (serviceFee['sCountryId'] === sCountryId && !serviceFee['pCountryId'] && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        country2AllList.push(serviceFee);
        return;
      }
      // all countries to specific country for a all remittance types and for all remittance sub types
      if (!serviceFee['sCountryId'] && serviceFee['pCountryId'] === pCountryId && amount >= serviceFee.amountFrom && amount < amountTo && serviceFee.remittanceTypeId == null && serviceFee.remittanceSubTypeId == null) {
        all2CountryList.push(serviceFee);

      }
      // END - all remittance type and sub type
    });

    var filteredServiceFees = [];

    if (branch2BranchListRemitSubType.length > 0)
      filteredServiceFees = branch2BranchListRemitSubType;
    else if (branch2AllBranchesListRemitSubType.length > 0)
      filteredServiceFees = branch2AllBranchesListRemitSubType;
    else if (allBranches2BranchListRemitSubType.length > 0)
      filteredServiceFees = allBranches2BranchListRemitSubType;
    else if (parentBranch2ParentBranchListRemitSubType.length > 0)
      filteredServiceFees = parentBranch2ParentBranchListRemitSubType;
    else if (parentBranch2AllParentBranchesListRemitSubType.length > 0)
      filteredServiceFees = parentBranch2AllParentBranchesListRemitSubType;
    else if (allParentBranches2ParentBranchListRemitSubType.length > 0)
      filteredServiceFees = allParentBranches2ParentBranchListRemitSubType;
    else if (country2CountryListRemitSubType.length > 0)
      filteredServiceFees = country2CountryListRemitSubType;
    else if (country2AllListRemitSubType.length > 0)
      filteredServiceFees = country2AllListRemitSubType;
    else if (all2CountryListRemitSubType.length > 0)
      filteredServiceFees = all2CountryListRemitSubType;
    else if (all2AllListRemitSubType.length > 0)
      filteredServiceFees = all2AllListRemitSubType;

    else if (branch2BranchListRemitType.length > 0)
      filteredServiceFees = branch2BranchListRemitType;
    else if (branch2AllBranchesListRemitType.length > 0)
      filteredServiceFees = branch2AllBranchesListRemitType;
    else if (allBranches2BranchListRemitType.length > 0)
      filteredServiceFees = allBranches2BranchListRemitType;
    else if (parentBranch2ParentBranchListRemitType.length > 0)
      filteredServiceFees = parentBranch2ParentBranchListRemitType;
    else if (parentBranch2AllParentBranchesListRemitType.length > 0)
      filteredServiceFees = parentBranch2AllParentBranchesListRemitType;
    else if (allParentBranches2ParentBranchListRemitType.length > 0)
      filteredServiceFees = allParentBranches2ParentBranchListRemitType;
    else if (country2CountryListRemitType.length > 0)
      filteredServiceFees = country2CountryListRemitType;
    else if (country2AllListRemitType.length > 0)
      filteredServiceFees = country2AllListRemitType;
    else if (all2CountryListRemitType.length > 0)
      filteredServiceFees = all2CountryListRemitType;
    else if (all2AllListRemitType.length > 0)
      filteredServiceFees = all2AllListRemitType;

    else if (branch2BranchList.length > 0)
      filteredServiceFees = branch2BranchList;
    else if (branch2AllBranchesList.length > 0)
      filteredServiceFees = branch2AllBranchesList;
    else if (allBranches2BranchList.length > 0)
      filteredServiceFees = allBranches2BranchList;
    else if (parentBranch2ParentBranchList.length > 0)
      filteredServiceFees = parentBranch2ParentBranchList;
    else if (parentBranch2AllParentBranchesList.length > 0)
      filteredServiceFees = parentBranch2AllParentBranchesList;
    else if (allParentBranches2ParentBranchList.length > 0)
      filteredServiceFees = allParentBranches2ParentBranchList;
    else if (country2CountryList.length > 0)
      filteredServiceFees = country2CountryList;
    else if (country2AllList.length > 0)
      filteredServiceFees = country2AllList;
    else if (all2CountryList.length > 0)
      filteredServiceFees = all2CountryList;
    else if (all2AllList.length > 0)
      filteredServiceFees = all2AllList;

    if (!filteredServiceFees.length)
      return null;

    if (filteredServiceFees.length == 1)
      return filteredServiceFees[0];

    //selecting the correct service fee
    var minRange = (filteredServiceFees[0].amountTo ? filteredServiceFees[0].amountTo : bigAmount - filteredServiceFees[0].amountFrom);
    var minIndex = 0;
    for (var i = 1; i < filteredServiceFees.length; i++) {
      var diff = (filteredServiceFees[i].amountTo ? filteredServiceFees[i].amountTo : bigAmount - filteredServiceFees[i].amountFrom);
      if (diff < minRange) {
        minRange = diff;
        minIndex = i;
      }
    }
    return filteredServiceFees[minIndex];
  }
  public static isJson(item): Boolean {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    if (typeof item === "object" && item !== null) {
      return true;
    }
    return false;
  }
}
export function getBaseLocation() {
  let paths: string[] = window.location.pathname.split('/');
  paths = paths[1].split('-');
  let language: string = (paths && paths[0]) || 'en'; // Default: 'en'
  let region: string = (paths && paths[1] || null);
  if (siteLanguages.includes(language) && siteRegions.includes(region)) {
    return '/' + language + '-' + region;
  } else if (siteLanguages.includes(language)) {
    return '/' + language;
  } else {
    return '/en';
  }
}



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HowitworksComponent } from './howitworks.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HowitworksComponent],
  imports: [
    RouterModule.forChild([
      {
          path: '',
          component: HowitworksComponent
      }
  ]),
    CommonModule
  ],
  exports: [HowitworksComponent]
})
export class HowitworksModule { }

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SendingCountry } from '../../models/sendingcountry';
import { AppDataService } from '../../shared/app-data.service';

@Component({
  selector: 'app-selectcountry',
  templateUrl: './selectcountry.component.html',
  styleUrls: ['./selectcountry.component.css']
})
export class SelectcountryComponent implements OnInit {

  form: FormGroup;
  selectedSendingCountry: SendingCountry;
  selectedSenderCountrySubscription: Subscription;
  sendingCountryList: SendingCountry[];
  @ViewChild('selectCountryModal') selectCountryModal: TemplateRef<any>;

  constructor(private fb: FormBuilder, private appDataService: AppDataService, public dialog: MatDialog) { }

  ngOnInit() {
    this.form = this.fb.group({
      ddlSelectCountry: [null]
    });
    this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
    if (!this.selectedSendingCountry) {
      this.appDataService.getSendingCountriesList_Api()
        .then(value => {
          this.sendingCountryList = value;
          this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
          setTimeout(() => {
            this.dialog.open(this.selectCountryModal);
          }, 300);
        })
        .catch(err => {
          console.log('Loading countries failed-- Err is --' + err);
        });
    }
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
      }
    );
  }

  onSelectSendingCountry(event): void {
    this.appDataService.setSelectedSendingCountry(this.sendingCountryList[event.target.selectedIndex - 1]);
    this.dialog.closeAll();
  }
}

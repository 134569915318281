<div class="container-fluid p-3 copyright bg-gray">
    <div class="row">
        <div class="col-md-4">
          <div *ngIf="selectedSendingCountry?.country?.countryId !== 246 && selectedSendingCountry?.country?.countryId !== 39">
            <small class="d-block text-muted" >&copy; 2019-{{currentYear}} - All Rights Reserved - <a href="">Taaj</a></small>
          </div>
          <div *ngIf="selectedSendingCountry?.country?.countryId === 246 || selectedSendingCountry?.country?.countryId === 39">
            <small class="d-block text-muted" >&copy; 2019-{{currentYear}} - All Rights Reserved - <a href="">Taaj - powered by SafariPay Corp.</a></small>
          </div>
        </div>
        <div class="col-md-8">
            <div>
                <div class="d-block float-right"><i class="fa fa-cc-visa fa-2x"></i>&nbsp;<i class="fa fa-cc-mastercard fa-2x"></i>&nbsp;<i class="fa fa-expeditedssl fa-2x"></i><br></div>
            </div>
        </div>
    </div>
</div>

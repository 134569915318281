import { Injectable } from '@angular/core';

@Injectable()
export class Session {
    private data: SessionData = null;

    constructor() {
        this.data = new SessionData();
    }

    public setSession(key: string, value: any) {
        this.data.sessionData[key] = value;
        this.syncSessionStorage(true);
    }

    public getSession(key: string): any {
        this.syncSessionStorage(false);
        return this.data.sessionData[key];
    }

    private syncSessionStorage(set: boolean) {
        let dataKey: string = 'session';
        if (set) {
            sessionStorage.setItem(dataKey, JSON.stringify(this.data));
            return;
        }

        let storageData = sessionStorage.getItem(dataKey);
        if (!storageData) {
            this.data = new SessionData();
            return this.data;
        }

        let sessionData: SessionData = JSON.parse(storageData);
        this.data = sessionData;
    }

    public cleareSession() {
        this.data = new SessionData();
        this.syncSessionStorage(true);
    }
}

class SessionData {
    public sessionData: any;
    constructor() {
        this.sessionData = {};
    }
}

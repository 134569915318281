import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowerrorsComponent } from './showerrors/showerrors.component';
import { ShowerrorComponent } from './showerror/showerror.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ ShowerrorsComponent, ShowerrorComponent ],
  exports: [ ShowerrorsComponent, ShowerrorComponent]
})
export class SharedModule { }

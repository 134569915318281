<div class="position-relative overflow-hidden mt-5 mb-3">
  <div class="col-md-12 mx-auto section-title">
    <h2 class="pt-4 text-center">Easy Steps To Send Money To {{selectedPayingCountry?.country?.countryName}}</h2>
    <div class="divider"></div>
  </div>
</div>
<div class="container easy-steps">
  <div class="row text-center hide-on-mobile">
  </div>
    <div class="row my-5 text-center">
      <div class="col-md-4">
        <div class="card">
          <img alt="send money to {{selectedPayingCountry?.country?.countryName}}" src="assets/images/v2/step1.jpg" class="steps-img">
          <div class="card-body">
            <h4 class="card-title text-center" class="section-header" ><span class="steps-number">01.</span> Sign up for free</h4>
            <p>Easy to register online or in our unique mobile app. You can also sign up with your Google or Facebook account.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <img alt="send money to {{selectedPayingCountry?.country?.countryName}}" src="assets/images/v2/step2.jpg" class="steps-img">
          <div class="card-body">
            <h4 class="card-title text-center" class="section-header" ><span class="steps-number">02.</span> Select country & enter amount</h4>
            <p>Select a country of your choice to send money to, enter the amount you want to send & see the exchange rate applied, the fees charged & the total amount to pay transparently.</p>
          </div>
        </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <img alt="send money to {{selectedPayingCountry?.country?.countryName}}" src="assets/images/v2/step3.jpg" class="steps-img">
        <div class="card-body">
          <h4 class="card-title text-center" class="section-header" ><span class="steps-number">03.</span> Add recipient information</h4>
          <p>Select existing recipient from your previous list or add a new recipient and fill correct receiving details i.e. mobile wallet account or bank account etc.</p>
        </div>
      </div>
  </div>
</div>
<div class="row mb-5 text-center">
<div class="col-md-4">
    <div class="card">
      <img alt="send money to {{selectedPayingCountry?.country?.countryName}}" src="assets/images/v2/step4.jpg" class="steps-img">
      <div class="card-body">
        <h4 class="card-title text-center" class="section-header" ><span class="steps-number">04.</span> Verify your identity</h4>
        <p>To help us keep your money safe please provide your valid photo identity and proof of address.</p>
      </div>
    </div>
</div>
<div class="col-md-4">
  <div class="card">
    <img alt="send money to {{selectedPayingCountry?.country?.countryName}}" src="assets/images/v2/step5.jpg" class="steps-img">
    <div class="card-body">
      <h4 class="card-title text-center" class="section-header" ><span class="steps-number">05.</span> Pay for your transfer</h4>
      <p>Use your debit/credit card to pay for your transfer using our safe and secure payment.</p>
    </div>
  </div>
</div>
<div class="col-md-4">
  <div class="card">
    <img alt="send money to {{selectedPayingCountry?.country?.countryName}}" src="assets/images/v2/step6.jpg" class="steps-img">
    <div class="card-body">
      <h4 class="card-title text-center" class="section-header" ><span class="steps-number">06.</span> That's it</h4>
      <p>Let's do the rest, to follow your transfer please login to your account and see the status.</p>
    </div>
  </div>
</div>
</div>
</div>
<div class="position-relative overflow-hidden mt-5 mb-3">
  <div class="col-md-12 mx-auto section-title">
    <h2 class="pt-5 text-center">Send Money To {{selectedPayingCountry?.country?.countryName}} FAQs</h2>
    <div class="divider"></div>
  </div>
</div>
<div class="container">
  <div class="row my-5">
    <div class="col-md-12 py-1">
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button class="btn btn-block btn-light nav-link text-left" data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" i18n>
              What’s the fastest way to send money to {{selectedPayingCountry?.country?.countryName}}?
            </button>
          </h5>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card-body">
            <p i18n>Taaj is the fastest way to send money to {{selectedPayingCountry?.country?.countryName}}, with money transfers available for your recipient in {{selectedPayingCountry?.country?.countryName}} within minutes. Some transfers can take more time because of pickup locations having their own specific business hours and holidays. In every case, we try to get your money to {{selectedPayingCountry?.country?.countryName}} as quickly as possible. Plus, we’ll keep you notified of the entire process as it happens.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" i18n>
              How much does it cost to send money to {{selectedPayingCountry?.country?.countryName}}?</button>
          </h5>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
          <div class="card-body">
            <p i18n>Taaj charges a small transaction fee to make international money transfers to {{selectedPayingCountry?.country?.countryName}}. The fee amount varies depending on:</p>
            <ul>
            <li>Your payment method</li>
            <li>How much money you send</li>
          </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" i18n>
              What do I need to know before sending money to {{selectedPayingCountry?.country?.countryName}}?</button>
          </h5>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
          <div class="card-body">
            <p i18n>You’ll need to know your recipient’s name as it appears on their ID, as well as their address and phone number. If you’re making a bank deposit, you’ll also need to know their bank name and account number.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFour">
          <h5 class="mb-0">
            <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                    data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" i18n>
              What is a Transaction ID?</button>
          </h5>
        </div>
        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
          <div class="card-body">
            <p i18n>A transaction ID is a unique number given to every transaction you set up. It is the number you’ll need to give out to your beneficiary in {{selectedPayingCountry?.country?.countryName}} to collect the money. You’ll also need to reference the Transaction ID if you contact the Taaj customer support team with any queries. You can find the Transaction ID in your transfer history and in emails you’ve received.</p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFive">
          <h5 class="mb-0">
            <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                    data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" i18n>
              How can I avoid any delays when sending money to {{selectedPayingCountry?.country?.countryName}}?</button>
          </h5>
        </div>
        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
          <div class="card-body">
            <p i18n>There are few measures you can take to ensure that your money arrives to your recipient in {{selectedPayingCountry?.country?.countryName}} as quickly as possible. These include: </p>
            <ul>
              <li>Prepare any additional information and documentation that might be required by Taaj or your recipient’s financial institution</li>
              <li>Remember that banks, cash pickup locations, and cash delivery services have their own specific business hours and holidays</li>
              <li>Check that your recipient details are accurate</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSix">
          <h5 class="mb-0">
            <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                    data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" i18n>
              How can I make sure that my money will be secure?</button>
          </h5>
        </div>
        <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
          <div class="card-body">
            <p i18n>Taaj staff will never ask for your password, or other login credentials. We will never ask you to install remote login or remote support software on your computer.</p>
<!--            <p>Only contact Taaj through this portal or phone support on:</p>-->
<!--            <p>UK <a href="tel:+44-20-8103-3856">+44-20-8103-3856</a> <br>-->
<!--              Sweden <a href="tel:+46-844-682-333">+46-844-682-333</a></p>-->
            <p>Fake customer service numbers and pages are on the increase  -  please be careful to verify any information you find on the web.</p>
            <p>Use a complex and unique password that is not shared or similar to those used on any other websites. It's also a good practice to change your password periodically and use a completely new password each time and never disclose your password to anyone.</p>
            <p>Your email address is one of the most important connections between you and your Taaj account! Make sure it is secure.</p>
            <p>Do not use the same password on your email account that you do on your Taaj account. If your email provider offers it, activate 2-factor authentication (2FA) on your email account to add an extra layer of security.</p>
            <p>Be on the lookout for emails pretending to be from Taaj (you may have heard this called “phishing”). This is the most common way customers are compromised. Avoid clicking links or opening attachments in emails.</p>
            <p>Be sure to check for the "lock" symbol appearing in your browser's address bar when you access Taaj which indicates that your connection is secure. If this symbol is not present, double check the URL.
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSeven">
          <h5 class="mb-0">
            <button class="btn btn-block btn-light nav-link text-left collapsed" data-toggle="collapse"
                    data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" i18n>
              Can I get a refund if I want to cancel my money transfer to {{selectedPayingCountry?.country?.countryName}}?</button>
          </h5>
        </div>
        <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
          <div class="card-body">
            <p i18n>If for any reason you need to cancel your money transfer to {{selectedPayingCountry?.country?.countryName}} after sending your money to us, but before the transfer is complete, Contact Taaj Customer service and your money will be refunded. If your transaction is processed we will refund your money without fee, if your transaction is not collected by your beneficiary.</p>
          </div>
        </div>
      </div>
  </div>
    </div>
</div>
</div>

export class DuplicateRemittance {
  constructor(
    public orderNumber: string,
    public receiverFullName: string,
    public receiverMobile: string,
    public transferAmount: number,
    public sCurrencyCode: string,
    public receivingAmount: number,
    public pCurrencyCode: string,
    public transactionDate: string,
    public transactionStatus: string
  ) {}
}

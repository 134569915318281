import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit, AfterViewInit {

  constructor( private titleService: Title) { 
    this.titleService.setTitle( "Help Support 24/7 | Online Money Transfer | Send Money Abroad | Taaj Money Transfer Limited" );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }
}

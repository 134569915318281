import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PaymentGatewayService } from './paymentgateway.service';
import { from } from 'rxjs';

@Injectable()
export class PaymentmethodsResolver implements Resolve<any> {
  constructor(private paymentGatewayService: PaymentGatewayService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return from(this.paymentGatewayService.getPaymentMethods_Api());
  }
}

<div class="container-fluid py-5" style="background-color: #f7f7f7;">
  <div class="row p-5 justify-content-md-left" style="padding-bottom:70px;">
    <div class="col-md-4"></div>
    <div class="col-md-4 px-4 py-3 mb-5 bg-light" style="box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2); border-radius: 5px;">
      <h3 class="py-3" i18n>Login</h3>
      <form name="login-form" [formGroup]="form" *ngIf="form" id="login-form" class="form-horizontal" novalidate
        autocomplete="off" (ngSubmit)="login()">
        <div class="form-group pb-2">
          <input #email type="email" name="email" id="email" class="form-control form-control-lg" placeholder="Enter email"
            i18n-placeholder="Email Id *" autofocus formControlName="email" required>
        </div>
        <div class="form-group pb-2">
          <input #password type="password" name="password" id="password" class="form-control form-control-lg"
            placeholder="Enter password" i18n-placeholder="Password *" formControlName="password" required>
        </div>
        <div class="mb-3" *ngIf="isReCaptchaEnabled">
          <re-captcha formControlName="recaptchaReactive"></re-captcha>
        </div>
        <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="showProgress"></mat-progress-spinner>
        <button name="login" id="regularButton" [disabled]="!form.valid || disableSubmitButton" i18n class="btn btn-primary btn-block mb-4" i18n>Login</button>
        <p style="padding-top: 25px;color:red" *ngIf="passwordResetRequired" i18n>
          Your password is expired. Please click
          <!-- <a class="link" [routerLink]="['/customers/resetexpirypassword']" [queryParams]="{ id: emailparam}">Reset Possword</a> link. -->
          <a style="cursor: pointer" class="link" (click)="resetPassword()">Reset Password</a> link.
        </p>
      </form>
      <small id="forgotpass" class="form-text text-muted mt-3 float-right">
        <a routerLink="/customers/forgotpassword" i18n style="font-size: 14px;">Forgot Password?</a>
      </small>
      <br>
    </div>
    <div class="col-md-3 align-self-center mx-5 p-5" style="padding-left: 75px !important;">
      <ul class="list-group mb-3">
        <li class="list-group-item list-group-item-success text-center" i18n>Not Registered Yet?</li>
        <a  class="text-white" id="regularButton1" name="login" class="btn btn-gold mt-3 btn-block" (click)="signUp()" i18n>Sign Up</a>
      </ul>
      <div class="col-md-12 px-0 my-3">
        <form name="loginbankid-form" [formGroup]="formbankid" id="loginbankid-form" class="form-horizontal" novalidate
          autocomplete="off">
          <div class="loginForm">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { Utilities } from '../../../shared/utilities';
import { RemittanceRequest } from '../model/remittancerequest';
import { ApiResponse } from '../../../models/apiresponse';
import { Subject } from 'rxjs';
import {environment} from "../../../../environments/environment";


@Injectable()
export class PaymentGatewayService {
  app_data: any;
  private surl: any;
  surlSubject: Subject<any> = new Subject();

  constructor(private apiService: ApiService) { this.init(); }

  init() {
    this.app_data = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString()),environment.clientKey)) || {};
    if (Object.keys(this.app_data).length) {
      for (const key in this.app_data) {
        if (this.app_data[key]) {
          this[key] = this.app_data[key];
        }
      }
    }
  }

  set_app_data(prop, value) {
    this.app_data[prop] = value;
    localStorage.setItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString(),
      Utilities.encryptPayLoad(JSON.stringify(this.app_data),environment.clientKey).toString());
  }

  getSeamurl(): Number {
    return this.surl;
  }

  setSeamurl(url: any) {
    this.surl = url;
    this.set_app_data('surl', this.surl);
    this.surlSubject.next(this.surl);
  }
  /*  API CALLS*/

    registerOnlinePayment_Api(remittanceRequest: RemittanceRequest): Promise<ApiResponse> {
      var paymentApi = 'initiatepayment';
      if (remittanceRequest.paymentGatewayId === 1) {
        paymentApi = 'registeronlinepayment';
      }
      if(remittanceRequest.paymentGatewayId === 4 && remittanceRequest.remittances[0].payment[0].type == 115) { //need to check with seamless payment method
        remittanceRequest.resType = 'redirect';
      } else {
        remittanceRequest.resType = 'json';
      }

      return new Promise<ApiResponse>((resolve, reject) => {
        const endpoint = Utilities.getEndpointUrlByApiName(paymentApi);
        const payload = remittanceRequest;
        this.apiService.sendHttpPostRequest(endpoint, payload)
          .then(resp => {
            if (resp.result) {
            resp.result.forEach(value => {
              const tUrl = value.terminalUrl;
            this.setSeamurl(tUrl);
            });
          }
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
    getPaymentMethods_Api(): Promise<ApiResponse> {
      return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getpaymentmethodsview');
      const payload = {paymentMethodTypeId: 2};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
    }
    getCustomerSavedCards_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getcustomersavedcards');
      const payload = {};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

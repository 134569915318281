import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DuplicateRemittance} from '../../model/duplicateRemittance';

@Component({
  selector: 'app-duplicate-remittanes-dialog',
  templateUrl: 'duplicateRemittanesDialog.component.html'
})
export class DuplicateRemittanesDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public remittances: DuplicateRemittance[]) {}
}

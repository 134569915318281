import { Injectable } from '@angular/core';
import { AppDataService } from './app-data.service';
import { Calculation } from '../models/calculation';
import { AppMockupData } from './app-mockup-data';
import { Utilities } from './utilities';
import { PlatformLocation } from '@angular/common';
import { languages } from '../shared/constants';
import {environment} from "../../environments/environment";

@Injectable()
export class CalculationService {

  calculation: Calculation;
  constructor(private appDataService: AppDataService, private platformLocation: PlatformLocation) {
    this.calculation = this.appDataService.app_data.calculation || new Calculation();
  }

  getPageHTMLContentByCountryId(countryId) {
    let languageId = languages.en;
    let selectedLanguage;
    selectedLanguage = this.platformLocation.getBaseHrefFromDOM().split('/')[0];
    if (selectedLanguage && selectedLanguage.toLowerCase() === 'so') {
      languageId = languages.so;
    }
    return AppMockupData.payingCountryHTMLContentList.filter(function (content) {
      return (!content.countryId || content.countryId === countryId) && content.languageId === languageId;
    })[0];
  }

  getLastCalculation(): Calculation {
    return this.calculation;
  }

  setLastCalculation(calculation: Calculation) {
    this.calculation = calculation;
    this.appDataService.set_app_data('calculation', calculation);
  }

  /*  API CALLS*/

  getSendingToPayingExchangeRate(): number {
    let sendingCurrencyExchangeRate = 0;
    let payingCurrencyExchangeRate = 0;
    let exchangeRate = 0;
    sendingCurrencyExchangeRate = this.appDataService.getSelectedSendingCurrency().exchangeRateUsd;
    payingCurrencyExchangeRate = this.appDataService.getSelectedPayingCurrency().exchangeRateUsd;
    exchangeRate = payingCurrencyExchangeRate / sendingCurrencyExchangeRate;
    exchangeRate = Utilities.setDecimals(exchangeRate, 5);
    return exchangeRate;
  }

  calculateFromSendingAmount(sCountryId: number, pCountryId: number, remittanceTypeId: number, remittanceSubTypeId: number, sendingAmount: number, skipCheckingZeroCommRemit: boolean = false): Promise<Calculation> {
    const sendingCurrency = this.appDataService.getSelectedSendingCurrency();
    const payingCurrency = this.appDataService.getSelectedPayingCurrency();
    const usdAmount: number = sendingAmount / sendingCurrency.exchangeRateUsd;
    const payAmount = Utilities.setDecimals(payingCurrency.exchangeRateUsd * usdAmount, 2);
    sendingAmount = Utilities.setDecimals(sendingAmount, 2);
    return this.getServiceFeeRate(sCountryId, pCountryId, remittanceTypeId, usdAmount, sendingAmount, payAmount,
      sendingCurrency, payingCurrency, skipCheckingZeroCommRemit, remittanceSubTypeId);
  }

  calculateFromReceivingAmount(sCountryId: number, pCountryId: number, remittanceTypeId: number, remittanceSubTypeId: number, receivingAmount: number, skipCheckingZeroCommRemit: boolean = false): Promise<Calculation> {
    const sendingCurrency = this.appDataService.getSelectedSendingCurrency();
    const payingCurrency = this.appDataService.getSelectedPayingCurrency();
    const usdAmount = receivingAmount / payingCurrency.exchangeRateUsd;
    const sendAmount = Utilities.setDecimals(sendingCurrency.exchangeRateUsd * usdAmount, 2);
    receivingAmount = Utilities.setDecimals(receivingAmount, 2);
    return this.getServiceFeeRate(sCountryId, pCountryId, remittanceTypeId, usdAmount, sendAmount, receivingAmount,
      sendingCurrency, payingCurrency, skipCheckingZeroCommRemit, remittanceSubTypeId);
  }

  getServiceFeeRate(sCountryId, pCountryId, remittanceTypeId, usdAmount, sendAmount, payAmount, sendingCurrency, payingCurrency,
    skipCheckingZeroCommRemit: boolean, remittanceSubTypeId): Promise<Calculation> {
    const currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)) || 'null';
    if (skipCheckingZeroCommRemit || !currentUser || !currentUser.availableZeroCommRemits) {
      return fetchServiceFees(this, null);
    }

    return new Promise<Calculation>((res, rej) => {
      this.appDataService.getNoOfAvailableZeroCommRemits(currentUser.userId)
        .then(zeroFeeRemitConfigs => {
          res(fetchServiceFees(this, zeroFeeRemitConfigs));
        })
        .catch(rej);
    });
    function fetchServiceFees(ctx, zeroFeeRemitConfigs): Promise<Calculation> {
      return new Promise<Calculation>((resolve, reject) => {
        ctx.appDataService.getPayingCountryServiceFees_Api(sCountryId, pCountryId, remittanceTypeId, usdAmount, remittanceSubTypeId)
          .then(value => {
            ctx.calculation.sendCurrency = sendingCurrency;
            ctx.calculation.sendAmount = sendAmount;
            ctx.calculation.feeCurrency = sendingCurrency;
            if (zeroFeeRemitConfigs && zeroFeeRemitConfigs.availableZeroCommRemits &&
              zeroFeeRemitConfigs.availableZeroCommRemits > 0 && zeroFeeRemitConfigs.eligiblePayingCountries.indexOf(pCountryId) > -1) {
              ctx.calculation.feeAmount = 0;
            } else {
              let feeAmount;
              let feeInUsd = value;
              if (feeInUsd < 1) {
                feeInUsd = 1;
              }
              feeAmount = Utilities.setDecimals((feeInUsd || 0) * sendingCurrency.exchangeRateUsd, 2);
              ctx.calculation.feeAmount = feeAmount;
            }
            ctx.calculation.totalSendAmount = +ctx.calculation.sendAmount + +ctx.calculation.feeAmount;
            ctx.calculation.payCurrency = payingCurrency;
            ctx.calculation.payAmount = payAmount;
            ctx.calculation.exchangeRate = ctx.getSendingToPayingExchangeRate();
            ctx.calculation.zeroFeeRemitConfigs = zeroFeeRemitConfigs || null;
            ctx.appDataService.set_app_data('calculation', ctx.calculation);
            resolve(ctx.calculation);
          })
          .catch(reject);
      });
    }
  }

  // New functions to get Calculated Amount & Fees in local currencies and USD
  calculateFromSendDirection(sCountryId: number, pCountryId: number, remittanceTypeId: number, remittanceSubTypeId: number, sendingAmount: number, skipCheckingZeroCommRemit: boolean = false, promoCode: string): Promise<Calculation> {
    const sendingCurrency = this.appDataService.getSelectedSendingCurrency();
    const payingCurrency = this.appDataService.getSelectedPayingCurrency();
    sendingAmount = Utilities.setDecimals(sendingAmount, 2);
    const direction = "send";
    return this.getAmountAndFees(sCountryId, pCountryId, remittanceTypeId, remittanceSubTypeId, sendingAmount, sendingCurrency, payingCurrency, direction, skipCheckingZeroCommRemit, promoCode);
  }

  calculateFromPayDirection(sCountryId: number, pCountryId: number, remittanceTypeId: number, remittanceSubTypeId: number, receivingAmount: number, skipCheckingZeroCommRemit: boolean = false, promoCode: string): Promise<Calculation> {
    const sendingCurrency = this.appDataService.getSelectedSendingCurrency();
    const payingCurrency = this.appDataService.getSelectedPayingCurrency();
    receivingAmount = Utilities.setDecimals(receivingAmount, 2);
    const direction = "pay";
    return this.getAmountAndFees(sCountryId, pCountryId, remittanceTypeId, remittanceSubTypeId, receivingAmount, sendingCurrency, payingCurrency, direction, skipCheckingZeroCommRemit, promoCode);
  }

  getAmountAndFees(sCountryId, pCountryId, remittanceTypeId, remittanceSubTypeId, amount, sendingCurrency, payingCurrency, direction, skipCheckingZeroCommRemit: boolean, promoCode): Promise<Calculation> {
    const currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)) || 'null';
      return fetchServiceFees(this, null);

    function fetchServiceFees(ctx, zeroFeeRemitConfigs): Promise<Calculation> {
      return new Promise<Calculation>((resolve, reject) => {
        ctx.appDataService.getCalculatedAmountAndFees_Api(sCountryId, pCountryId, remittanceTypeId, remittanceSubTypeId, amount, sendingCurrency, payingCurrency, direction, currentUser ? currentUser.userId : null, promoCode)
          .then(value => {
            ctx.calculation.sendCurrency = sendingCurrency;
            ctx.calculation.payCurrency = payingCurrency;
            ctx.calculation.feeCurrency = sendingCurrency;
            ctx.calculation.feeAmountUSD = value.serviceFeeUSD;
            ctx.calculation.feeAmount = value.serviceFeeLocalCurrency;
            ctx.calculation.totalSendAmount = value.totalAmountLocalCurrency;
            ctx.calculation.oldServiceFeeLocalCurrency = value.oldServiceFeeLocalCurrency;
            ctx.calculation.promotionDiscount = value.promotionDiscount;
            ctx.calculation.ormPromotionConfigId = value.ormPromotionConfigId;
            ctx.calculation.sendAmount = value.sendAmountLocalCurrency;
            ctx.calculation.payAmount = value.payAmountLocalCurrency;
            ctx.calculation.sendAmountUSD = value.sendAmountUSD;
            ctx.calculation.payAmountUSD = value.payAmountUSD;
            ctx.calculation.exchangeRate = ctx.getSendingToPayingExchangeRate();
            ctx.calculation.zeroFeeRemitConfigs = zeroFeeRemitConfigs || null;
            ctx.calculation.direction = direction;
            ctx.appDataService.set_app_data('calculation', ctx.calculation);
            resolve(ctx.calculation);
          })
          .catch(reject);
      });
    }
  }

}

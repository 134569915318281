	
	<!-- cards::before -->
	<div class="container mt-1">
		<div class="row">
			<div class="col-lg-12 mt-5 mb-5">
				<div class="row card-animate">
          <div class="col-md-12 pb-5 text-center section-title">
            <h2>How it Works</h2>
            <div class="divider"></div>
          </div>
					<div class="col-md-3">
						<div class="card border">								
							<div class="card-body">
							
								<p class="card-text text-center">
                  <img src="../../../assets/images/v2/step1.webp" alt="">
                                         
                </p>
                    
							</div>
						</div>
					</div>
          <div class="col-md-3 mt-5">
            <div class="card-title"> Sign up for free</div>
					<div>Register online or via our unique mobile application hassle free. Please make sure to have your email address ready
					<!--				<p class="card-text text-center">Card Body</p> -->
						</div>					
			</div>
			
    <!-- card 2 -->
    <div class="col-md-3 dashDvdr">
      <div class="card border">								
        <div class="card-body">
      
          <p class="card-text text-center">
            <img src="../../../assets/images/v2/step2.webp" alt="">                                     
          </p>
           </div>
      </div>
    </div>
      <div class="col-md-3 mt-5">
        <div class="card-title"> Select country & enter amount</div>
        <div>Select the country of your choice, enter the amount you would like to send, see rates and fees applied & the total you have to pay transparently.
               </div>					
    </div>


    <div class="container mt-5"></div>

<!-- card 3 -->

<div class="col-md-3">
  <div class="card border">
    <div class="card-body">

     				<p class="card-text text-center">
        <img src="../../../assets/images/v2/step3.webp" alt="">
      </p>
    </div>
  </div>
</div>
<div class="col-md-3 mt-5">
  <div class="card-title"> Add recipient information</div>
  <div>Add recipient details i.e. mobiles wallets or bank account details. You can also select recipients from previous transactions.   
  </div>
</div>
<!-- card 4 -->
<div class="col-md-3 dashDvdr">
  <div class="card border">
    <div class="card-body">

     				<p class="card-text text-center">
        <img src="../../../assets/images/v2/step4.webp" alt="">
      </p>
    </div>
  </div>
</div>
<div class="col-md-3 mt-5">
  <div class="card-title"> Verify your identity</div>
  <div>To help us verify your identity and keep your money safe, we might ask for a photo of your ID and a proof of address.  
  </div>
</div>

<div class="container mt-5"></div>

<!-- card 5 -->

<div class="col-md-3">
  <div class="card border">
    <div class="card-body">

     				<p class="card-text text-center">
        <img src="../../../assets/images/v2/step5.webp" alt="">
      </p>
    </div>
  </div>
</div>
<div class="col-md-3 mt-5">
  <div class="card-title"> Pay for your transfer</div>
  <div>Use your debit or credit to pay for your transfer using our safe and secure payment platform.
     </div>
</div>
<!-- card 6 -->
<div class="col-md-3 dashDvdr">
  <div class="card border">
    <div class="card-body">

     				<p class="card-text text-center">
        <img src="../../../assets/images/v2/step6.webp" alt="">
      </p>
    </div>
  </div>
</div>
<div class="col-md-3 mt-5">
  <div class="card-title"> That's it</div>
  <div>Let us do the rest. You can follow the status of your transfer on your account and we will notify the recipient.
     </div>
</div>
    
  </div>
	</div>
  </div>



  
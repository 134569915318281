import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {siteRegions} from "./constants";

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private renderer: Renderer2;
  constructor(@Inject(DOCUMENT) private doc, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
   }
  createHrefLangTags(link: string, removeOldTags: boolean) {
    let origin = window.location.origin;
    let pathName = window.location.pathname;
    let paths: string[] = pathName.split('/');
    paths = paths[1].split('-');
    let language: string = (paths && paths[0]) || 'en'; // Default: 'en'
    let region: string = (paths && paths[1] || null);
    if (link) {
      this.createLinkForHrefLang(origin + '/' + 'en' + link, 'x-default', removeOldTags);
    } else {
      this.createLinkForHrefLang(origin + '/' + 'en/', 'x-default', removeOldTags);
    }
    siteRegions.forEach(reg => {
      if (reg !== region) {
        if (link) {
          this.createLinkForHrefLang(origin + '/' + language + '-' + reg + link, language + '-' + reg, removeOldTags );
        } else {
          this.createLinkForHrefLang(origin + '/' + language + '-' + reg + '/', language + '-' + reg, removeOldTags );
        }
      }
    });
  }

  createLinkForHrefLang(link: string, lang: string, removeOldTags: boolean) {
    const linkElt = this.renderer.createElement('link');
    this.renderer.setAttribute(linkElt, 'rel', 'alternate');
    this.renderer.setAttribute(linkElt, 'href', link);
    this.renderer.setAttribute(linkElt, 'hreflang', lang);
    this.renderer.appendChild(this.doc.head, linkElt);
    if (removeOldTags) {
      const oldLinkTag = document.head.querySelector('link[hreflang='+lang+']');
      if (oldLinkTag) {
        document.head.removeChild(oldLinkTag);
      }
    }
  }
}

import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomerService } from '../customers.service';
import { ApiResponse } from '../../../models/apiresponse';
import { User } from '../../../models/user';
import { of } from 'rxjs';
import { from } from 'rxjs';
import {AppDataService} from "../../../shared/app-data.service";
import {Utilities} from "../../../shared/utilities";
import {environment} from "../../../../environments/environment";


@Injectable()
export class CustomerResolver implements Resolve<any> {
    currentUser: User;

    constructor(private customerService: CustomerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApiResponse>  {
      let user;
      if(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString())) {
        user = Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey);
      } else if(localStorage.getItem(Utilities.encryptPayLoad2('userInfoForRegistration',environment.clientKey).toString())) {
        user = Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('userInfoForRegistration',environment.clientKey).toString()),environment.clientKey);
      }
      else if(localStorage.getItem(Utilities.encryptPayLoad2('twoFactorUserEnabledId',environment.clientKey).toString())) {
        user = Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('twoFactorUserEnabledId',environment.clientKey).toString()),environment.clientKey);
      }
      if(user){
        this.currentUser = JSON.parse(user);
        return from(this.customerService.getCustomerByUserId_Api(this.currentUser.userId));
      }
      return of(null);
    }
}


<!-- Call Out [START] -->
<div class="container-fluid call-out">
    <div class="row pb-5 pt-3 mx-auto-5 justify-content-center">
               <div class="col-md-8 pt-5 text-white text-center section-title">
            <h2>Create your account in few minutes</h2>
            <p class="text-white">Sign up for free, and get exiciting offers using referral codes.</p>
                <div class="mt-5 "  >
            <a type="button" routerLink="/customers/signup" class="btn btn-light btn-lg">REGISTER NOW</a>
        </div>
    </div>
    </div>
</div>
<!-- Call Out [END] -->


<div class="footer bg-light">
    <footer class="container py-5">
        <div class="row">
            <!-- Taaj about us [START] -->
            <div class="col-md-5 col-md">
                <img alt="Logo" src="../../../assets/images/Taaj-Logo.svg" width="120">
                <div *ngIf="selectedSendingCountry?.country?.countryId === 64">
                    <p class=" mt-3">Taaj is the name you can trust when it comes to speed, convenience and security in money transfers. We are known for our ultra-competitive rates and instant service with our latest inclusion in mobile-to-mobile payments.</p>
                </div>
                <div *ngIf="selectedSendingCountry?.country?.countryId === 129">
                    <p class="mt-3">Taaj is the name you can trust when it comes to speed, convenience and security in money transfers. We are known for our ultra-competitive rates and instant service with our latest inclusion in mobile-to-mobile payments.</p>
                </div>
            </div>
             <!-- Taaj about us [END] -->

<!-- Information [START] -->
<div class="col-md-2">
    <h6>Information</h6>
    <ul class="list-unstyled text-small footer-list">
        <li><a class="text-muted" routerLink="/aboutus" title="About us">About Us</a></li>
         <li *ngIf="selectedSendingCountry?.country?.countryId === 246 || selectedSendingCountry?.country?.countryId === 39">
            <a class="text-muted" routerLink="/ceostatement" title="Mission Statement">Mission Statement</a>
        </li>
        <li><a class="text-muted" routerLink="/HowItWorks" title="How it works">How it works</a></li>
        <!-- <li
            *ngIf="selectedSendingCountry?.country?.countryId !== 246 && selectedSendingCountry?.country?.countryId !== 39">
            <a class="text-muted" routerLink="/news" title="News">News & Updates</a></li> -->
        <li><a class="text-muted" routerLink="/contactus" title="Contact us">Contact Us</a></li>
        <li><a class="text-muted" routerLink="/faqs" title="FAQs">FAQs</a></li>
    </ul>
</div>
<!-- Information  [END] -->

<!-- Legal  [START] -->
<div class="col-md-2">
    <h6>Legal</h6>
    <ul class="list-unstyled text-small footer-list">
        <li><a class="text-muted" routerLink="/terms" title="Terms and conditions">Terms and Conditions</a></li>
        <li><a class="text-muted" routerLink="/complaintspolicy" title="Privacy Policy">Complaints Policy</a></li>
        <li><a class="text-muted" routerLink="/privacypolicy" title="Privacy Policy">Privacy Policy</a></li>
        <li><a class="text-muted" routerLink="/refundpolicy" title="Terms and conditions">Refund Policy</a></li>
    </ul>
</div>
<!-- Legal  [END] -->

<!-- Social Media  [START] -->
<div class="col-md-2 pr-0">
    <h6>Follow Us</h6>
    <ul class="list-unstyled text-small footer-social">
        <li><a href="#" target="blank"><button type="submit"
                    class="btn btn-outline-secondary btn-sm" aria-label="Center Align"><i
                        class="fa fa-facebook-f"></i></button></a></li>
        <li><a href="#" target="blank"><button
                    type="submit" class="btn btn-outline-secondary btn-sm" aria-label="Center Align"><i
                        class="fa fa-youtube"></i></button></a></li>
        <li><a href="#" target="blank"><button type="submit"
                    class="btn btn-outline-secondary btn-sm"><i class="fa fa-linkedin"
                        aria-label="Center Align"></i></button></a></li>
        <li><a href="#" target="blank"><button type="submit"
                    class="btn btn-outline-secondary btn-sm"><i class="fa fa-twitter"
                        aria-label="Center Align"></i></button></a></li>
        <li><a href="#" target="blank"><button type="submit"
                    class="btn btn-outline-secondary btn-sm"><i class="fa fa-instagram"
                        aria-label="Center Align"></i></button></a></li>
    </ul>
</div>
<!-- Social Media  [END] -->

        </div>
    </footer>
</div>
<div class="container-fluid p-3 copyright bg-gray">
    <div class="row">
        <div class="col-md-4">
            <div *ngIf="selectedSendingCountry?.country?.countryId !== 246 && selectedSendingCountry?.country?.countryId !== 39">
                <small class="d-block text-muted">&copy; 2019-{{currentYear}} - All Rights Reserved - <a
                        href="">Taaj</a></small>
            </div>
            <div *ngIf="selectedSendingCountry?.country?.countryId === 246 || selectedSendingCountry?.country?.countryId === 39">
                <small class="d-block text-muted">&copy; 2019-{{currentYear}} - All Rights Reserved - <a
                        href="">Taaj</a></small>
            </div>
        </div>
        <div class="col-md-8">
            <div>
                <div class="d-block float-right"><i class="fa fa-cc-visa fa-2x"></i>&nbsp;<i
                        class="fa fa-cc-mastercard fa-2x"></i>&nbsp;<i class="fa fa-expeditedssl fa-2x"></i><br></div>
            </div>
        </div>
    </div>
</div>

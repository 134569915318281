import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef  } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RegExes } from '../../../../shared/regexes';
import { CustomValidators} from '../../../../shared/CustomValidators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from '../../../../models/apiresponse';
import { AuthService } from '../../../../shared/auth.service';
import { Utilities } from '../../../../shared/utilities';
import { environment } from '../../../../../environments/environment';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessComponent } from '../../../../components/success/success.component';
import { ErrorComponent } from '../../../../components/error/error.component';
import { SendingCountry } from '../../../../models/sendingcountry';
import { Subscription } from 'rxjs';
import { AppDataService } from '../../../../shared/app-data.service';
import { Observable } from 'rxjs';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-signup-bankid',
  templateUrl: './signup-bankid.component.html'
})
export class SignupBankidComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  userInfo: any;
  maxDateOfBirth = new Date().toISOString().slice(0, 10);
  selectedSendingCountry: SendingCountry;
  selectedSenderCountrySubscription: Subscription;
  disableSubmitButton = false;
  showProgress: boolean;
  showProgress1: boolean;
  isDuplicateUser = 0;

  @ViewChild('email') email: ElementRef;
  public input$: Observable<string>;

  constructor(private fb: FormBuilder, private appDataService: AppDataService, private route: ActivatedRoute,
              private router: Router, private authService: AuthService,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
    this.route.queryParams
      .subscribe(
        (params: Params) => {
          if (params['error']) {
            window.location.assign(environment.apiProtocol + '://' + environment.apiServerIP + ':' + environment.apiServerPort
              + '/bankid/no/oidc/callback?error=' + params['error']
              + '&error_description=' + params['error_description'] + '&state=' + params['state']);
          }
          if (params['code'] && !params['userInfo'])  { // below code is a temporary fix for the oidc redirect-uri changed to backend
            window.location.assign(environment.apiProtocol + '://' + environment.apiServerIP + ':' + environment.apiServerPort
              + '/bankid/no/oidc/callback?code=' + params['code'] + '&state=' + params['state']);
          }
          if (params['err']) {
            this.dialog.open(ErrorComponent, {
              data: {
                message: params['err'].toString()
              }
            });
            return;
          }
          this.userInfo = JSON.parse(params['userInfo']);
          this.form = this.fb.group({
              fullName: [this.userInfo['fullName'], [Validators.required]],
              mobileNo: [this.userInfo['mobile'], [Validators.required, Validators.pattern(RegExes.mobileNo),
                CustomValidators.validateMobileLength(
                  this.selectedSendingCountry ? this.selectedSendingCountry.country.countryCallingCode : '',this.selectedSendingCountry ? this.selectedSendingCountry.country.mobileMinLength : null, this.selectedSendingCountry ? this.selectedSendingCountry.country.mobileMaxLength : null)
              ]],
              email: [this.userInfo['email'], [Validators.required, Validators.email], this.verifyDuplicateUser.bind(this)],
              password: [null, [Validators.required, Validators.minLength(8)]],
              confirmPassword: [null, [Validators.required, Validators.minLength(8)]]
            }
            , { validator: CustomValidators.matchingPasswords('password', 'confirmPassword') }
          );
        });
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
        this.form.controls['mobileNo'].setValidators(CustomValidators.validateMobileLength(
          this.selectedSendingCountry ? this.selectedSendingCountry.country.countryCallingCode : '',this.selectedSendingCountry ? this.selectedSendingCountry.country.mobileMinLength : null, this.selectedSendingCountry ? this.selectedSendingCountry.country.mobileMaxLength : null));
        this.form.controls['mobileNo'].updateValueAndValidity();
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
  }

  verifyDuplicateUser(val: string) {
    const q = new Promise((resolve, reject) => {
        this.authService.verifyDuplicateUser_Api(val)
          .then((apiResp: ApiResponse) => {
            this.showProgress1 = false;
            if (apiResp.result && (apiResp.result[0].emailregistered === '1' || apiResp.result[0].emailregistered === 1)) {
              this.isDuplicateUser = 1;
              resolve({ 'isDuplicateUser': 1 });
            } else {
              this.isDuplicateUser = 0;
              resolve(null);
            }
          })
          .catch((apiException: ApiResponse) => {
            this.showProgress1 = false;
            this.isDuplicateUser = 0;
            resolve(null);
          });
    });
    return q;
  }

  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
  }

  signUp() {
    if (this.form.valid) {
      this.disableSubmitButton = true;
      this.showProgress = true;
      const formValues = this.form.value;
      const fullName = this.userInfo['fullName'];
      const mobile = this.selectedSendingCountry.country.countryCallingCode +  formValues.mobileNo;
      const email = formValues.email;
      const address = this.userInfo['address'];
      const dateOfBirth = this.userInfo['dateOfBirth'];
      const locale = this.userInfo['locale'];
      const bankId = this.userInfo['nationalId'];
      const password = formValues.password;
      const countryId = this.userInfo['countryId'];
      this.authService.signupBankId_Api(fullName, mobile, email, address, dateOfBirth, locale, bankId, password, countryId)
        .then((apiResp: ApiResponse) => {
          if (apiResp.err) {
            this.disableSubmitButton = false;
            this.showProgress = false;
            this.dialog.open(ErrorComponent, {
              data: {
                message: apiResp.err.message.toString()
              }
            });
            return;
          }
          if (apiResp.result && apiResp.result.length > 0) {
            this.dialog.open(SuccessComponent, {
              data: {
                message: 'Registration completed successfully.'
              }
            });
            this.authService.signinUser(email, password)
              .then((resp: ApiResponse) => {
                this.router.navigateByUrl('/');
              })
              .catch((exception: ApiResponse) => {
                this.showProgress = false;
                this.dialog.open(ErrorComponent, {
                  data: {
                    message: exception.err.message.toString()
                  }
                });
              });
          } else {
            this.disableSubmitButton = false;
            this.showProgress = false;
            this.dialog.open(ErrorComponent, {
              data: {
                message: 'Some error occured'
              }
            });
            return;
          }
        })
        .catch((apiException: ApiResponse) => {
          this.disableSubmitButton = false;
          this.showProgress = false;
          this.dialog.open(ErrorComponent, {
            data: {
              message:  (apiException && apiException.err && apiException.err.message) ? apiException.err.message.toString() : apiException
            }
          });
        });
    }
  }
  ngAfterViewInit() {
    this.input$ = fromEvent(this.email.nativeElement, 'input')
    .pipe(debounceTime(2000),map((e: KeyboardEvent) => e.target['value']))
    this.input$.subscribe((val: string) => {
    this.showProgress1 = true;
    this.verifyDuplicateUser(val);
  });
  }
}

<div class="pad">
  <mat-dialog-content>
    <div class="notify-migration-content">
      <h4 class="px-4 pb-3" style="border-bottom: 1px solid #787878;"><i class="fa fa-bell mr-2"></i> {{content.pageTitle}}</h4>
      <p [innerHTML]="content.pageContent" class="px-4 mt-3 text-white"></p>
  </div>
   </mat-dialog-content>
  <mat-dialog-actions class="pl-4 pb-4">
      <button class="btn btn-primary mr-2" (click)="migrateOrmUser()" i18n>Accept</button>
      <button class="btn btn-primary" (click)="logout()" i18n>Reject</button>
  </mat-dialog-actions>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from '../../modules/customers/customers.service';
import { ApiResponse } from '../../models/apiresponse';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor(private customerService: CustomerService, public thisDialogRef: MatDialogRef<NotificationsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  updateNotificarionStatus() {
    const payload = {
      systemNotificationId: this.data.systemNotificationId,
      type: 'RECEIVED'
    }
    this.customerService.updateCustomerNotification(payload).then((apiResp:ApiResponse) => {
      this.thisDialogRef.close();
    })
  }

  closeDocumentNotification() {
    this.thisDialogRef.close();
  }

}

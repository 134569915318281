<div class="container minbody my-auto py-5">
  <div class="row">
    <div class="col-md-6">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="cardheader">
              <h6 i18n>ORDER SUMMARY</h6>
            </mat-panel-title>
            <mat-panel-description>
              <!-- Type your name and age -->
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-card-content>
            <div>
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Reference Number" value="{{orderNumber}}" [disabled]="disabled" style="font-size: 18px;font-weight: 500;color: #747474 !important;letter-spacing: .5px;">
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <input matInput placeholder="Description" value="Payment for Remittance" [disabled]="disabled" style="font-size: 18px;font-weight: 500;color: #747474 !important;letter-spacing: .5px;">
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <input matInput placeholder="Amount" value="{{currency}} {{amount | number:'1.1-3'}}" [disabled]="disabled" style="font-size: 18px;font-weight: 500;color: #747474 !important;letter-spacing: .5px;">
              </mat-form-field>


            </div>
          </mat-card-content>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="col-md-5">
      <form action="{{shopperResultUrl}}" class="paymentWidgets" [attr.data-brands]="paymentMethod"></form>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { SendingCountry } from '../../models/sendingcountry';
import { AppDataService } from '../../shared/app-data.service';
import { AuthService } from '../../shared/auth.service';
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { User } from '../../models/user';
import { Subscription } from 'rxjs';
import { UserSessionService } from '../../shared/usersession.service';
import { ApiResponse } from '../../models/apiresponse';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from '../success/success.component';
import { ErrorComponent } from '../error/error.component';
import { Utilities } from '../../shared/utilities';
import { environment } from '../../../environments/environment';
import { CalculationService } from '../../shared/calculation.service';
import { CountryPageHTMLContent } from '../../models/countrypagehtmlcontent';
import { PayingCountry } from '../../models/payingcountry';
import { GoogleAnalyticsService } from '../../shared/google-analytics.service';

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})
export class NavmenuComponent implements OnInit, OnDestroy {
  sendingCountryList: SendingCountry[];
  selectedSendingCountry: SendingCountry;
  currentUser: User;
  currentUserSubscription: Subscription;
  calculatorPageHTMLContent: CountryPageHTMLContent;
  selectedPayingCountry: PayingCountry;
  selectedSenderCountrySubscription: Subscription;
  paramsendingCountry: any;
  currentRoute: any;

  constructor(private appDataService: AppDataService, private authService: AuthService, private router: Router,
    private userSessionService: UserSessionService, private route: ActivatedRoute,
    public dialog: MatDialog, private calculationService: CalculationService, private googleAnalyticsService: GoogleAnalyticsService) {
    this.router.events.subscribe((event: Event) => {
      this.currentRoute = this.router.url;
    });
  }

  ngOnInit() {
    this.isActiveUserSession();
    this.currentUserSubscription = this.userSessionService.currentUserSubject.subscribe(
      (value: User) => {
        this.currentUser = value;
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      });
      this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
      if (this.router.url === '/home1') {
        this.selectedSendingCountry = null;
      }
      this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
        (value: SendingCountry) => {
          this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
        },
        (err) => {
          this.dialog.open(ErrorComponent, {
            data: {
              message: err.toString()
            }
          });
        }
      );
// If customer navigates from any of reference page, then respective sending from country is set from queryparam - @MIA
/*       this.route.queryParams
      .filter(params => params.sendingCountryId)
      .subscribe(params => {
       this.paramsendingCountry = params.sendingCountryId;
        this.sendingCountryList = this.sendingCountryList.filter(x => x.country.countryId == this.paramsendingCountry);
        this.appDataService.setSelectedSendingCountry(this.sendingCountryList[0]);
      }); */
  }

  onSelectSendingCountry(event): void {
    this.appDataService.setSelectedSendingCountry(this.sendingCountryList[event.target.selectedIndex]);
  }

  onLogout() {
    this.authService.logout_Api()
      .then((resp: ApiResponse) => {
        if (this.router.url === '/') {
          this.router.navigateByUrl('');
        } else {
          this.router.navigateByUrl('/');
        }
      })
      .catch((err: ApiResponse) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.err.message.toString()
          }
        });
      });
  }

  ngOnDestroy() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
     if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
  }

  openDialog() {
    this.dialog.open(ErrorComponent, {
      data: {
        message: 'Logout done successfully'
      }
    });
  }

  isActiveUserSession() {
    if (localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString())) {
      if (localStorage.getItem(Utilities.encryptPayLoad2('serverLastActionDateTime',environment.clientKey).toString())) {
        const serverLastActionDateTime = new Date(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('serverLastActionDateTime',environment.clientKey).toString()),environment.clientKey));
        const localStorageExpiryDateTime = Utilities.getLocalStorageExpiryDateTime(serverLastActionDateTime,
          environment.localStorageValidityInMinutes);
        const currentDateTime = new Date();
        if (currentDateTime > localStorageExpiryDateTime) {
          this.authService.isActiveUserSession_Api()
            .then((apiResp: ApiResponse) => {
              if (!(apiResp.result && apiResp.result.length > 0 && apiResp.result[0].isActive === 1)) {
                this.currentUser = null;
              } else {
                this.currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
              }
            })
            .catch((apiException: ApiResponse) => {
              this.currentUser = null;
            });
        } else {
          this.currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
        }
      } else {
        this.currentUser = null;
      }
    }
  }
  // onSelectLanguage(event){
  //   const selectedLanguageId = event.target.value;
  //   console.log(JSON.parse(localStorage.getItem('APP_DATA')).selectedPayingCountry.country.countryId);

    // this.calculatorPageHTMLContent = this.calculationService.
    // getPageHTMLContentByCountryId(this.selectedPayingCountry.country.countryId,
    //   selectedLanguageId);
  // }

  gtagEvent(cat) {
    if (cat === 'headerphone') {
      this.googleAnalyticsService.gtagClickEvent('headerphone', 'headerphoneclick', '1');
    }
    if (cat === 'secondheaderphone') {
      this.googleAnalyticsService.gtagClickEvent('secondheaderphone', 'secondheaderphoneclick', '1');
    }
  }
}

import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {isPlatformBrowser, Location} from '@angular/common';
import {ApiResponse} from '../../models/apiresponse';
import {ErrorComponent} from '../../components/error/error.component';
import {AuthService} from '../../shared/auth.service';
import {MatDialog} from '@angular/material/dialog';
import { User } from '../../models/user';
import {UserSessionService} from '../../shared/usersession.service';
import {AppDataService} from '../../shared/app-data.service';
import {Utilities} from "../../shared/utilities";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html'
})
export class CustomersComponent implements OnInit {
  isDashboard = false;
  isBrowser: boolean;
  route: string;
  currentUser: User;

  constructor(private appDataService: AppDataService, private router: Router, @Inject(PLATFORM_ID) platformId: Object, location: Location, private authService: AuthService, public dialog: MatDialog, private userSessionService: UserSessionService) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.router.events.subscribe((event: Event) => {
      this.route = location.path();
      switch (true) {
        case event instanceof NavigationEnd: {
          this.isDashboard = false;
          if (this.isBrowser) {
            this.route = location.path();
            if (this.route === '/customers/dashboard' || this.route === '/customers/mynotifications' ||
              this.route === '/customers/mydetails' || this.route === '/customers/changepassword' || this.route === '/customers/twofactorauthentication') {
              this.isDashboard = true;
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    });
  }


  ngOnInit() {
    this.currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
  }

  onLogout() {
    this.authService.logout_Api()
      .then((resp: ApiResponse) => {
        if (this.router.url === '/') {
          this.router.navigateByUrl('');
        } else {
          this.router.navigateByUrl('/');
        }
      })
      .catch((err: ApiResponse) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.err.message.toString()
          }
        });
      });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../models/apiresponse';
import { CookieService } from 'ngx-cookie-service';
import { Utilities } from './utilities';
import { UserSessionService } from './usersession.service';
import { environment } from '../../environments/environment';
import { unEncryptedEndpoints } from './constants';
import {Observable} from 'rxjs';
import {Router} from "@angular/router";

@Injectable()
export class ApiService {
    constructor(private http: HttpClient, private cookieService: CookieService,
                private userSessionService: UserSessionService, private router: Router) {}

    sendHttpPostRequest(endpoint: string, payload: any, options?: any, encrypt?: boolean, token?: string): Promise<ApiResponse> {
      return new Promise<ApiResponse>((resolve, reject) => {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        options = options ? options : { headers: headers , withCredentials: true};
        payload.systemId = environment.systemId;
        // if (unEncryptedEndpoints.indexOf(endpoint) === -1 && environment.addSecretKeyAndTimestamp) {
        //   payload.secretKey = 'Bt0zwvH3yfKuh6GRXnjOXw==';
        //   payload.requestTimestamp = new Date().toString();
        // }
        const encryptedPayload =
          unEncryptedEndpoints.indexOf(endpoint) === -1 ? this.encryptPayload(payload, encrypt, token) : payload;
        const params = {requestTimestamp: Utilities.encryptPayLoad(new Date().toString(),environment.clientKey).toString(), payload: encryptedPayload};
        const endPointUrl = environment.apiProtocol + '://' + environment.apiServerIP + ':' + environment.apiServerPort + endpoint;
        localStorage.setItem(Utilities.encryptPayLoad2('serverLastActionDateTime',environment.clientKey).toString(),
          Utilities.encryptPayLoad(new Date().toString(),environment.clientKey).toString());
        this.http.post(endPointUrl, JSON.stringify(params), options)
          .toPromise()
          .then(response => {
              const jsonResponse: any = response || {};
              if (jsonResponse.err && Array.isArray(jsonResponse.err) && jsonResponse.err.length > 0) {
                if (!environment.production) {
                  // console.log('START- ERROR PAYLOAD RECEIVED');
                  // console.log(jsonResponse);
                  // console.log('END- ERROR PAYLOAD RECEIVED');
                }
                const apiResponse: ApiResponse = new ApiResponse(null, null);
                apiResponse.err = jsonResponse.err[0];
                if (jsonResponse.err[0].code === '6002') {
                 this.userSessionService.expireUserSession();
                  apiResponse.err.message = 'Your session with us is expired.Please login again.';
                }
                if (jsonResponse.err[0].code === '1003') {
                  this.userSessionService.expireUserSession();
                  this.router.navigateByUrl('/under-maintenance');
                }
                resolve(apiResponse);
              } else if (jsonResponse.payload) {
                if (endpoint === '/login') {
                  this.getUserToken()
                    .then((apiResp: ApiResponse) => {
                      let _encrypt: boolean;
                      let _token: string;
                      if (apiResp.err) {
                        resolve(apiResp);
                      }
                      if (apiResp.result) {
                        _encrypt = apiResp.result[0].encrypt;
                        _token = apiResp.result[0].token;
                        const decryptedPayload =
                          unEncryptedEndpoints.indexOf(endpoint) === -1 ?
                            this.decryptPayload(jsonResponse.payload, _encrypt, _token) : jsonResponse.payload;
                        if (decryptedPayload.result) {
                          const apiResponse: ApiResponse = new ApiResponse(null, null);
                          apiResponse.result = decryptedPayload.result;
                          resolve(apiResponse);
                        } else {
                          if (!environment.production) {
                          // console.log('START- NO RESULT AFTER DECRYPTING');
                          // console.log(decryptedPayload);
                          // console.log('END- NO RESULT AFTER DECRYPTING');
                          }
                        }
                      }
                    })
                    .catch((err: ApiResponse) => {
                        reject(err);
                      });
                } else {
                  const decryptedPayload =
                    unEncryptedEndpoints.indexOf(endpoint) === -1 ? this.decryptPayload(jsonResponse.payload) : (Utilities.isJson(jsonResponse.payload) ? jsonResponse.payload : this.decryptPayload(jsonResponse.payload));
                  if (decryptedPayload.result) {
                    const apiResponse: ApiResponse = new ApiResponse(null, null);
                    apiResponse.result = decryptedPayload.result;
                    resolve(apiResponse);
                  } else if (decryptedPayload && endpoint === "/orm/usermanagement/verifyemail") {
                    const apiResponse: ApiResponse = new ApiResponse(null, null);
                    apiResponse.result = decryptedPayload;
                    resolve(apiResponse);
                  } else {
                    if (!environment.production) {
                    // console.log('START- NO RESULT AFTER DECRYPTING');
                    // console.log(decryptedPayload);
                    // console.log('END- NO RESULT AFTER DECRYPTING');
                    }
                  }
                }
              } else {
                if (!environment.production) {
                // console.log('START- RESPONSE PAYLOAD RECEIVED');
                // console.log(jsonResponse);
                // console.log('END- RESPONSE PAYLOAD RECEIVED');
                }
                const apiResponseError = {code: '6001', message: 'Invalid response payload !'};
                const apiResponse: ApiResponse = new ApiResponse(null, null);
                apiResponse.err = apiResponseError;
                resolve(apiResponse);
              }
            }
          )
          .catch(error => {
            const apiResponseError = {code: '6001', message: error.message || error};
            const apiResponse: ApiResponse = new ApiResponse(null, null);
            apiResponse.err = apiResponseError;
            reject(apiResponse);
          });
      });
    }
    encryptPayload(payload: any, encrypt?: boolean, token?: string) {
      if (encrypt && encrypt === true && token) {
        return Utilities.encryptPayLoad(JSON.stringify(payload), token).toString();
      } else if (this.cookieService.get('encrypt') && this.cookieService.get('token')) {
        encrypt = (this.cookieService.get('encrypt') === 'true');
        if (encrypt && encrypt === true) {
          return Utilities.encryptPayLoad(JSON.stringify(payload), this.cookieService.get('token').toString()).toString();
        }
      } else if (localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString())) {
        encrypt = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).encrypt;
        if (encrypt && encrypt === true) {
          return Utilities.encryptPayLoad(JSON.stringify(payload),
            JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).token.toString()).toString();
        }
      }
      return payload;
    }
    decryptPayload(payload: any, encrypt?: boolean, token?: string) {
    if (encrypt && (encrypt === true) && token) {
      if (!environment.production) {
      // console.log('START- DECRYPTING WITH GIVEN TOKEN');
      // console.log(token);
      // console.log('END- DECRYPTING WITH GIVEN TOKEN');
      }
      return Utilities.dycryptPayLoad(payload, token);
    } else if (this.cookieService.get('encrypt') && this.cookieService.get('token')) {
      if (!environment.production) {
      // console.log('START- DECRYPTING WITH COOKIE TOKEN');
      // console.log(this.cookieService.get('token'));
      // console.log('END- DECRYPTING WITH COOKIE TOKEN');
      }
      encrypt = (this.cookieService.get('encrypt') === 'true');
      if (encrypt && (encrypt === true)) {
        return Utilities.dycryptPayLoad(payload, this.cookieService.get('token').toString());
      }
    } else if (localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString())) {
      if (!environment.production) {
      // console.log('START- DECRYPTING WITH LOCALSTORAGE TOKEN');
      // console.log(JSON.parse(localStorage.getItem('currentUser')).token.toString());
      // console.log('END- DECRYPTING WITH LOCALSTORAGE TOKEN');
      }
      encrypt = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).encrypt;
      if (encrypt && (encrypt === true)) {
        return Utilities.dycryptPayLoad(payload, JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).token.toString());
      }
    }
    return payload;
  }
    sendHttpPostRequest_FormData(endpoint: string, fd: FormData, options?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      options = options ? options : { withCredentials: true};
      const endPointUrl = environment.apiProtocol + '://' + environment.apiServerIP + ':' + environment.apiServerPort + endpoint;
      localStorage.setItem(Utilities.encryptPayLoad2('serverLastActionDateTime',environment.clientKey).toString(),
        Utilities.encryptPayLoad(new Date().toString(),environment.clientKey).toString());
      fd.append('requestTimestamp', Utilities.encryptPayLoad(new Date().toString(), environment.clientKey).toString());
      this.http.post(endPointUrl, fd, options)
        .toPromise()
        .then(response => {
          const jsonResponse: any = response;
          if (jsonResponse.err && Array.isArray(jsonResponse.err) && jsonResponse.err.length > 0) {
            const apiResponse: ApiResponse = new ApiResponse(null, null);
            apiResponse.err = jsonResponse.err[0];
            if (jsonResponse.err[0].code === '6002') {
              this.userSessionService.expireUserSession();
              apiResponse.err.message = 'Your session with us is expired.Please login again.';
            }
            resolve(apiResponse);
          } else {
            // const decryptResponse = this.decryptPayload(response.json());
            // console.log(decryptResponse);
            // resolve(response);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getUserToken(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getUserToken');
      const payload = {};
      this.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getSiteContent(fileName: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const filePath = '/assets/content/' + fileName + '.json';
      const endPoint = window.location.origin + filePath;
      this.http.get(endPoint)
        .toPromise()
        .then(response => {
          const jsonResponse = response;
          const apiResponse: ApiResponse = new ApiResponse(null, null);
          apiResponse.result = jsonResponse;
          resolve(apiResponse);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

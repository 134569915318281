<div class="container minbody" >
  <div class="row">
    <h1><b i18n>Complete your migration</b></h1>
    <div class="col-md-8">
      <form name="migrationForm" id="migrationForm" class="form-horizontal" [formGroup]="form" *ngIf="form" novalidate autocomplete="off" (ngSubmit)="migrateonlinecustomer()">
        <div class="form-group">
          <label class="control-label col-md-3 " i18n>First Name: <span class="asteriskField">*</span></label>
          <div class="controls col-md-5" >
            <input type="text" class="form-control" id="firstName"  name="firstName" autofocus formControlName="firstName" />
            <app-showerror [control]="form.get('firstName')"></app-showerror>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-3" i18n> Middle Name: </label>
          <div class="controls col-md-5 ">
            <input class="form-control" id="middleName" name="middleName" type="text" formControlName="middleName"/>
            <app-showerror [control]="form.get('middleName')"></app-showerror>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-3 " i18n>Last Name: <span class="asteriskField">*</span></label>
          <div class="controls col-md-5">
            <input class="form-control" id="lastName" name="lastName" type="text" formControlName="lastName" />
            <app-showerror [control]="form.get('lastName')"></app-showerror>
          </div>
        </div>
        <div class="form-group required">
          <label class="control-label col-md-3  requiredField" i18n>Mobile No:
            <span class="asteriskField">*</span>
          </label>
          <div class="controls col-md-5 mobileMoney">
            <input [readonly]="true" class="form-control" id="mobileNo" name="mobileNo" type="text" formControlName="mobileNo" />
            <app-showerror [control]="form.get('mobileNo')"></app-showerror>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-3" i18n>Email: <span class="asteriskField">*</span></label>
          <div class="controls col-md-5">
            <input [readonly]="true" class="form-control" id="email" name="email" type="email" formControlName="email" />
            <app-showerror [control]="form.get('email')"></app-showerror>
            <div class="text-danger" *ngIf="isDuplicateUser === 1">Email already taken ! <a class="link" style="cursor:pointer;width:250px;color:#a94442;" (click)="recoverPassword()">Password recovery</a></div>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-3" i18n>Password: <span class="asteriskField">*</span></label>
          <div class="controls col-md-5">
            <input class="form-control" id="password" name="password"  type="password" formControlName="password" />
            <app-showerror [control]="form.get('password')"></app-showerror>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-3" i18n>Confirm Password: <span class="asteriskField">*</span></label>
          <div class="controls col-md-5">
            <input class="form-control" id="confirmPassword" name="confirmPassword" type="password" formControlName="confirmPassword" />
            <app-showerror [control]="form.get('confirmPassword')"></app-showerror>
          </div>
        </div>
        <div class="form-group">
          <div class="controls Signup col-md-8">
            <button style="float: right" class="btn btn-info btn-lg regularButton" type="submit" name="migrate" id="submit-id-migrate" [disabled]="!form.valid || disableSubmitButton"><b i18n>Submit and Create Account</b></button>
          </div>
        </div>
        <div class="form-group">
          <div class="controls col-md-12 ">
            <label class="control-label-turms requiredField loginForm">
              <p i18n>By submitting this form, you agree and accept thes <a routerLink="/terms" title="Terms and Conditions">Terms and Conditions</a> and <a routerLink="/privacypolicy" title="Privacy Policy">Privacy policy</a> and allow to contact you by email and SMS.</p>
            </label>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-4">
      <div class="right-content">
        <div class="right-content-title">
          <h2 i18n>Need Help?</h2>
        </div>

        <div class="right-content-service">
          <div class="right-angle">
            <span><i class="glyphicon glyphicon-triangle-top"></i></span>
            <p><a i18n>Contact customer service</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

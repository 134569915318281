<div class="pad">
  <mat-dialog-content>
    <div class="notify-content">
      <h4 class="px-4 pb-3" style="border-bottom: 1px solid #787878;"><i class="fa fa-bell mr-2"></i> {{data.subject}}</h4>
      <p [innerHTML]="data.message" class="px-4 mt-3 text-white"></p>
  </div>
   </mat-dialog-content>
  <mat-dialog-actions class="pl-4 pb-4">
      <button *ngIf="!data.isDocumentExpiring" class="btn btn-primary mr-2" (click)="updateNotificarionStatus()" i18n>I have read and understand this notification</button>
    <button *ngIf="data.isDocumentExpiring" class="btn btn-primary mr-2" (click)="closeDocumentNotification()" i18n>I have read and understand this updates</button>
    <button class="btn btn-primary" matDialogClose i18n>Close</button>
  </mat-dialog-actions>
</div>
import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiResponse} from '../../../../models/apiresponse';
import {ErrorComponent} from '../../../../components/error/error.component';
import {ActivatedRoute, Data, Params, Router} from '@angular/router';
import {CustomValidators} from '../../../../shared/CustomValidators';
import {AuthService} from '../../../../shared/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {CustomerService} from '../../customers.service';
import {UserSessionService} from '../../../../shared/usersession.service';
import {User} from '../../../../models/user';
import {SuccessComponent} from '../../../../components/success/success.component';
import {ApiService} from '../../../../shared/api.service';
import {Utilities} from "../../../../shared/utilities";
import {environment} from "../../../../../environments/environment";


@Component({
  selector: 'app-otpverification',
  templateUrl: './otpverification.component.html',
  styleUrls: ['./otpverification.component.css']
})
export class OtpverificationComponent implements OnInit {
  form: FormGroup;
  disableSubmitButton = false;
  otp = '';
  userId: string;
  userName: string;
  mobile: string;
  countryId: string;
  ormResendMobileVeriOtpCounter: number;
  isLoginOTPEnabled: number;
  canResendOtp = false;
  disabled = false;
  public loading = false;
  returnUrl: string;
  currentUser: User;
  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private  authService: AuthService,
              public dialog: MatDialog, private customerService: CustomerService, private userSessionService: UserSessionService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.route.data
      .subscribe(
        (data) => {
          if (data && data.customerUser && data.customerUser.err) {
            this.dialog.open(ErrorComponent, {
              data: {
                message: data.senderCustomer.err.message.toString()
              }
            });
            return;
          }

          if (data && data.customerUser && data.customerUser.result) {
            this.isLoginOTPEnabled = data.customerUser?.result[0]?.isLoginOTPEnabled;
            if(this.isLoginOTPEnabled == 1){
              this.userId = data.customerUser?.result[0]?.userId;
              this.userName = data.customerUser?.result[0]?.userName;
            } else {
              this.userId = data.customerUser?.result[0]?.encryptedUserId;
              this.userName = data.customerUser?.result[0]?.encryptedUserName;
            }

            this.mobile = data.customerUser?.result[0]?.encryptedMobile;
            this.countryId = data.customerUser?.result[0]?.encryptedCountryId;
            this.isLoginOTPEnabled = data.customerUser?.result[0]?.isLoginOTPEnabled;
            this.canResendOtp = data.customerUser?.result[0]?.canResendOtp && !this.isLoginOTPEnabled;
            this.ormResendMobileVeriOtpCounter = data.customerUser?.result[0]?.ormResendMobileVeriOtpCounter;
          }
        });
    this.form = this.fb.group({
              otp: [this.otp, Validators.compose([Validators.required]) ]
    });

  }

  sendOtpVerification() {
     if (this.form.valid) {
       this.disableSubmitButton = true;
       const formValues = this.form.value;
       const loginOTP = formValues.otp;
       this.loading = true;
       this.userName = this.userName;
       this.userId = this.userId;
       if (this.isLoginOTPEnabled == 1) {
         this.authService.verifyOtp_Api(this.userId, this.userName, loginOTP , this.isLoginOTPEnabled, 1)
           .then((apiResp: ApiResponse) => {
             this.loading = false;
             if (apiResp.err) {
               this.disableSubmitButton = false;
               this.dialog.open(ErrorComponent, {
                 data: {
                   message: apiResp.err.message.toString()
                 }
               });
               return;
             }
             if (apiResp.result) {
               apiResp.result.forEach(value => {
                 this.currentUser = new User(value.user.userId, value.user.username,
                   value.user.firstName, value.user.middleName, value.user.lastName,
                   value.user.branchDefaultCashAccountTypeId,
                   value.user.branchDefaultCashAccountId,
                   value.user.branchDefaultCashAccountName,
                   value.user.lastLogin,
                   value.user.encrypt, value.user.token,
                   value.user.countryId ? value.user.countryId : value.user.branchCountryId, value.user.customerId,
                   value.user.mobile, value.user.isSenderAddInfoAvailable, value.user.isSenderDocInfoAvailable, null, value.user.availableZeroCommRemits, value.user.isLocationRequired, value.user.ormMigrationStateId, null, null, null, null, null, null, null, null, null);
                 localStorage.setItem(Utilities.encryptPayLoad2('currentUser', environment.clientKey).toString(),
                   Utilities.encryptPayLoad(JSON.stringify(this.currentUser), environment.clientKey).toString());
                 const APP_DATA = localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA', environment.clientKey).toString()) ? JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA', environment.clientKey).toString()), environment.clientKey)) : {};
                 const currentUser = localStorage.getItem(Utilities.encryptPayLoad2('currentUser', environment.clientKey).toString()) ? JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser', environment.clientKey).toString()), environment.clientKey)) : {};
                 this.userSessionService.currentUserSubject.next(currentUser);
                 this.router.navigateByUrl(this.returnUrl);
               });
             }
           });
       } else {
         this.authService.loginOTPVerification_Api(this.userName, this.userId, loginOTP, this.ormResendMobileVeriOtpCounter)
           .then((apiResp: ApiResponse) => {
             this.loading = false;
             if (apiResp.err) {
               this.disableSubmitButton = false;
               this.dialog.open(ErrorComponent, {
                 data: {
                   message: apiResp.err.message.toString()
                 }
               });
               return;
             }
             if (apiResp.result) {
               apiResp.result.forEach(value => {
                 this.currentUser = new User(value.user.userId, value.user.username,
                   value.user.firstName, value.user.middleName, value.user.lastName,
                   value.user.branchDefaultCashAccountTypeId,
                   value.user.branchDefaultCashAccountId,
                   value.user.branchDefaultCashAccountName,
                   value.user.lastLogin,
                   value.user.encrypt, value.user.token,
                   value.user.countryId ? value.user.countryId : value.user.branchCountryId, value.user.customerId,
                   value.user.mobile, value.user.isSenderAddInfoAvailable, value.user.isSenderDocInfoAvailable, null, value.user.availableZeroCommRemits, value.user.isLocationRequired, value.user.ormMigrationStateId, null, null, null, null, null, null, null, null, null);
                 localStorage.setItem(Utilities.encryptPayLoad2('currentUser', environment.clientKey).toString(),
                   Utilities.encryptPayLoad(JSON.stringify(this.currentUser), environment.clientKey).toString());
                 const APP_DATA = localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA', environment.clientKey).toString()) ? JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA', environment.clientKey).toString()), environment.clientKey)) : {};
                 const currentUser = localStorage.getItem(Utilities.encryptPayLoad2('currentUser', environment.clientKey).toString()) ? JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser', environment.clientKey).toString()), environment.clientKey)) : {};
                 this.userSessionService.currentUserSubject.next(currentUser);
                 this.router.navigateByUrl(this.returnUrl);
               });
             }

           });
       }
     }
     }

   resendOtp() {
    this.disabled = true;
     const formValues = this.form.value;
     const loginOTP = formValues.otp;
     this.authService.resendOtp_Api(this.userName, this.userId, loginOTP, this.mobile, this.countryId, 1, this.ormResendMobileVeriOtpCounter)
       .then((apiResp: ApiResponse) => {
         this.loading = false;
         this.disabled = false;
         if (apiResp.err) {
           this.disableSubmitButton = false;
           this.dialog.open(ErrorComponent, {
             data: {
               message: apiResp.err.message.toString()
             }
           });
           return;
         }
         if (apiResp.result) {
           this.dialog.open(SuccessComponent, {
             data: {
               message: 'OTP Sent Successfully !!!'
             }
           });
           this.canResendOtp = apiResp.result[0].canResendOtp && !this.isLoginOTPEnabled;
           this.ormResendMobileVeriOtpCounter = apiResp.result[0].ormResendMobileVeriOtpCounter;
         }
       });
   }
}

<div class="mxsvg bg-lightblue">
  <div class="container-fluid ">
    <div class="row">
      <div class="col-md-10 p-5 ml-0 mt-5 minHght">
        <div>
          <div class="header-text mb-3"> SEND MONEY ONLINE <span style="color: #00000094 !important;"> WITH LOW
              FEES</span></div>
        </div>
        <h3 class="hide-on-mobile">Faster, Simpler, Safer</h3>
      </div>
      <div class="col-md-2"></div>
    </div>
    <span class="show-on-mobile"></span>
  </div>
  <div class="custom-shape-divider-bottom-1631084452">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path
        d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
        class="shape-fill"></path>
    </svg>
  </div>
</div>

<!-- <div class="container-fluid bg-lightblue">
  <div class="row">
    <div class="col-md-10 mt-5 pt-5 ml-5">
      <div>

        <div class="header-text mb-3"> SEND MONEY ONLINE <span style="color: #00000094 !important;"> WITH LOW
            FEES</span></div>
      </div>
      <h3 class="hide-on-mobile">Faster, Simpler, Safer</h3>
    </div>
    <div class="col-md-2"></div>
  </div>
  <span class="show-on-mobile"></span>
</div> -->

<div class="container-fluid">
  <div class="row">
    <!-- <div class="custom-shape-divider-bottom-1631026267">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
      </svg>
  </div> -->



    <!-- <svg height="100%" width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg"
      class="transition duration-300 ease-in-out delay-150">
      <defs>
        <linearGradient id="gradient">
          <stop offset="5%" stop-color="#79d289ff"></stop>
          <stop offset="95%" stop-color="#79d289ff"></stop>
        </linearGradient>
      </defs>
      <path
        d="M 0,400 C 0,400 0,200 0,200 C 120.26666666666665,162.39999999999998 240.5333333333333,124.79999999999998 405,147 C 569.4666666666667,169.20000000000002 778.1333333333334,251.2 958,270 C 1137.8666666666666,288.8 1288.9333333333334,244.4 1440,200 C 1440,200 1440,400 1440,400 Z"
        stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150"
        transform="rotate(-180 720 200)">
      </path>
    </svg> -->


  </div>
</div>
<!-- We are simply {START} -->

<div class="container instant-delivery pb-4">
  <app-howitworks></app-howitworks>
</div>
<div class="container-fluid bg-gray we-are-simple">
  <div class="container">
    <div class="row mt-3 mb-3">
      <div class="col-md-8 py-4 sub-title px-0">
        <h4 class="primayrColor">We are simply</h4>
        <!-- divider {start} -->
        <div class="col-md-12 section-title float-left pl-0">
          <div class="divider pull-left"></div>
        </div>
        <!-- divider {end} -->
        <h2>Our secure approach for online payments</h2>
      </div>

    </div>
    <div class="row mb-4 my-3">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class=""><img alt="image" src="assets/images/fast.svg" width="35"></div>
            <h5 class="card-title my-3 text-uppercase">Fast</h5>
            <ul class="list-group">
              <li class="list-group-item">Instant delivery to mobile money accounts</li>
              <li class="list-group-item">Collect cash at thousands of agents worldwide within minutes
              </li>
              <li class="list-group-item">Transfer funds to bank accounts instantly</li>
            </ul>

          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class=""><img alt="image" src="assets/images/secure.svg" width="35"></div>
            <h5 class="card-title my-3 text-uppercase">SAFE & SECURE</h5>
            <ul class="list-group card-img">
              <!-- <li class="list-group-item" *ngIf="selectedSendingCountry?.country?.countryId === 245">Regulated by the
                FCA
                (805769)</li>
              <li class="list-group-item" *ngIf="selectedSendingCountry?.country?.countryId === 246">Regulated by the
                FinCEN (Financial Crimes Enforcement Network)</li>
              <li class="list-group-item" *ngIf="selectedSendingCountry?.country?.countryId === 39 ">Regulated by the
                Financial Transactions and Reports Analysis Centre of Canada (FinTrac) MSB Reg. No. M14477256</li> -->
            <li class="list-group-item" *ngIf="selectedSendingCountry?.country?.countryId === 64" >Regulated by the Financial Authorities.</li>
              <li class="list-group-item"
                *ngIf="selectedSendingCountry?.country?.countryId === 129 && selectedSendingCountry?.country?.countryId">
                Regulated by the Financial Authorities.</li>
              <li class="list-group-item" *ngIf="!selectedSendingCountry?.country?.countryId">Regulated by the Financial
                Authorities</li>
              <li class="list-group-item">Trusted by banks and payment institutions worldwide</li>
              <li class="list-group-item">Verified by Visa and Mastercard </li>
            </ul>

          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class=""><img alt="image" src="assets/images/convenient.svg" width="35"></div>
            <h5 class="card-title my-3 text-uppercase">Convenient</h5>
            <ul class="list-group">
              <li class="list-group-item">Send money at your convenient time & location</li>
              <li class="list-group-item">Use your Smartphone, Laptop or Desktop</li>
              <li class="list-group-item">We are available 24 hours 7 days a week</li>
            </ul>

          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class=""><img alt="image" src="assets/images/cheaper.svg" width="35"></div>
            <h5 class="card-title my-3 text-uppercase">Cheaper</h5>
            <ul class="list-group">
              <li class="list-group-item">Lowest services fees in the market</li>
              <li class="list-group-item">Best exchange rate guaranteed</li>
              <li class="list-group-item">Faster transfer to bank accounts</li>
            </ul>

          </div>
        </div>
      </div>


    </div>
  </div>
</div>
<!-- We are simply {END} -->

<!-- Testimonial {START} -->
<!-- <section class="pt-5 pb-5 buildingBg testimonial">
  <div class="container">
    <div class="row">

      <div class="col-md-6 py-4 sub-title">
        <h4 class="primayrColor">Our Happy Clients</h4>
        <div class=" col-md-12 section-title float-left px-0">
          <div class="divider float-left px-0"></div>
        </div>
        <h2>See what others have to say</h2>
      </div>

      <div class="col-6 text-right">
        <a class="btnarrow mb-3 mr-1 " href="#carouselExampleIndicators2" role="button" data-slide="prev">
          <i class="fa fa-arrow-left"></i>
        </a>
        <a class="btnarrow mb-3" href="#carouselExampleIndicators2" role="button" data-slide="next">
          <i class="fa fa-arrow-right"></i>
        </a>
      </div>
      <div class="col-md-12 mt-3">
        <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">

          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <img class="card-img-top w-25 mx-auto mt-5" src="assets/images/userTestimonial.png"
                      alt="Card image cap">
                    <div class="card-body">
                      <h4 class="card-title text-center">Khalid</h4>
                      <p class="text-mute text-center">Taxi Driver, UK</p>
                      <p class="card-text text-center">“I am always on the road. I could never find parking at my local
                        agent. Now I just send money from my
                        phone. The App is so easy that can I send money before the traffic light is green.”
                        Previous
                      </p>

                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <img class="card-img-top w-25 mx-auto mt-5" src="assets/images/userTestimonial.png"
                      alt="Card image cap">
                    <div class="card-body">
                      <h4 class="card-title text-center">Ayan</h4>
                      <p class="text-mute text-center">Sales, Sweden</p>
                      <p class="card-text text-center">“Sending money online is the easiest way to support my family,
                        Thank you Taaj.”</p>

                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-3">
                  <div class="card">
                    <img class="card-img-top w-25 mx-auto mt-5" src="assets/images/userTestimonial.png"
                      alt="Card image cap">
                    <div class="card-body">
                      <h4 class="card-title text-center">Zakaria</h4>
                      <p class="text-mute text-center">Carpenter, Norway</p>
                      <p class="card-text text-center">“My family are back home in Kenya. I miss them a lot but thanks
                        to Taaj, I feel closer to them.
                        I send money straight to my wife’s Mpesa while we video chat.”</p>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <img class="card-img-top w-25 mx-auto mt-5" src="assets/images/userTestimonial.png"
                      alt="Card image cap">
                    <div class="card-body">
                      <h4 class="card-title text-center">Ali</h4>
                      <p class="text-mute text-center">Mechanic, Denmark</p>
                      <p class="card-text text-center">“Since my friend referred me to Taaj, I didn’t go back</p>

                    </div>

                  </div>
                </div>

                <div class="col-md-4 mb-3">
                  <div class="card">
                    <img class="card-img-top w-25 mx-auto mt-5" src="assets/images/userTestimonial.png"
                      alt="Card image cap">
                    <div class="card-body">
                      <h4 class="card-title text-center">Khalid</h4>
                      <p class="text-mute text-center">Taxi Driver, UK</p>
                      <p class="card-text text-center">“I am always on the road. I could never find parking at my local
                        agent. Now I just send money from my
                        phone. The App is so easy that can I send money before the traffic light is green.”
                        Previous
                      </p>

                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card ">
                    <img class="card-img-top w-25 mx-auto mt-5" src="assets/images/userTestimonial.png"
                      alt="Card image cap">
                    <div class="card-body">
                      <h4 class="card-title text-center">Ayan</h4>
                      <p class="text-mute text-center">Sales, Sweden</p>
                      <p class="card-text text-center">“Sending money online is the easiest way to support my family,
                        Thank you Taaj.”</p>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


</section> -->

<!-- Testimonial {END} -->


<!-- Download our mobile apps {START} -->
<div class="container mobile-app mb-5">
  <div class="row">
    <div class="col-md-7 my-5">
      <div class="col-md-12 pt-5 pb-3 section-title sub-title">
        <h4 class="primayrColor my-3">A better way to send money</h4>
        <div class="col-md-12 section-title px-0">
          <div class="divider"></div>
        </div>
        <h2>Download our mobile apps</h2>

        <p *ngIf="selectedSendingCountry?.country?.countryId === 245">
          We are authorised and regulated by the Financial Conduct Authority. We use best in class technology
          to give
          you the best experience from start to finish whilst using our service. Be assured, your money is
          safe and
          secured until its delivered.</p>
        <p *ngIf="selectedSendingCountry?.country?.countryId === 246">We are authorised and regulated by the
          FinCEN
          (Financial Crimes Enforcement Network). We use best in class technology to give you the best
          experience from
          start to finish whilst using our service. Be assured, your money is safe and secured until its
          delivered.
        </p>
        <p *ngIf="selectedSendingCountry?.country?.countryId === 39">We are authorised and regulated by the
          (FinTrac)
          MSB Reg. No. M14477256. We use best in class technology to give you the best experience from start
          to finish
          whilst using our service. Be assured, your money is safe and secured until its delivered.</p>
        <p *ngIf="selectedSendingCountry?.country?.countryId === 13">We are authorised and regulated by the
          Austrac ABN. We use best in class technology to give you the best experience from start
          to finish whilst using our service. Be assured, your money is safe and secured until its delivered.</p>
        <p *ngIf="selectedSendingCountry?.country?.countryId !== 13 && selectedSendingCountry?.country?.countryId !== 245 && selectedSendingCountry?.country?.countryId !== 246 && selectedSendingCountry?.country?.countryId !== 39 && selectedSendingCountry?.country?.countryId">
          We are authorised and regulated by the FSA Financial Supervisory Authority. We use best in class technology to give you the best experience from start to finish whilst using our service. Be assured, your money is safe and secured until its delivered.</p>
        <p *ngIf="!selectedSendingCountry?.country?.countryId">We use best in class technology to give you the best
          experience from start to finish whilst using our service. Be assured, your money is safe and secured until its
          delivered.</p>
        <div class="mt-4">
          <a href="#"><img (click)="onClickAppStoreButton()" alt="Play store" class="android-icon"
              src="assets/images/playstore-icon.webp" height="60px"></a> &nbsp;
          <a href="#"><img (click)="onClickAppStoreButton()" alt="App store" class="android-icon"
              src="assets/images/appstore-icon.webp" height="60px"></a>
        </div>
      </div>
    </div>
    <div class="col-md-5 mt-5 mobile-app-img">
      <img alt="image" src="../../../assets/images/Iphone&Iphone.png">
    </div>

  </div>
</div>
<!-- Download our mobile apps {END} -->

<div
  *ngIf="calculatorPageHTMLContent?.countryId && randomImage?.cBanner === 'assets/images/country/squares.svg'"
  class="p-3 p-md-5 text-center bg-light" [ngStyle]="{'background-image': 'url(' + randomImage?.cBanner + ')'}"
  style="background-size: auto; background-position: center; height: 200px;">
</div>
<div
  *ngIf="calculatorPageHTMLContent?.countryId && randomImage?.cBanner !== 'assets/images/country/squares.svg'"
  class="p-3 p-md-5 text-center bg-light" [ngStyle]="{'background-image': 'url(' + randomImage?.cBanner + ')'}"
  style="background-size: cover; background-position: center; height: 200px;">
</div>
<div *ngIf="!calculatorPageHTMLContent?.countryId" class="p-3 p-md-5 text-center bg-light"
  [ngStyle]="{'background-image': 'url(' + randomImage?.cBanner + ')'}"
  style="background-size: auto; background-position: center; height: 200px;">
</div>
<div class="container">
  <!-- style="margin-top:-20px;" -->
  <div class="row justify-content-center">
    <h1 class="col-md-12 text-left flag-title"> <img alt="flag" src={{imgPath}} class="">
      Send money to {{selectedPayingCountry.country.countryName}}
    </h1>
    <div class="col-md-5 calculator">
      <form name="calcForm" id="calcForm" class="corpCalculator" [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()"
        novalidate autocomplete="off">
        <div class="form-group">
          <div>
            <!-- <img id="imgTo" alt="flag" src={{imgPath}} class="float-right dd-country-flag"> -->
            <select class="custom-select custom-select-lg" (change)="onSelectPayingCountry($event)"
              name="ddlPayingCountry" formControlName="ddlPayingCountry">
              <ng-container *ngFor="let payingCountry of payingCountriesList">
                <option value="{{payingCountry?.country?.countryId}}">{{payingCountry?.country?.countryName}}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div>
            <select class="custom-select custom-select-lg" id="ddlRemittanceSubType"
              (change)="onSelectRemittanceSubType($event)" name="ddlRemittanceSubType"
              formControlName="ddlRemittanceSubType">
              <option *ngIf="remittanceSubTypesList.length > 1" [ngValue]="null" [selected]>Select Remittance Type...
              </option>
              <option *ngFor="let rSubType of remittanceSubTypesList" value="{{rSubType?.remittanceSubTypeId}}">
                {{rSubType?.remittanceSubTypeLabel}}
              </option>
            </select>
          </div>
          <div class="pt-4" *ngIf="showEbirrbankSubtypes">
            <select class="custom-select custom-select-lg" id="ddlBankOrWalletSubType" name="ddlBankOrWalletSubType"
                    formControlName="ddlBankOrWalletSubType" (change)="onSelectBank($event)">
              <option *ngIf="additionalRemitSubTypeLabels.length > 0" [ngValue]="null" [selected]>{{bankorWalletOption}}</option>
              <option *ngFor="let bank of additionalRemitSubTypeLabels"
                      value="{{bank?.branchCode}}">
                {{bank?.branchName}}
              </option>
            </select>
          </div>
        </div>
        <fieldset class="clearfix" *ngIf="showMmtAccount">
          <div class="form-group mb-0 mmtAccountNo">
            <div class="input-group">
              <div class="input-group-prepend" style="width: 70%;">
                <input class="form-control mobilePhone form-control-lg" name="mmtAccountNo" id="mmtAccountNo"
                  type="number" formControlName="mmtAccountNo" placeholder="Mobile Number" [readonly]="isDisabled" />
                <label for="mmtAccountNo">Mobile Number</label>
              </div>
              <button class="btn btn-gold btn-lg verify-btn" (click)="onVerifymmtAccount()"
                [disabled]="disableVerifyButton" i18n>Verify</button>
            </div>
            <app-showerror [control]="form.get('mmtAccountNo')"></app-showerror>
            <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="showVerifyProgress">
            </mat-progress-spinner>
            <div class="text-success my-1" *ngIf="isVerified"><i class="fa fa-check-circle fa-lg float-left pr-2"
                aria-hidden="true"></i>
              <p>Mobile Number Verified</p>
            </div>
            <div class="text-danger my-1" *ngIf="isNotVerified"><i class="fa fa-times-circle fa-lg float-left pr-2"
                aria-hidden="true"></i>
              <p>Mobile Number Not Verified</p>
            </div>
          </div>
          <div class="form-group mb-0" *ngIf="showProductList">
            <div class="input-group">
              <select class="form-control form-control-lg" (change)="onSelectRechargeType($event)"
                name="ddlRechargeType" formControlName="ddlRechargeType">
                <option *ngFor="let amount of productListAmount" value="{{amount}}">{{amount}}</option>
              </select>
              <div class="input-group-prepend">
                <div class="div-select"
                  style="background-color: #1b72ba !important; border: 1px solid #1b72ba; width: 118px;">
                  <i class="fa fa-chevron-down float-right text-success pt-1"></i>
                  <span id="cType1">{{destinationCurrency}}</span>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <div *ngIf="showAccountFields">
          <div class="form-group">
            <div class="input-group">
              <input type="number" class="form-control form-control-lg" id="txtSendingAmount" placeholder="You Send"
                name="txtSendingAmount" value=200 #txtSendingAmount formControlName="txtSendingAmount"
                value={{youSendAmount}} [readonly]="isDisabled">
              <label for="txtSendingAmount">You Send</label>
              <div class="input-group-prepend">
                <div class="div-select"
                  style="background-color: #35a849b8 !important; border: 1px solid #35a849b8; width: 118px;">
                  <i class="fa fa-chevron-down float-right text-success ml-3 pt-1"></i>
                  <span id="cType1" *ngFor="let currency of sendingCurrencyList">{{currency.currencyCode}}</span>
                  <select class="custom-select-currency" (change)="onSelectSendingCurrency($event)"
                    name="ddlSendingCurrency" formControlName="ddlSendingCurrency">
                    <option *ngFor="let currency of sendingCurrencyList" value="{{currency.currencyCode}}">
                      {{currency.currencyCode}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-0">
            <div class="input-group">
              <input type="number" class="form-control form-control-lg" id="txtPayingAmount" placeholder="They Receive"
                name="txtPayingAmount" value=22.47 #txtPayingAmount formControlName="txtPayingAmount"
                value={{theyReceiveAmount}} [readonly]="isDisabled">
              <label for="txtPayingAmount">They Receive</label>
              <div class="input-group-prepend">
                <div class="div-select"
                  style="background-color: #35a849b8 !important; border: 1px solid #35a849b8; width: 118px;">
                  <i class="fa fa-chevron-down float-right text-success ml-3 pt-1"></i>
                <span id="cType2">{{selectedPayingCurrency.currencyCode}} </span>
                  <select class="custom-select-currency" (change)="onSelectPayingCurrency($event)"
                    name="ddlPayingCurrency" formControlName="ddlPayingCurrency">
                    <option *ngFor="let currency of payingCurrencyList" value="{{currency.currencyCode}}">
                      {{currency.currencyCode}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="calc-output clearfix">
          <div ng-model="calculationresult">
          <div class="calculator-output">
            <div *ngIf="calculation && !calculation?.oldServiceFeeLocalCurrency" class="pomotionCode">
              <div class="ml-1 py-2" style="color: #1b72ba;cursor: pointer;font-weight: 600;" (click)="showPromotionCode()">Have a promotion code?</div>
              <div class="input-group" *ngIf="showPromoCode && !calculation?.oldServiceFeeLocalCurrency">
                <div class="input-group-prepend" style="width: 70%;">
                  <input class="form-control promoCode form-control-lg" name="promoCode" id="promoCode"
                         formControlName="promoCode" placeholder="Enter Promotion Code" />
                  <label for="promoCode">Enter Promotion Code</label>
                </div>
                <a style="cursor: pointer;" class="btn btn-gold btn-lg verify-btn text-white" (click)="applyPromotionCode()"
                        i18n>Apply</a>
              </div>
            </div>
            <ul class="list-group">
              <li class="list-group-item" *ngIf="calculation?.oldServiceFeeLocalCurrency">
                <p>Promo code <strong>"<u>{{promoCode}}</u>"</strong> applied <span class="float-right"><strong>{{calculation?.promotionDiscount}}% OFF</strong></span> <i title="Remove code" (click)="removePromoCode()" style="color: black !important;font-size: 15px;cursor: pointer;" class="fa fa-times-circle"></i></p>
              </li>
              <li class="list-group-item" i18n>
                <p>Fees
                  <span class="float-right">
                  <span *ngIf="calculation?.oldServiceFeeLocalCurrency" style="text-decoration: line-through">{{calculation.feeCurrency?.currencyCode}} {{calculation.oldServiceFeeLocalCurrency | number:'1.1-3'}}</span>
                  <span *ngIf="calculation?.oldServiceFeeLocalCurrency"> | </span>
                  <span *ngIf="calculation">{{calculation.feeCurrency?.currencyCode}} {{calculation.feeAmount | number:'1.1-3'}}</span>
                </span></p>
              </li>
<!--               <li class="list-group-item" data-outbound-currency="" i18n>
                <p>Exchange rate <span  *ngIf="calculation" data-outbound-currency="" class="float-right">1 {{calculation.sendCurrency?.currencyCode}} = {{calculation.exchangeRate | number:'1.1-5'}} {{calculation.payCurrency?.currencyCode}}</span></p>
              </li> -->
              <li class="list-group-item" data-outbound-currency="" i18n>
                <p style="display: inline;">Total to pay <span *ngIf="calculation" data-outbound-currency="" class="float-right">{{calculation.sendCurrency?.currencyCode}} <span class="totaltopay">{{calculation.totalSendAmount | number:'1.1-3'}}</span></span></p>
              </li>
            </ul>
          </div>
          </div>
        </div>
        <div>
          <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="showProgress"></mat-progress-spinner>
          <button type="submit" class="btn btn-warning btn-lg btn-block"
            [disabled]="!enableCalculationButton || disableSubmitButton" i18n>Send Money</button>
        </div>
        <div *ngIf="showExchangeRate">
          <p class="ml-4 pt-3">Exchange rate <span class="ml-3">1 {{selectedSendingCurrency?.currencyCode}} =
              {{exchangeRate | number:'1.1-5'}} {{selectedPayingCurrency?.currencyCode}}</span></p>
        </div>
      </form>
    </div>
    <div class="col-md-7 we-are-simple">
      <div id="carouselPartners" class="carousel slide" data-ride="carousel" data-interval="2000">
        <ol class="carousel-indicators">
          <li data-target="#carouselPartners" *ngFor="let rT of finalPartnersList; let i = index"
            [attr.data-slide-to]="i" [ngClass]="i == 0 ? 'active' : ''"></li>
        </ol>
        <div class="carousel-inner text-center">
          <div class="carousel-item partners-logo mt-1" *ngFor="let rT of finalPartnersList; let i = index"
            [ngClass]="i == 0 ? 'active' : ''">
            <span *ngFor="let rImg of rT?.csListImg">
              <img src="{{rImg.cList}}">&nbsp;
            </span>
            <h4 class="mb-4 mt-5">{{rT ? rT.csList : ''}}</h4>
            <span *ngFor="let rTD of rT?.csListDesc"><i class="fa fa-check"></i>{{rTD.csListDescItem}}<br></span>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselPartners" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselPartners" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false"
  style="position: absolute; bottom: 10px; right: 40px; z-index: 10000;">
  <div class="toast-body">
    <h5 class="mr-auto"><i class="fa fa-info-circle mr-2"></i>{{serviceNotification?.headerText}}</h5>
    <span>{{serviceNotification?.descriptionText}}</span><br>
    <button type="button" class="btn btn-sm btn-primary mt-2" data-dismiss="toast" aria-label="Close">
      <span aria-hidden="true">Got it</span>
    </button>
  </div>
</div>
<app-calculatorcommon></app-calculatorcommon>

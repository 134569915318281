import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth.service';
import { Utilities } from '../../shared/utilities';
import { ApiResponse } from '../../models/apiresponse';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { SuccessComponent } from '../success/success.component';
import { ErrorComponent } from '../error/error.component';
import { Subscription } from 'rxjs';
import { SendingCountry } from '../../models/sendingcountry';
import { AppDataService } from '../../shared/app-data.service';
import { environment } from '../../../environments/environment';
import { RegExes } from '../../shared/regexes';
import { CustomValidators } from '../../shared/CustomValidators';
import { CustomerService } from '../../modules/customers/customers.service';
import { User } from '../../models/user';
import { UserSessionService } from '../../shared/usersession.service';
import {MigrationComponent} from "../migration/migration.component";
import {ApiService} from '../../shared/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup;
  formbankid: FormGroup;
  returnUrl: string;
  emailparam = '';
  passwordResetRequired = false;
  selectedSendingCountry: SendingCountry;
  selectedSenderCountrySubscription: Subscription;
  disableSubmitButton = false;
  showProgress: boolean;
  isSecondaryDocRequired: any;
  cUser: User;
  isReCaptchaEnabled: boolean;

  constructor(private fb: FormBuilder, private appDataService: AppDataService, private router: Router, private route: ActivatedRoute, private userSessionService: UserSessionService,
              private authService: AuthService, public dialog: MatDialog, private customerService: CustomerService, private titleService: Title, private apiService: ApiService) {
    this.titleService.setTitle( "Login | Online Money Transfer | Send Money Abroad | Taaj Money Transfer Limited" );
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(
        (params: Params) => {
          if (params['err']) {
            this.dialog.open(ErrorComponent, {
              data: {
                message: params['err'].toString()
              }
            });
            return;
          }
        });
    this.isReCaptchaEnabled = environment.isReCaptchaEnabled;
    this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.email])],
      password: [null, Validators.compose([Validators.required])],
      recaptchaReactive: [null, this.isReCaptchaEnabled ? Validators.required : '']
    });
    this.formbankid = this.fb.group({
      // bankId: [null, Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])],
    });
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
  }

  login() {
    if (this.form.valid) {
      this.disableSubmitButton = true;
      this.showProgress = true;
      const formValues = this.form.value;
      const email = formValues.email;
      const password = formValues.password;
      this.authService.signinUser(email, password)
        .then((apiResp: ApiResponse) => {
          if (apiResp.err) {
            this.disableSubmitButton = false;
            this.showProgress = false;
            if (apiResp.err.code === '6015') {
              this.emailparam = email;
              this.passwordResetRequired = true;
            } else {
              this.dialog.open(ErrorComponent, {
                data: {
                  message: apiResp.err.message.toString()
                }
              });
              this.passwordResetRequired = false;
            }
            return;
          }
          if (apiResp.result) {
            if(apiResp.result.length && apiResp.result[0].user && apiResp.result[0].user.isForcePwdChange == 1){
                this.router.navigateByUrl('/customers/resetpassword?id=' + apiResp.result[0].user.resetPasswordEncryptedData);
              return;
            }

            if(apiResp.result.length && apiResp.result[0].user && apiResp.result[0].user.isOrmOtpRequired == 1 || apiResp.result[0].user.isTwoFactorAuthEnabled == 1){
              this.router.navigateByUrl('/customers/otpverification');
              return;
            }
            const APP_DATA = localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString()) ? JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString()),environment.clientKey)) : {};
            const currentUser = localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()) ? JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)) : {};
            if(currentUser.ormMigrationStateId == 1) {
              this.openMigrationDialog();
              return;
            } else if(currentUser.ormMigrationStateId == 2) {
              this.router.navigate(['/remittance/senderverification'], {
                queryParams: {
                  returnUrl: '/'
                }
              });
              return;
            }
            this.customerService.getCustomerByUserId_Api(currentUser.userId)
              .then((apiResp: ApiResponse) => {
                currentUser.isSecondaryDocRequired = apiResp.result[0].isSecondaryDocRequired;
                currentUser.isLocationRequired = apiResp.result[0].isLocationRequired;
                currentUser.ormMigrationStateId = apiResp.result[0].ormMigrationStateId;
                currentUser.buildingNo = apiResp.result[0].buildingNo;
                currentUser.city = apiResp.result[0].city;
                currentUser.placeOfBirth = apiResp.result[0].placeOfBirth;
                currentUser.postCode = apiResp.result[0].postCode;
                currentUser.state = apiResp.result[0].state;
                currentUser.address = apiResp.result[0].address;
                currentUser.dateOfBirth = apiResp.result[0].dateOfBirth;
                currentUser.accountCreateDate = apiResp.result[0].accountCreatedDate;
                localStorage.setItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString(),
                  Utilities.encryptPayLoad(JSON.stringify(currentUser),environment.clientKey).toString());
                this.userSessionService.currentUserSubject.next(currentUser);
              })
              .catch((apiException: ApiResponse) => {
                this.dialog.open(ErrorComponent, {
                  data: {
                    message: apiException.err.message.toString()
                  }
                });
              });
            if ((APP_DATA && currentUser && !this.returnUrl) || (APP_DATA && currentUser && this.returnUrl
              && apiResp.result.unMatchedSelectedSendingCountry)) {
              this.appDataService.setIsAfterLogin(true);
              this.returnUrl = '/';
            }
            this.appDataService.setIsAfterLogin(true);
            this.router.navigateByUrl(this.returnUrl);
          }

        })
        .catch((apiException: ApiResponse) => {
          this.disableSubmitButton = false;
          this.showProgress = false;
          this.dialog.open(ErrorComponent, {
            data: {
              message: apiException.err.message.toString()
            }
          });
        });
    }
  }

  signUp() {
    if (this.returnUrl === '/remittance/addrecipient') {
      this.router.navigate(['/customers/signup'], {
        queryParams: {
          returnUrl: '/remittance/addrecipient' // this.returnUrl
        }
      });
    } else {
      this.router.navigate(['/customers/signup']);
    }
  }

  isErrorVisible(field: string, validator: string) {
    return Utilities.isErrorVisible(this.form, field, validator);
  }

  resetPassword() {
    const formValues = this.form.value;
    const email = formValues.email;
    if (email) {
      this.authService.forgotPassword_Api(email)
        .then((apiResp: ApiResponse) => {
          if (apiResp.err) {
            this.dialog.open(ErrorComponent, {
              data: {
                message: apiResp.err.message.toString()
              }
            });
            this.passwordResetRequired = false;
            return;
          }
          if (apiResp.result) {
            this.router.navigateByUrl('/customers/forgotpasswordsent');
          }
        })
        .catch((apiException: ApiResponse) => {
          this.dialog.open(ErrorComponent, {
            data: {
              message: (apiException && apiException.err && apiException.err.message) ? apiException.err.message.toString() : apiException
            }
          });
        });
    } else {
      // error message invalid email
    }
  }
  redirectBankIdLogin(method: string) {
    this.authService.getBankIdLoginUrl_Api('bankid_login', method, this.selectedSendingCountry.country.countryId, null, null)
      .then((apiResp: ApiResponse) => {
        if (apiResp.err) {
          console.log(apiResp.err);
        }
        if (apiResp.result) {
          window.location.assign(apiResp.result[0].bankIdloginUrl);
        }
      })
      .catch((apiException: ApiResponse) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: apiException
          }
        });
      });
  }

  ngAfterViewInit() {

  }

  openMigrationDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      subject: 'Terms and Conditions',
      message: 'We are upgrading our system.Do you accept our updated terms and conditions ?'
    };

    const dialogRef = this.dialog.open(MigrationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog output:", data)
    );
  }
}

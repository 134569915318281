export class Country {
  public countryId: number;
  public countryName: string;
  public countryCode: string;
  public countryCallingCode: string;
  public flagUrl: string;
  public mobileMinLength: number;
  public mobileMaxLength: number;
  constructor(countryId: number, countryName: string, countryCode: string, countryCallingCode: string, flagUrl: string, mobileMinLength: number, mobileMaxLength: number) {
    this.countryId = countryId;
    this.countryName = countryName;
    this.countryCode = countryCode;
    this.countryCallingCode = countryCallingCode;
    this.flagUrl = flagUrl;
    this.mobileMinLength = mobileMinLength;
    this.mobileMaxLength = mobileMaxLength;
  }
  public static fromJson({countryId, countryName, countryCode, countryCallingCode, flagUrl, mobileMinLength, mobileMaxLength}): Country {
    return new Country(countryId, countryName, countryCode, countryCallingCode, flagUrl, mobileMinLength, mobileMaxLength);
  }
  public static toJson(country: Country): Country { // do data manipulation before sending data to api
    return country;
  }
}

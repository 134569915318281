import { Component, OnInit } from '@angular/core';
import { AppDataService } from '../../shared/app-data.service';
import { SendingCountry } from '../../models/sendingcountry';
import { Subscription } from 'rxjs';
import {ApiResponse} from '../../models/apiresponse';
import {GoogleAnalyticsService} from '../../shared/google-analytics.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-supportnumbers',
  templateUrl: './supportnumbers.component.html',
  styleUrls: ['./supportnumbers.component.css']
})
export class SupportnumbersComponent implements OnInit {

  selectedSenderCountrySubscription: Subscription;
  selectedSendingCountry: SendingCountry;
  supportList = [];

  constructor(private appDataService: AppDataService, private googleAnalyticsService: GoogleAnalyticsService, private router: Router) { }

  ngOnInit() {
    
    this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
      }
    );
    this.appDataService.getSiteContent_Api('support')
      .then((apiResp: ApiResponse) => {   
        
        if (apiResp.err) {
          console.log(apiResp.err);
          return;
        }
        if (apiResp.result) {
          this.supportList = apiResp.result.support;
        }
      })
      .catch((apiException: ApiResponse) => {
        console.log(apiException);
      });
    }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
       this.selectedSenderCountrySubscription.unsubscribe();
     }
   }

  gtagEvent(number) {
    if (this.router.url === '/contactus') {
      if (number === '+61-130-098-6750') {
        this.googleAnalyticsService.gtagClickEvent('auscontactus', 'ausphoneclick', '1');
      }
      if (number === '+46-844-682-333') {
        this.googleAnalyticsService.gtagClickEvent('swecontactus', 'swephoneclick', '1');
      }
      if (number === '+44-20-8103-3856') {
        this.googleAnalyticsService.gtagClickEvent('ukcontactus', 'ukphoneclick', '1');
      }
      if (number === '+44-20-8103-3826') {
        this.googleAnalyticsService.gtagClickEvent('uksecondcontactus', 'uksecondphoneclick', '1');
      }
      if (number === '+1-855-618-0888') {
        this.googleAnalyticsService.gtagClickEvent('usccontactus', 'uscphoneclick', '1');
      }
      if (number === '+1-855-518-1238') {
        this.googleAnalyticsService.gtagClickEvent('uscsecondcontactus', 'uscsecondphoneclick', '1');
      }
      if (number === '+44-20-7536-3888') {
        this.googleAnalyticsService.gtagClickEvent('corpcontactus', 'corpphoneclick', '1');
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {PayingCountry} from '../../models/payingcountry';
import {AppDataService} from '../../shared/app-data.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-calculatorcommon',
  templateUrl: './calculatorcommon.component.html',
  styleUrls: ['./calculatorcommon.component.css']
})
export class CalculatorcommonComponent implements OnInit {

  selectedPayingCountry: PayingCountry;
  selectedPayingCountrySubscription: Subscription;

  constructor(private appDataService: AppDataService) { }

  ngOnInit() {
    this.selectedPayingCountry = this.appDataService.getSelectedPayingCountry();
    this.selectedPayingCountrySubscription = this.appDataService.selectedPayingCountrySubject.subscribe(
      (value: PayingCountry) => {

        this.selectedPayingCountry = this.appDataService.getSelectedPayingCountry();
      });
  }

  ngOnDestroy() {
    if (this.selectedPayingCountrySubscription) {
      this.selectedPayingCountrySubscription.unsubscribe();
    }
  }

}

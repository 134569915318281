import { Component, OnInit } from '@angular/core';
import { AppDataService } from '../../shared/app-data.service';


@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.css']
})
export class UnderMaintenanceComponent implements OnInit {
  isLoading: boolean;
  days: number;
  hours;
  mins: number;
  secs: number = 58;


  constructor(private appDataService: AppDataService) {}

  countDown: any;

  ngOnInit() {
    this.isLoading = true;
    this.appDataService.getSendingCountriesList_Api()
    .then((resp: any) => {
      this.isLoading = false;
    })
    .catch((err:any) => {
      this.isLoading = false;
      this.countDown = err.err;
      this.days = err.err.remainingPeriod.nrOfDays;
      this.hours = err.err.remainingPeriod.nrOfHours;
      console.log( this.hours = err.err.remainingPeriod.nrOfHours, " GAMER");

      this.mins = err.err.remainingPeriod.nrOfMinutes;
    });

   this.counterDownTimer();


  }

  counterDownTimer() {

    var futureDate = new Date("Oct 15, 2022 00:00:00").getTime();
    var today = new Date().getTime();
    var distance = futureDate - today;
    this.days = Math.floor(distance / (1000 *60 * 60 * 24));
    var zee = this.hours = Math.floor((distance));
    console.log(zee, "ZEE");

    this.mins = Math.floor(distance % (1000 * 60 * 60)) / (1000 * 60);
    this.secs = Math.floor(distance % (1000 * 60)) / (1000);

     console.log(this.days);
     console.log(this.hours);
     console.log(this.mins);


    }
}

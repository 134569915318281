<div class="position-relative overflow-hidden bg-light">
    <div class="col-md-12 mx-auto hOnePageTitle">
        <h1 class="p-4 text-center" i18n>Contact Us</h1>
    </div>
</div>
<div class="container mt-5">
    <app-supportnumbers></app-supportnumbers>
</div>
<div
    *ngIf="selectedSendingCountry?.country?.countryId != 64 && selectedSendingCountry?.country?.countryId != 129">
    <div class="container single-page">
        <div class="row pt-5 my-3" style="border-bottom:1px solid rgb(219, 219, 219);  padding-bottom:100px;">
            <div class="col-md-12">
                <h4 class="text-center" i18n>Write to us</h4>
                <hr>
                <form name="contactUsForm" id="contactUsForm" class="form-horizontal" [formGroup]="form" *ngIf="form"
                    novalidate autocomplete="off" (ngSubmit)="contactUs()">
                    <div class="form-row pt-5">
                        <div class="form-group col-md-12">
                            <label for="contactName"></label>
                            <input type="text" class="form-control" id="contactName" name="contactName"
                                placeholder="Enter your name" formControlName="contactName" />
                            <app-showerror [control]="form.get('contactName')"></app-showerror>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="contactEmail"></label>
                            <input type="email" class="form-control" id="contactEmail" name="contactEmail"
                                placeholder="Enter your email" formControlName="contactEmail" />
                            <app-showerror [control]="form.get('contactEmail')"></app-showerror>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="contactPhone"></label>
                            <input type="text" class="form-control" id="contactPhone" name="contactPhone"
                                placeholder="Enter your phone" formControlName="contactPhone" />
                            <app-showerror [control]="form.get('contactPhone')"></app-showerror>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="contactMessage"></label>
                        <textarea class="form-control" id="contactMessage" rows="3" name="contactMessage"
                            placeholder="Enter your message" formControlName="contactMessage"></textarea>
                        <app-showerror [control]="form.get('contactMessage')"></app-showerror>
                    </div>
                    <button type="submit" class="btn btn-success mr-2" [disabled]="!form.valid || disableSubmitButton"
                        i18n>Send
                        message</button> <button type="reset" class="btn btn-gold regularButton" i18n>Cancel</button>

                </form>
            </div>
        </div>

        <div class="row p-5 my-3">
<!--            <div class="col-md-6 p-2" style="box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);">-->
<!--                <iframe title="Taaj Office location"-->
<!--                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8123.704671812276!2d17.939998898364255!3d59.400944275461214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x69e2c2c4942341c3!2sKista%20Science%20Tower!5e0!3m2!1sen!2sin!4v1609475231805!5m2!1sen!2sin"-->
<!--                    width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"-->
<!--                    tabindex="0"></iframe>-->
<!--            </div>-->
            <div class="col-md-1 py-4">
            </div>
            <div class="col-md-5 px-5">
<!--                <h4 i18n>Corporate Office</h4>-->
<!--                <hr class="float-left"><br><br>-->
<!--                <h6>Taaj Money Transfer Limited</h6>-->
<!--                <strong><p>Headquarters - GPO</p></strong>-->
<!--                <ul class="list-unstyled py-3">-->
<!--                    <li class="mb-4"><i class="fa fa-map-marker"></i> {{content.location}}</li>-->
<!--                    <li class="mb-3"><i class="fa fa-phone"></i> {{content.number}}</li>-->
<!--                    <li><i class="fa fa-envelope"></i> <a href="mailto:{{content.email}}"> {{content.email}}</a></li>-->
<!--                </ul>-->
                <div class="footer-social">
                    <h4 i18n>Follow us</h4>
                    <hr class="float-left">
                    <br>
                    <ul class="list-unstyled social-link py-3">
                        <li><a href="#" target="blank"><button
                                    aria-label="Center Align" type="submit" class="btn btn-outline-secondary btn-sm"><i
                                        class="fa fa-facebook-f"></i></button></a></li>
                        <li class="mx-2"><a href="#" target="blank"><button
                                    aria-label="Center Align" type="submit" class="btn btn-outline-secondary btn-sm"><i
                                        class="fa fa-youtube"></i></button></a></li>
                        <li><a href="#" target="blank"><button aria-label="Center Align" type="submit" class="btn btn-outline-secondary btn-sm"><i
                                    class="fa fa-linkedin"></i></button></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="selectedSendingCountry?.country?.countryId === 129">
    <div class="container single-page">
        <div class="row pt-5 my-3" style="border-bottom:1px solid rgb(219, 219, 219);  padding-bottom:100px;">
            <div class="col-md-12">
                <h4 class="text-center" i18n>Write to us</h4>
                <hr>
                <form name="contactUsForm" id="contactUsForm" class="form-horizontal" [formGroup]="form" *ngIf="form"
                    novalidate autocomplete="off" (ngSubmit)="contactUs()">
                    <div class="form-row pt-5">
                        <div class="form-group col-md-12">
                            <label for="contactName"></label>
                            <input type="text" class="form-control" id="contactName" name="contactName"
                                placeholder="Enter your name" formControlName="contactName" />
                            <app-showerror [control]="form.get('contactName')"></app-showerror>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="contactEmail"></label>
                            <input type="email" class="form-control" id="contactEmail" name="contactEmail"
                                placeholder="Enter your email" formControlName="contactEmail" />
                            <app-showerror [control]="form.get('contactEmail')"></app-showerror>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="contactPhone"></label>
                            <input type="text" class="form-control" id="contactPhone" name="contactPhone"
                                placeholder="Enter your phone" formControlName="contactPhone" />
                            <app-showerror [control]="form.get('contactPhone')"></app-showerror>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="contactMessage"></label>
                        <textarea class="form-control" id="contactMessage" rows="3" name="contactMessage"
                            placeholder="Enter your message" formControlName="contactMessage"></textarea>
                        <app-showerror [control]="form.get('contactMessage')"></app-showerror>
                    </div>
                    <button type="submit" class="btn btn-success mr-2" [disabled]="!form.valid || disableSubmitButton"
                        i18n>Send
                        message</button> <button type="reset" class="btn btn-gold regularButton" i18n>Cancel</button>
                </form>
            </div>
        </div>

        <div class="row p-5 my-3">
<!--            <div class="col-md-6 p-2" style="box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);">-->
<!--                <iframe title="Taaj Office location"-->
<!--                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9934.590778142938!2d-0.0210259!3d51.5013323!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x24a97e97a391fd63!2sPURPLE+EYE+LTD!5e0!3m2!1sen!2sin!4v1557914204883!5m2!1sen!2sin"-->
<!--                    width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>-->
<!--            </div>-->
            <div class="col-md-1 py-4">
            </div>
            <div class="col-md-5 px-5">
<!--                <h4 i18n>Corporate Office</h4>-->
<!--                <hr class="float-left"><br><br>-->
<!--                <h6>Taaj Money Transfer Limited</h6>-->
<!--                <strong><p>Headquarters - GPO</p></strong>-->
<!--                <ul class="list-unstyled py-3">-->
<!--                    <li class="mb-4"><i class="fa fa-map-marker"></i> {{content.location}}</li>-->
<!--                    <li class="mb-3"><i class="fa fa-phone"></i>{{content.number}}</li>-->
<!--                    <li><i class="fa fa-envelope"></i> <a href="mailto:{{content.email}}"> {{content.email}}</a></li>-->
<!--                </ul>-->
                <div class="footer-social">
                    <h4 i18n>Follow us</h4>
                    <hr class="float-left">
                    <br>
                    <ul class="list-unstyled social-link py-3">
                        <li><a href="https://www.facebook.com/OfficialTaaj/" target="blank"><button
                                    aria-label="Center Align" type="submit" class="btn btn-outline-secondary btn-sm"><i
                                        class="fa fa-facebook-f"></i></button></a></li>
                        <li><a href="https://www.youtube.com/channel/UCNzDqf1tDpZpdqcSOJ9FsFw" target="blank"><button
                                    aria-label="Center Align" type="submit" class="btn btn-outline-secondary btn-sm"><i
                                        class="fa fa-youtube"></i></button></a></li>
                        <li><button aria-label="Center Align" type="submit" class="btn btn-outline-secondary btn-sm"><i
                                    class="fa fa-linkedin"></i></button></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngIf="selectedSendingCountry?.country?.countryId === 64">
    <div class="container single-page">
        <div class="row pt-5 my-3" style="border-bottom:1px solid rgb(219, 219, 219);  padding-bottom:100px;">
<!--            <div class="col-md-6 p-2" style="box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);">-->
<!--                <iframe-->
<!--                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3158.451326983146!2d144.98674467624576!3d-37.6621014585571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64fb0ab630431%3A0x37250b74b0bacf96!2s108%20Moffat%20Dr%2C%20Lalor%20VIC%203075%2C%20Australia!5e0!3m2!1sen!2sin!4v1606389454915!5m2!1sen!2sin"-->
<!--                    width="100%" height="380" frameborder="0" style="border:0;" allowfullscreen></iframe>-->
<!--            </div>-->
            <div class="col-md-1 py-4">
            </div>
            <div class="col-md-5 px-5">
<!--                <h4 i18n>Corporate Office</h4>-->
<!--                <hr class="float-left">-->
<!--                <br>-->
<!--                <ul class="list-unstyled py-3">-->
<!--                    <li class="mb-4"><i class="fa fa-map-marker"></i> {{content.location}}</li>-->
<!--                    <li class="mb-3"><i class="fa fa-phone"></i> {{content.number}}-->
<!--                    </li>-->
<!--                    <li><i class="fa fa-envelope"></i> <a href="mailto:{{content.email}}"> {{content.email}}</a></li>-->
<!--                </ul>-->
                <div class="footer-social">
                    <h4 i18n>Follow us</h4>
                    <hr class="float-left">
                    <br>
                    <ul class="list-unstyled py-3">
                        <a href="https://www.facebook.com/OfficialTaaj/" target="blank"><button type="submit"
                                class="btn btn-outline-secondary btn-sm"><i class="fa fa-facebook-f"></i></button></a>
                        <a href="https://www.youtube.com/channel/UCNzDqf1tDpZpdqcSOJ9FsFw" target="blank"><button
                                type="submit" class="btn btn-outline-secondary btn-sm"><i
                                    class="fa fa-youtube"></i></button></a>
                        <button type="submit" class="btn btn-outline-secondary btn-sm"><i
                                class="fa fa-linkedin"></i></button>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row p-5 my-3">
            <div class="col-md-12">
                <h4 class="text-center" i18n>Write to us</h4>
                <hr>
                <form name="contactUsForm" id="contactUsForm" class="form-horizontal" [formGroup]="form" *ngIf="form"
                    novalidate autocomplete="off" (ngSubmit)="contactUs()">
                    <div class="form-row pt-5">
                        <div class="form-group col-md-12">
                            <label for="contactName"></label>
                            <input type="text" class="form-control" id="contactName" name="contactName"
                                placeholder="Enter your name" formControlName="contactName" />
                            <app-showerror [control]="form.get('contactName')"></app-showerror>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="contactEmail"></label>
                            <input type="email" class="form-control" id="contactEmail" name="contactEmail"
                                placeholder="Enter your email" formControlName="contactEmail" />
                            <app-showerror [control]="form.get('contactEmail')"></app-showerror>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="contactPhone"></label>
                            <input type="text" class="form-control" id="contactPhone" name="contactPhone"
                                placeholder="Enter your phone" formControlName="contactPhone" />
                            <app-showerror [control]="form.get('contactPhone')"></app-showerror>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="contactMessage"></label>
                        <textarea class="form-control" id="contactMessage" rows="3" name="contactMessage"
                            placeholder="Enter your message" formControlName="contactMessage"></textarea>
                        <app-showerror [control]="form.get('contactMessage')"></app-showerror>
                    </div>
                    <button type="submit" class="btn btn-success" [disabled]="!form.valid || disableSubmitButton"
                        i18n>Send
                        message</button> <button type="reset" class="btn btn-gold regularButton" i18n>Cancel</button>
                </form>
            </div>
        </div>
    </div>
</div>

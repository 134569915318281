export class User {
  public userId: number;
  public userName: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public accountTypeId: number;
  public accountId: number;
  public accountName: string;
  public lastLogin: string;
  public encrypt: boolean;
  public token: string;
  public countryId: number;
  public customerId: number;
  public mobile: string;
  public isSenderAddInfoAvailable: number;
  public isSenderDocInfoAvailable: number;
  public isSecondaryDocRequired: number;
  public availableZeroCommRemits: number;
  public isLocationRequired: boolean;
  public ormMigrationStateId: number;
  public buildingNo: number;
  public city: string;
  public placeOfBirth: string;
  public postCode: string;
  public state: string;
  public address: string;
  public dateOfBirth: string;
  public accountCreateDate: string;
  public customerStateId: number;


  public static fromJson({userId, userName, firstName, middleName, lastName, accountTypeId, accountId, accountName, lastLogin, encrypt,
                           token, countryId, customerId, mobile, isSenderAddInfoAvailable, isSenderDocInfoAvailable, isSecondaryDocRequired, availableZeroCommRemits, isLocationRequired, ormMigrationStateId, buildingNo, city, placeOfBirth, postCode, state, address, dateOfBirth, accountCreateDate, customerStateId}): User {
    return new User(userId, userName, firstName, middleName, lastName, accountTypeId, accountId,
      accountName, lastLogin, encrypt, token, countryId, customerId, mobile, isSenderAddInfoAvailable, isSenderDocInfoAvailable, isSecondaryDocRequired, availableZeroCommRemits, isLocationRequired, ormMigrationStateId, buildingNo, city, placeOfBirth, postCode, state, address, dateOfBirth, accountCreateDate, customerStateId);
  }
  public static toJson(user: User): User { // do data manipulation before sending data to api
    return user;
  }
  constructor(userId: number, userName: string, firstName: string, middleName: string, lastName: string, accountTypeId: number,
              accountId: number, accountName: string, lastLogin: string, encrypt: boolean, token: string,
              countryId: number, customerId: number, mobile: string,
              isSenderAddInfoAvailable: number, isSenderDocInfoAvailable: number, isSecondaryDocRequired: number, availableZeroCommRemits: number, isLocationRequired: boolean, ormMigrationStateId: number, buildingNo: number, city: string, placeOfBirth: string, postCode: string, state: string, address: string, dateOfBirth: string, accountCreateDate: string, customerStateId: number) {
    this.userId = userId;
    this.userName = userName;
    this.firstName = firstName;
    this.middleName =  middleName;
    this.lastName = lastName;
    this.accountTypeId = accountTypeId;
    this.accountId = accountId;
    this.accountName = accountName;
    this.lastLogin = lastLogin;
    this.encrypt = encrypt;
    this.token = token;
    this.countryId = countryId;
    this.customerId = customerId;
    this.mobile = mobile;
    this.isSenderAddInfoAvailable = isSenderAddInfoAvailable;
    this.isSenderDocInfoAvailable = isSenderDocInfoAvailable;
    this.isSecondaryDocRequired = isSecondaryDocRequired;
    this.availableZeroCommRemits = availableZeroCommRemits;
    this.isLocationRequired = isLocationRequired;
    this.ormMigrationStateId = ormMigrationStateId;
    this.buildingNo = buildingNo;
    this.city = city;
    this.placeOfBirth = placeOfBirth;
    this.postCode = postCode;
    this.state = state;
    this.address = address;
    this.dateOfBirth = dateOfBirth;
    this.accountCreateDate = accountCreateDate;
    this.customerStateId = customerStateId;
  }
}

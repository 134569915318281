import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomerService } from '../customers.service';
import { ApiResponse } from '../../../models/apiresponse';
import { User } from '../../../models/user';
import {Utilities} from "../../../shared/utilities";
import {environment} from "../../../../environments/environment";


@Injectable()
export class MynotificationResolver implements Resolve<any> {
    currentUser: User;
    constructor(private customerService: CustomerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApiResponse>  {
      if (localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString())) {
        return from(this.customerService.getCustomerNotification());
      }
      return of(null);
    }
}

<app-homegeneral></app-homegeneral>
<div class="container-fluid section-one bg-lightblue">
  <div class="row pt-5 justify-content-center">
      <div class="col-md-12 mx-auto  text-center">
          <div>
              <div class="header-text mb-3">Remittance <span style="color: #1b72ba !important;">Made EASY</span></div>
          </div>
          <h4 class="hide-on-mobile">Faster, simpler, safer</h4>
      </div>
  </div>
  <br><br><br><br><br><br><br><br><span class="show-on-mobile"><br><br><br><br></span>
</div>
<div class="container howitworks pb-3">
<app-howitworks></app-howitworks>
</div>
<div class="container instant-delivery">
  <div class="row mt-5 mb-3">
      <div class="col-md-12 pt-5 pb-3 text-center section-title">
          <h2>How it Works</h2>
          <div class="divider"></div>
      </div>
  </div>
  <div class="row mx-auto-5 pb-5">
    <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="showProgress" class="instantdelivery-progress">
    </mat-progress-spinner>
      <div class="col-6 col-sm-4 text-center my-2 instantdelivery" *ngFor="let instantPayingCountry of instantPayingCountriesList">
          <button type="submit" class="btn btn-block btn-light" (click)="selectedInstantPayingCountry(instantPayingCountry)"><img alt="country flag" src="{{instantPayingCountry?.country?.flagUrl}}" class="mr-2"><span style="margin-top: 3px;">{{instantPayingCountry?.country?.countryName}}</span></button>
      </div>
  </div>
</div>

<div class="container-fluid bg-gray we-are-simple">
  <div class="row mt-3 mb-3">
      <div class="col-md-12 pt-5 pb-3 text-center section-title">
          <h2>We are simply</h2>
          <div class="divider"></div>
      </div>
  </div>
  <div class="row mx-auto-5">
      <div class="col-md-6 item-right-border">
          <img alt="image" src="assets/images/fast.svg" width="100">
          <div class="item-content">
              <h4>Fast</h4>
              <ul class="list-group">
                  <li class="list-group-item">Instant delivery to mobile money accounts</li>
                  <li class="list-group-item">Collect cash at thousands of agents worldwide within minutes</li>
                  <li class="list-group-item">Transfer funds to bank accounts instantly</li>
              </ul>
          </div>
      </div>
      <div class="col-md-6 item-right-border item-no-right-border">
          <img alt="image" src="assets/images/secure.svg" width="100">
          <div class="item-content">
              <h4>Safe & Secure</h4>
<!--              <ul class="list-group card-img">-->
<!--                <li class="list-group-item" *ngIf="selectedSendingCountry?.country?.countryId === 245">Regulated by the FCA (805769)</li>-->
<!--                <li class="list-group-item" *ngIf="selectedSendingCountry?.country?.countryId === 246">Regulated by the FinCEN (Financial Crimes Enforcement Network)</li>-->
<!--                <li class="list-group-item" *ngIf="selectedSendingCountry?.country?.countryId === 39 ">Regulated by the Financial Transactions and Reports Analysis Centre of Canada (FinTrac) MSB Reg. No. M14477256</li>-->
<!--                <li class="list-group-item" *ngIf="selectedSendingCountry?.country?.countryId === 13">Austrac ABN Regulated Reg. number : 100672264.</li>-->
<!--                &lt;!&ndash; <li class="list-group-item" *ngIf="selectedSendingCountry?.country?.countryId !== 13 && selectedSendingCountry?.country?.countryId !== 245 && selectedSendingCountry?.country?.countryId !== 246 && selectedSendingCountry?.country?.countryId !== 39 && selectedSendingCountry?.country?.countryId">Regulated by the FSA (45537)</li> &ndash;&gt;-->
<!--                <li class="list-group-item" *ngIf="!selectedSendingCountry?.country?.countryId">Regulated by the Financial Authorities</li>-->
<!--                <li class="list-group-item">Verified by Visa and Mastercard <img alt="Visa Card" src="assets/images/visa.webp"> &nbsp; <img alt="Master Card" src="assets/images/master.webp"></li>-->
<!--                <li class="list-group-item">Trusted by banks and payment institutions worldwide</li>-->
<!--              </ul>-->
          </div>
      </div>
      <div class="col-md-6 item-right-border item-no-bottom-border">
          <img alt="image" src="assets/images/convenient.svg" width="100">
          <div class="item-content">
              <h4>Convenient</h4>
              <ul class="list-group">
                  <li class="list-group-item">Send money at your convenient time & location</li>
                  <li class="list-group-item">Use your Smartphone, Laptop or Desktop</li>
                  <li class="list-group-item">We are available 24 hours 7 days a week</li>
              </ul>
          </div>
      </div>
      <div class="col-md-6 item-right-border item-no-right-border item-no-bottom-border">
          <img alt="image" src="assets/images/cheaper.svg" width="100">
          <div class="item-content">
              <h4>Cheaper</h4>
              <ul class="list-group">
                  <li class="list-group-item">Lowest services fees in the market</li>
                  <li class="list-group-item">Best exchange rate guaranteed</li>
                  <li class="list-group-item">Faster transfer to bank accounts</li>
              </ul>
          </div>
      </div>
  </div>
</div>

<!-- <div class="container-fluid testimonials pb-5">
  <div class="row">
      <div class="col-md-12 pt-5 pb-3 text-center section-title">
          <h2>See what others have to say</h2>
          <div class="divider"></div>
      </div>
  </div>
  <div class="row pb-5 pt-3 mx-auto-5 justify-content-center">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner text-center">
              <div class="carousel-item active">
                  <img alt="image" src="assets/images/user.svg" width="60">
                  <h4 class="mt-4">Khalid</h4>
                  <p class="text-muted font-italic">Taxi Driver, UK</p>
                  <h6>“I am always on the road. I could never find parking at my local agent. Now I just send money from my<br>phone. The App is so easy that can I send money before the traffic light is green.”</h6>
              </div>
              <div class="carousel-item">
                  <img alt="image" src="assets/images/user.svg" width="60">
                  <h4 class="mt-4">Ayan</h4>
                  <p class="text-muted font-italic">Sales, Sweden</p>
                  <h6>“Sending money online is the easiest way to support my family, Thank you Taaj.”</h6>
              </div>
              <div class="carousel-item">
                  <img alt="image" src="assets/images/user.svg" width="60">
                  <h4 class="mt-4">Zakaria</h4>
                  <p class="text-muted font-italic">Carpenter, Norway</p>
                  <h6>“My family are back home in Kenya. I miss them a lot but thanks to Taaj, I feel closer to them.<br>I send money straight to my wife’s Mpesa while we video chat.”</h6>
              </div>
              <div class="carousel-item">
                <img alt="image" src="assets/images/user.svg" width="60">
                <h4 class="mt-4">Ali</h4>
                <p class="text-muted font-italic">Mechanic, Denmark</p>
                <h6>“Since my friend referred me to Taaj, I didn’t go back</h6>
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
          </a>
      </div>
  </div>
</div> -->
<div class="container mobile-app my-5">
  <div class="row">
      <div class="col-md-6 mobile-app-img">
          <img alt="image" src="assets/images/mobile-app.webp">
      </div>
      <div class="col-md-6 my-5">
          <div class="col-md-12 pt-5 pb-3 section-title">
              <h2>Download our mobile apps</h2>
              <h4>A better way to send money</h4>
              <div class="divider-simple mb-4"></div>
              <p *ngIf="selectedSendingCountry?.country?.countryId !== 246 && selectedSendingCountry?.country?.countryId !== 39">We are authorised and regulated by the Financial Conduct Authority. We use best in class technology to give you the best experience from start to finish whilst using our service. Be assured, your money is safe and secured until its delivered.</p>
              <p *ngIf="selectedSendingCountry?.country?.countryId === 246">We are authorised and regulated by the FinCEN (Financial Crimes Enforcement Network). We use best in class technology to give you the best experience from start to finish whilst using our service. Be assured, your money is safe and secured until its delivered.</p>
              <p *ngIf="selectedSendingCountry?.country?.countryId === 39">We are authorised and regulated by the (FinTrac) MSB Reg. No. M14477256. We use best in class technology to give you the best experience from start to finish whilst using our service. Be assured, your money is safe and secured until its delivered.</p>
            <div class="mt-4">
                  <a href="#" target="blank"><img (click)="onClickAppStoreButton()" alt="Play store" class="android-icon" src="assets/images/playstore-icon.webp" height="60px"></a> &nbsp;
                      <a href="#" target="blank"><img (click)="onClickAppStoreButton()" alt="App store" class="android-icon" src="assets/images/appstore-icon.webp" height="60px"></a>
              </div>
          </div>
      </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import {ApiResponse} from '../../models/apiresponse';
import {ErrorComponent} from '../error/error.component';
import {AuthService} from '../../shared/auth.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.authService.logout_Api()
      .then((resp: ApiResponse) => {
        window.location.assign('about:blank');
      })
      .catch((err: ApiResponse) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.err.message.toString()
          }
        });
      });
  }
}

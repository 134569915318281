<div class="container-fluid bg-lightblue">
  <div class="row mx-auto-6">
    <nav class="navbar navbar-expand-lg navbar-light" role="navigation">
      <a *ngIf="selectedSendingCountry?.country?.countryId !== 246 && selectedSendingCountry?.country?.countryId !== 39"
        class="navbar-brand" style="padding-bottom: 0px;" routerLink="/"><img alt="Logo"
          src="../../../assets/images/Taaj-Logo.svg" height="65"></a>
      <a *ngIf="selectedSendingCountry?.country?.countryId === 246 || selectedSendingCountry?.country?.countryId === 39"
        class="navbar-brand" style="padding-bottom: 0px;" routerLink="/"><img alt="Logo"
         src="../../../assets/images/Taaj-Logo.svg" height="65"></a>
      <button *ngIf="!currentUser" class="navbar-toggler" type="button" data-toggle="collapse"
        data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav ml-auto">
          <ng-template [ngIf]="!currentUser">
            <li class="nav-item navlink">
              <a routerLink="/faqs" class="nav-link">Help</a>
            </li><span class="seperator hide-on-mobile mx-3">|</span>
            <li class="nav-item navlink">
              <a routerLink="/help" class="nav-link">Support 24/7</a>
            </li>
            <span *ngIf="!selectedSendingCountry?.country?.countryId"
              class="seperator hide-on-mobile ml-3 mr-4">|</span>
            <li class="nav-item nav-button call-btn"
              *ngIf="selectedSendingCountry?.country?.countryId && selectedSendingCountry?.country?.countryId === 64">
              <button type="button" class="btn btn-outline-dark btn-sm mr-3"><a href="tel:+97142594723"
                  (click)="gtagEvent('headerphone')">+971 4 259 4723</a></button>
            </li>
            <li class="nav-item nav-button call-btn"
              *ngIf="selectedSendingCountry?.country?.countryId && selectedSendingCountry?.country?.countryId === 129">
              <button type="button" class="btn btn-outline-dark btn-sm mr-3"><a href="tel:+0202214722"
                  (click)="gtagEvent('secondheaderphone')">+020 221 4722</a></button>
            </li>
            <li class="nav-item nav-button call-btn"
              *ngIf="selectedSendingCountry?.country?.countryId && selectedSendingCountry?.country?.countryId === 129">
              <button type="button" class="btn btn-outline-dark btn-sm"><a href="tel:+0733406658"
                  (click)="gtagEvent('secondheaderphone')">+0733 406 658</a></button>
            </li>
            <li class="nav-item navlink ml-3">
              <a routerLink="/HowItWorks" class="nav-link">How it works</a>
            </li><span class="seperator hide-on-mobile ml-3 mr-4">|</span>
            <li class="nav-item nav-button">
              <button type="button" routerLink="/login" class="btn btn-success mx-1">LOG IN</button>
            </li>
            <li class="nav-item nav-button">
              <button type="button" routerLink="/customers/signup"
                class="btn btn-outline-success mx-1">REGISTER</button>
            </li>
          </ng-template>
        </ul>
      </div>
      <ng-template [ngIf]="currentUser">
        <ul ngClass="dbIcons">
          <li> <a class="p-2" routerLink="/customers/dashboard" i18n>
              <span><i class="fa fa-user-circle fa-1x pt-1 " style="padding-right:2px !important"></i> <span
                  class="hide-on-mobile">Dashboard</span> </span>
            </a></li>
          <li> <a class="pointerCursor p-2" (click)="onLogout()" i18n>
              <span><i class="fa fa-sign-out fa-1x" style="padding-right:5px !important"></i> <span
                  class="hide-on-mobile">Logout</span> </span>
            </a></li>
        </ul>
      </ng-template>
    </nav>
  </div>
</div>

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RegExes } from '../../../../shared/regexes';
import { CustomValidators} from '../../../../shared/CustomValidators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ApiResponse } from '../../../../models/apiresponse';
import { AuthService } from '../../../../shared/auth.service';
import { Utilities } from '../../../../shared/utilities';
import { environment } from '../../../../../environments/environment';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessComponent } from '../../../../components/success/success.component';
import { ErrorComponent } from '../../../../components/error/error.component';
import { SendingCountry } from '../../../../models/sendingcountry';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-migrateonlinecustomer',
  templateUrl: './migrateonlinecustomer.component.html'
})
export class MigrateonlinecustomerComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  onlineCustomerInfo: any;
  isDuplicateUser = 0;
  disableSubmitButton = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute,
              private router: Router, private authService: AuthService,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(
        (params: Params) => {
          if (params['q']) {
            this.onlineCustomerInfo = JSON.parse(decodeURIComponent(params['q']));
          }
          this.form = this.fb.group({
              firstName: [Utilities.splitFullName2(this.onlineCustomerInfo['name']).firstName, [Validators.required]],
              middleName: [null],
              lastName: [Utilities.splitFullName2(this.onlineCustomerInfo['name']).lastName, [Validators.required]],
              mobileNo: [this.onlineCustomerInfo['mobile'], [Validators.required, Validators.pattern(RegExes.mobileNo)]],
              email: [this.onlineCustomerInfo['email'], [Validators.required, Validators.email]],
              password: [null, [Validators.required, Validators.minLength(8)]],
              confirmPassword: [null, [Validators.required, Validators.minLength(8)]]
            }
            , { validator: CustomValidators.matchingPasswords('password', 'confirmPassword') }
          );
        });
  }

  migrateonlinecustomer() {
    if (this.form.valid) {
      this.disableSubmitButton = true;
      const formValues = this.form.value;
      const customerId = this.onlineCustomerInfo['id'];
      const firstName = formValues.firstName;
      const middleName = formValues.middleName ? formValues.middleName : '';
      const lastName = formValues.lastName;
      const mobile = this.onlineCustomerInfo['mobile'];
      const email = this.onlineCustomerInfo['email'];
      const password = formValues.password;
      this.authService.verifyDuplicateUser_Api(email)
        .then((apiResp: ApiResponse) => {
          if (apiResp.result && (apiResp.result[0].emailregistered === '1' || apiResp.result[0].emailregistered === 1)) {
            this.isDuplicateUser = 1;
          } else {
            this.isDuplicateUser = 0;
            this.authService.migrateOnlineCustomer_Api(customerId, firstName, middleName, lastName, mobile, email, password)
              .then((resp: ApiResponse) => {
                if (resp.err) {
                  this.disableSubmitButton = false;
                  this.dialog.open(ErrorComponent, {
                    data: {
                      message: resp.err.message.toString()
                    }
                  });
                  return;
                }
                if (resp.result && resp.result.length > 0) {
                  this.dialog.open(SuccessComponent, {
                    data: {
                      message: 'Migration completed successfully.'
                    }
                  });
                  this.authService.signinUser(email, password)
                    .then((resp2: ApiResponse) => {
                      this.router.navigateByUrl('/');
                    })
                    .catch((exception: ApiResponse) => {
                      this.dialog.open(ErrorComponent, {
                        data: {
                          message: exception.err.message.toString()
                        }
                      });
                    });
                } else {
                  this.disableSubmitButton = false;
                  this.dialog.open(ErrorComponent, {
                    data: {
                      message: 'Some error occured'
                    }
                  });
                  return;
                }
              })
              .catch((apiException: ApiResponse) => {
                this.disableSubmitButton = false;
                this.dialog.open(ErrorComponent, {
                  data: {
                    message:  (apiException && apiException.err && apiException.err.message) ? apiException.err.message.toString() : apiException
                  }
                });
              });
          }
        })
        .catch((apiException: ApiResponse) => {
          this.isDuplicateUser = 0;
          this.disableSubmitButton = false;
        });
    }
  }

  recoverPassword() {
    const emailId = this.onlineCustomerInfo['email'];
    this.router.navigateByUrl('/customers/forgotpassword?emailId=' + emailId);
  }

  ngAfterViewInit() {

  }
}

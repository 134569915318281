import { Country } from './country';
import { Currency } from './currency';
import { PayingCountry } from './payingcountry';

export class SendingCountry {
  public country: Country;
  public currencies: Currency[];
  public payingCountries: PayingCountry[];
  public isDefault: number;
  constructor(country: Country, currencies: Currency[], payingCountries: PayingCountry[], isDefault: number) {
    this.country = country;
    this.currencies = currencies;
    this.payingCountries = payingCountries;
    this.isDefault = isDefault;
  }
  public static fromJsonList(list): SendingCountry[] {
    return list.map(SendingCountry.fromJson);
  }
  public static fromJson({countryId,languageId, countryName, countryCode, countryCallingCode, flagUrl, mobileMinLength, mobileMaxLength,
                           currencies, payingCountries, isDefault}): SendingCountry { // do data manipulation after getting data from api
   return new SendingCountry(new Country(countryId, countryName, countryCode, countryCallingCode, flagUrl, mobileMinLength, mobileMaxLength), currencies, payingCountries, isDefault);
  }
  public static toJson(fromCountry: SendingCountry): SendingCountry { // do data manipulation before sending data to api
    return fromCountry;
  }
}

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>

<div class="position-relative overflow-hidden dshbrd-light">
  <div class="col-md-12">
    <h5 class="py-3 divdr" i18n>Two-Factor Authentication</h5>
  </div>
</div>
<div class="container ml-0">
  <div class="row">

    <div class="col-md-12 col-sm-12 bg-light mt-4 ml-0 pb-5" style="box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 20%); border-radius: 5px;">
      <mat-stepper  #stepper [linear]="isLinear" class="mt-4">
        <mat-step #step1 [stepControl]="firstFormGroup" disableRipple>
          <form [formGroup]="firstFormGroup" class="form-horizontal ng-pristine ng-invalid ng-touched my-4" novalidate autocomplete="off">
            <ng-template matStepLabel>Step 1</ng-template>
            <mat-radio-group aria-label="Select an option" formControlName="isEnabled" [value]="1" (change)="updateValidity($event.value)">
              <mat-radio-button value="1" class="mb-2 " color="primary">Enable</mat-radio-button>
              <mat-radio-button value="0" class="mb-2" color="primary">Disable</mat-radio-button>
            </mat-radio-group>
            <div class="row ml-3">
              <button mat-button class="btn-primary btn-sm" (click)="sendOtp()">Continue</button>
            </div>
          </form>
        </mat-step>
        <mat-step #step2 [stepControl]="secondFormGroup" label="Step 2"  [completed]="step1Completed" disableRipple>
          <form [formGroup]="secondFormGroup" (ngSubmit)="verifyOtp()" class="form-horizontal ng-pristine ng-invalid ng-touched my-4" novalidate autocomplete="off">

            <div class="form-group">
              <label class="control-label col-md-12" i18n>Please enter the OTP we just sent you to  your Email :
                <span class="asteriskField">*</span>
              </label>
              <div class="controls col-lg-8 col-sm-12 col-md-8">
                <input type="password" class="form-control" id="otp" placeholder="Enter OTP" name="otp" autofocus formControlName="otp"
                />
                <app-showerror [control]="secondFormGroup.get('otp')"></app-showerror>
              </div>
            </div>
            <div class="form-group">
              <div class="controls Signup col-md-12">
                <button class="btn btn-success btn-lg regularButton mt-2" type="submit" name="Updatepassword" id="submit-id-changepassword" [disabled]="!secondFormGroup.valid">
                  <span style="font-size: 16px; letter-spacing: 1px" i18n>Submit</span>
                </button>
                <span class="d-flex align-items-center d-flex justify-content-center">
            </span>
              </div>
            </div>

          </form>
        </mat-step>
        <mat-step #step3 disableRipple>
          <ng-template matStepLabel>Done</ng-template>
          <p>You are now done.</p>
          <div>
            <button mat-button class="btn-primary btn-sm mr-4">Back</button>
            <button mat-button (click)="stepper.reset()" class="btn-primary btn-sm">Reset</button>
          </div>
        </mat-step>
      </mat-stepper>

    </div>
  </div>
</div>

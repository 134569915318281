import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Router, NavigationEnd} from '@angular/router';
import { environment } from '../../environments/environment';
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {

  constructor(router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
    if (!environment.production) { return; } // <-- If you want to enable GA only in production
  }

  gtagClickEvent(eventCategory: string, eventLabel: string = null, eventValue: string = null) {
    gtag('event', 'click', {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue
    });
  }
}

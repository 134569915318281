import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { Utilities } from '../../shared/utilities';
import { ApiResponse } from '../../models/apiresponse';
import {environment} from "../../../environments/environment";

@Injectable()
export class CustomerService {

  constructor(private apiService: ApiService) {}

  getCustomerByUserId_Api(userId: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getcustbyuserid');
      const payload = {userId: userId};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getCustomerNotification(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getcustnotifications');
      const currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
      if(!currentUser) {
        resolve(null);
      } else {
        const payload = {userId: currentUser.userId};
        this.apiService.sendHttpPostRequest(endpoint, payload)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      }
    });
  }

  updateCustomerNotification(payload): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('updateSystemNotificationByAlert');
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  checkMaintenanceMode(currentUser): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('checkmaintenancemode');
      const payload = {userId: currentUser.userId};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  checkDocumentExpiry_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('checkdocumentexpiry');
      const payload = {};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

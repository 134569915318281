import { Component, OnInit, Input } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-transferreceipt',
  templateUrl: './transferreceipt.component.html',
  styleUrls: ['./transferreceipt.component.css']
})
export class TransferreceiptComponent implements OnInit {
  @Input() fileURL;
  trustedfileUrl: SafeUrl;

  constructor(private sanitizer: DomSanitizer, public dialog: MatDialog) { }

  ngOnInit() {
    this.trustedfileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileURL);
  }

}

import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { PaymentGatewayService } from './paymentgateway.service';

@Injectable()
export class SavedCardsResolver implements Resolve<any> {
  constructor(private paymentGatewayService: PaymentGatewayService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return from(this.paymentGatewayService.getCustomerSavedCards_Api());
  }
}

export const weAreSimpleHTMLContentList = [
  {
    countryId: 245,
    csList: 'Safe & Secure',
    csListImg: [
      { cList: '../assets/images/secure.svg' }
    ],
    csListDesc: [
      // { csListDescItem: 'FCA Regulated Reg. number 805769' },
      { csListDescItem: 'Visa card verified' },
      { csListDescItem: 'Master card verified' }
    ]
  },
  {
    countryId: 13,
    csList: 'Safe & Secure',
    csListImg: [
      { cList: '../assets/images/secure.svg' }
    ],
    csListDesc: [
      { csListDescItem: 'Austrac ABN Regulated Reg. number : 100672264.' },
      { csListDescItem: 'Visa card verified' },
      { csListDescItem: 'Master card verified' }
    ]
  },
  {
    countryId: 246,
    csList: 'Safe & Secure',
    csListImg: [
      { cList: '../assets/images/secure.svg' }
    ],
    csListDesc: [
      { csListDescItem: 'FinCEN Regulated Reg. number 31000187935195' },
      { csListDescItem: 'Visa card verified' },
      { csListDescItem: 'Master card verified' }
    ]
  },
  {
    countryId: null,
    csList: 'Fast',
    csListImg: [
      { cList: '../assets/images/fast.svg' }
    ],
    csListDesc: [
      { csListDescItem: 'Instant delivery to mobile money' },
      { csListDescItem: 'Cash collection takes just minutes' },
      { csListDescItem: 'Faster transfer to bank accounts' }
    ]
  },
  {
    countryId: null,
    csList: 'Convenient',
    csListImg: [
      { cList: '../assets/images/convenient.svg' }
    ],
    csListDesc: [
      { csListDescItem: 'Send money at your convenient time & location' },
      { csListDescItem: 'Use your Smartphone, Laptop or Desktop' },
      { csListDescItem: 'Trusted by international banks & payment insts.' }
    ]
  },
  {
    countryId: null,
    csList: 'Cheaper',
    csListImg: [
      { cList: '../assets/images/cheaper.svg' }
    ],
    csListDesc: [
      { csListDescItem: 'Lowest service fee in the market' },
      { csListDescItem: 'Best exchange rate is guaranteed' },
      { csListDescItem: 'Faster transfer to bank accounts' }
    ]
  }
];

export const AppMockupData = {
  payingCountryHTMLContentList: [
    {
      countryId: 211,
      languageId: 1,
      countryName: 'Somalia',
      countryCode: 'SO',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_somalia.jpg'},
        { cBanner: 'assets/images/country/optimize/country_somalia_1.jpg'},
        { cBanner: 'assets/images/country/optimize/country_somalia_3.jpg'},
        { cBanner: 'assets/images/country/optimize/country_somalia_4.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/somalia_osp1.jpg' },
        { cList: '../assets/img/service/sahal.jpg' },
        { cList: '../assets/img/service/salaam.jpg' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant to Mobile Wallet Account',
          csListImg: [
            { cList: '../assets/img/service/sahal.jpg' },
            { cList: '../assets/img/service/evc.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'SAHAL' },
            { csListDescItem: 'EVC PLUS' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Bank Account deposit',
          csListImg: [
            { cList: '../assets/img/service/salaam.jpg' },
          ],
          csListDesc: [
            { csListDescItem: 'Salaam Bank' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Cash Pick up',
          csListImg: [
            // { cList: '../assets/img/service/taaj.jpg' },
            { cList: '../assets/img/service/salaam.jpg' },
          ],
          csListDesc: [
            // { csListDescItem: 'Taaj Services' },
            { csListDescItem: 'Salaam Bank' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 242,
      languageId: 1,
      countryName: 'Uganda',
      countryCode: 'UG',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_uganda.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/uganda_osp1.jpg' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Cash Pick up',
          csListImg: [
            { cList: '../assets/img/service/sundus-uganda.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Sundus Exchange Uganda' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/MTN.png' }
          ],
          csListDesc: [
            { csListDescItem: 'MTN mobile money' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/airtel.png' },
          ],
          csListDesc: [
            { csListDescItem: 'Airtel Mobile Money' },
          ]
        },
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/africell.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Africell Uganda' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 129,
      languageId: 1,
      countryName: 'Kenya',
      countryCode: 'KE',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_kenya.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/mpesa.jpg' },
        { cList: '../assets/img/service/kenya_osp2.jpg' },
        { cList: '../assets/img/service/kenya_osp3.jpg' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant to Mobile Wallet Account',
          csListImg: [
            { cList: '../assets/img/service/mpesa.jpg' },
          ],
          csListDesc: [
            { csListDescItem: 'Mpesa' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Cash Pick up',
          csListImg: [
            // { cList: '../assets/img/service/taaj.jpg' },
            { cList: '../assets/img/service/gmt.jpg' },
          ],
          csListDesc: [
            // { csListDescItem: 'Taaj Services' },
            { csListDescItem: 'Global Money Transfer' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 244,
      languageId: 1,
      countryName: 'U.A.E',
      countryCode: 'AE',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_uae.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/uae_osp1.jpg' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Cash Pick up',
          csListImg: [
            { cList: '../assets/img/service/sundus.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Sundus Exchange' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 77,
      languageId: 1,
      countryName: 'Ethiopia',
      countryCode: 'ET',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_ethiopia.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/ethiopia_osp1.jpg' },
        { cList: '../assets/img/service/ethiopia_osp2.jpg' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Bank Account deposit',
          csListImg: [
            { cList: '../assets/img/service/wagegan.jpg' },
            { cList: '../assets/img/service/oromia.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Wegegen Bank' },
            { csListDescItem: 'Bank of Oromia' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Cash Pick up',
          csListImg: [
            { cList: '../assets/img/service/wagegan.jpg' },
            { cList: '../assets/img/service/oromia.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Wegegen Bank' },
            { csListDescItem: 'Bank of Orimia' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/ebirr.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Ebirr' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 64,
      languageId: 1,
      countryName: 'Djibouti',
      countryCode: 'DJ',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_djibouti.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 39,
      languageId: 1,
      countryName: 'Canada',
      countryCode: 'CA',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_canada.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 13,
      languageId: 1,
      countryName: 'Australia',
      countryCode: 'AU',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_australia.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 81,
      languageId: 1,
      countryName: 'Finland',
      countryCode: 'FI',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_finland.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 182,
      languageId: 1,
      countryName: 'Norway',
      countryCode: 'NO',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_norway.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 195,
      languageId: 1,
      countryName: 'Qatar',
      countryCode: 'QA',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_qatar.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 223,
      languageId: 1,
      countryName: 'Sweden',
      countryCode: 'SE',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_sweden.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 224,
      languageId: 1,
      countryName: 'Switzerland',
      countryCode: 'CH',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_switzerland.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 245,
      languageId: 1,
      countryName: 'United Kingdom',
      countryCode: 'GB',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_uk.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 246,
      languageId: 1,
      countryName: 'United States of America',
      countryCode: 'US',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_usa.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    },
    {
      countryId: 261,
      languageId: 1,
      countryName: 'South Sudan',
      countryCode: 'SS',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_ss.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/uae_osp1.jpg' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Cash Pick up',
          csListImg: [
            { cList: '../assets/img/service/sundus.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Sundus Exchange' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 9999,
      languageId: 1,
      countryName: 'Somaliland',
      countryCode: 'SOM',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/country_somalia_2.jpg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/somalia_osp2.jpg' },
        { cList: '../assets/img/service/salaam.jpg' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant to Mobile Wallet Account',
          csListImg: [
            { cList: '../assets/img/service/zaad.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'ZAAD' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Bank Account deposit',
          csListImg: [
            { cList: '../assets/img/service/salaam.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Salaam Bank' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Cash Pick up',
          csListImg: [
            // { cList: '../assets/img/service/taaj.jpg' },
            { cList: '../assets/img/service/salaam.jpg' }
          ],
          csListDesc: [
            // { csListDescItem: 'Taaj Services' },
            { csListDescItem: 'Salaam Bank' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 25,
      languageId: 1,
      countryName: 'Benin',
      countryCode: 'BJ',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/MTN.png' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/MTN.png' }
          ],
          csListDesc: [
            { csListDescItem: 'MTN mobile money' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 38,
      languageId: 1,
      countryName: 'Cameroon',
      countryCode: 'CM',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/MTN.png' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/MTN.png' }
          ],
          csListDesc: [
            { csListDescItem: 'MTN mobile money' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 51,
      languageId: 1,
      countryName: 'Congo',
      countryCode: 'CG',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/MTN.png' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/MTN.png' }
          ],
          csListDesc: [
            { csListDescItem: 'MTN mobile money' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 61,
      languageId: 1,
      countryName: 'Côte d’Ivoire',
      countryCode: 'CI',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/MTN.png' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/MTN.png' }
          ],
          csListDesc: [
            { csListDescItem: 'MTN mobile money' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 90,
      languageId: 1,
      countryName: 'Ghana',
      countryCode: 'GH',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/MTN.png' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/MTN.png' }
          ],
          csListDesc: [
            { csListDescItem: 'MTN mobile money' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/airteltigo.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Airtel Tigo' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/vodafone.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Vodafone Ghana' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 198,
      languageId: 1,
      countryName: 'Rwanda',
      countryCode: 'RW',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/MTN.png' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/MTN.png' }
          ],
          csListDesc: [
            { csListDescItem: 'MTN mobile money' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 258,
      languageId: 1,
      countryName: 'Zambia',
      countryCode: 'ZM',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/MTN.png' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/MTN.png' }
          ],
          csListDesc: [
            { csListDescItem: 'MTN mobile money' }
          ]
        },
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/airtel.png' }
          ],
          csListDesc: [
            { csListDescItem: 'Airtel Mobile Money' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 146,
      languageId: 1,
      countryName: 'Madagascar',
      countryCode: 'MG',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/orange.jpg' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/orange.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Orange Mobile Money' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 147,
      languageId: 1,
      countryName: 'Malawi',
      countryCode: 'MW',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/airtel.png' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/airtel.png' }
          ],
          csListDesc: [
            { csListDescItem: 'Airtel Mobile Money' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 177,
      languageId: 1,
      countryName: 'Niger',
      countryCode: 'NE',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/airtel.png' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/airtel.png' }
          ],
          csListDesc: [
            { csListDescItem: 'Airtel Mobile Money' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: 178,
      languageId: 1,
      countryName: 'Nigeria',
      countryCode: 'NG',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [
        { cList: '../assets/img/service/paga.jpg' }
      ],
      howToSend: [],
      countryServices: [
        {
          countryId: null,
          csList: 'Instant Mobile Money Transfer',
          csListImg: [
            { cList: '../assets/img/service/paga.jpg' }
          ],
          csListDesc: [
            { csListDescItem: 'Paga Nigeria' }
          ]
        }, weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]
      ]
    },
    {
      countryId: null,
      languageId: 1,
      countryName: '',
      countryCode: '',
      imgUrl: [
        { cBanner: 'assets/images/country/optimize/squares.svg'}
      ],
      stateFrom: '',
      stateTo: '',
      bannerTitle: '',
      listHeading: '',
      listSubHeading: '',
      coveredList: [],
      howToSend: [],
      countryServices: [weAreSimpleHTMLContentList[0],weAreSimpleHTMLContentList[1],weAreSimpleHTMLContentList[2],weAreSimpleHTMLContentList[3],weAreSimpleHTMLContentList[4],weAreSimpleHTMLContentList[5]]
    }
  ]
};

import { Currency } from '../models/currency';

export class Calculation {
  public sendCurrency: Currency;
  public sendAmount: number;
  public sendAmountUSD: number;
  public feeCurrency: Currency;
  public feeAmount: number;
  public feeAmountUSD: number;
  public payCurrency: Currency;
  public payAmount: number;
  public payAmountUSD: number;
  public totalSendAmount: number;
  public exchangeRate: number;
  public zeroFeeRemitConfigs: ZeroFeeRemitConfig;
  public direction: string;
  public oldServiceFeeLocalCurrency: number;
  public promotionDiscount: number;
  public ormPromotionConfigId: number;
}

export class ZeroFeeRemitConfig {
  public availableZeroCommRemits: number;
  public eligiblePayingCountries: number[];
}

export class RemittanceType {
  public remittanceTypeId: number;
  public remittanceType: string;
  public remittanceSubTypes: any;
  constructor(remittanceTypeId: number, remittanceType: string, remittanceSubTypes: any) {
    this.remittanceTypeId = remittanceTypeId;
    this.remittanceType = remittanceType;
    this.remittanceSubTypes = remittanceSubTypes;
  }
}

export class RemittanceSubType {
  public remittanceSubTypeId: number;
  public remittanceSubType: string;
  minAmount: any;
  maxAmount: any;
  public remittanceTypeId: number;
  public ormDefaultPPartnerBranchId: number;
  public isVerificationRequired: string;
  public additionalRemitSubTypeLabels: any;
  constructor(remittanceSubTypeId: number, remittanceSubType: string, minAmount: any, maxAmount:any , remittanceTypeId: any, isVerificationRequired: string, additionalRemitSubTypeLabels: any) {
    this.remittanceSubTypeId = remittanceSubTypeId;
    this.remittanceSubType = remittanceSubType;
    this.minAmount = minAmount;
    this.maxAmount = maxAmount;
    this.remittanceTypeId = remittanceTypeId;
    this.isVerificationRequired = isVerificationRequired
    this.additionalRemitSubTypeLabels = additionalRemitSubTypeLabels;
  }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SendingCountry } from '../../models/sendingcountry';
import { AppDataService } from '../../shared/app-data.service';

@Component({
  selector: 'app-homefooter',
  templateUrl: './homefooter.component.html',
  styleUrls: ['./homefooter.component.css']
})
export class HomefooterComponent implements OnInit {
  currentDate: Date = new Date();
  currentYear: number = this.currentDate.getFullYear();
  selectedSenderCountrySubscription: Subscription;
  selectedSendingCountry: SendingCountry;

  constructor( private appDataService: AppDataService ) { }

  ngOnInit() {
    this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
      }
    );
  } 

  ngOnDestroy() {
   if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
  }
}

import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-showerrors',
  templateUrl: './showerrors.component.html'
})
export class ShowerrorsComponent {

  private static readonly errorMessages = {
    'required': () => 'This field is required',
    'minlength': (params) => 'The min number of characters is ' + params.requiredLength,
    'maxlength': (params) => 'The max allowed number of characters is ' + params.requiredLength,
    'pattern': () => 'Invalid characters',
    'uniqueEmail': (params) => params.message,
    'notEquivalentPassword': () => 'Password and Confirm Password did not match',
    'notEquivalentAccountNo': () => 'Account No and re-entered Account No did not match',
    'email': () => 'Invalid email',
    'dateOfBirth': (params) => params.message,
    'expiryGreaterThanIssue' : () => 'Expiry Date should greater than issue date',
    'invalidMobileLength' : () => 'Mobile no should have min of 10 digits and max of 13 digits',
    'duplicateDocTypes': () => 'Primary document and secondary document cannot be same.',
    'invalidPwdPattern' : (params) => params.message
  };

  @Input()
  private control: AbstractControlDirective | AbstractControl;

  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  private getMessage(type: string, params: any) {
    return ShowerrorsComponent.errorMessages[type](params);
  }

}

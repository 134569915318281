import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RemittanceComponent } from './remittance.component';
import { PaymentmethodComponent } from './components/paymentmethod/paymentmethod.component';
import { AuthGuard } from '../../shared/auth.gaurd';
import { ReprinttransferreceiptComponent } from './components/reprinttransferreceipt/reprinttransferreceipt.component';
import { PaymentmethodsResolver } from './shared/paymentmethods.resolver';
import { SenderAddInfoViewResolver } from './shared/senderadditionalview.resolver';
import { SenderverificationComponent } from './components/senderverification/senderverification.component';
import { CustomerResolver } from '../customers/shared/customer.resolver';
import { SendingCountryResolver } from '../../shared/sendingcountry-resolver.service';
import { AddRecipientViewResolver } from './shared/addrecipientview.resolver';
import { PagenotfoundComponent } from '../../components/pagenotfound/pagenotfound.component';
import { TransferreceiptComponent } from './components/transferreceipt/transferreceipt.component';
import {PaymentfailedComponent} from './components/paymentfailed/paymentfailed.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import {CompletepaymentModule} from "./components/completepayment/completepayment.module";


const remittanceRoutes: Routes = [
  {
    path: 'remittance',
    component: RemittanceComponent,
    children: [
      {
        path: 'addrecipient',
        loadChildren: () => import('./components/addrecipient/addrecipient.module').then(m => m.AddrecipientModule),
        resolve: { addRecipientView:  AddRecipientViewResolver, senderCustomer: CustomerResolver},
        canActivate: [AuthGuard]
      },
      {
        path: 'paymentmethod',
        loadChildren: () => import('./components/paymentmethod/paymentmethod.module').then(m => m.PaymentmethodModule),
        resolve: { paymentMethodsView:  PaymentmethodsResolver, senderCustomer: CustomerResolver },
        canActivate: [AuthGuard]
      },
      {
        path: 'senderadditionaldetails',
        loadChildren: () => import('./components/senderadditionaldetails/senderadditionaldetails.module').then(m => m.SenderadditionaldetailsModule),
        resolve: { senderAddInfoView:  SenderAddInfoViewResolver, senderCustomer: CustomerResolver},
        canActivate: [AuthGuard]
      },
      {
        path: 'recipientlist',
        loadChildren: () => import('./components/recipientlist/recipientlist.module').then(m => m.RecipientlistModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'transactionhistory',
        loadChildren: () => import('./components/transactionhistory/transactionhistory.module').then(m => m.TransactionhistoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reprinttransferreceipt',
        component: ReprinttransferreceiptComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'paymentresult',
        children: [
          {
            path: '**',
            loadChildren: () => import('./components/paymentresult/paymentresult.module').then(m => m.PaymentresultModule),
            resolve: {senderCustomer: CustomerResolver},
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'completepayment',
        children: [
          {
            path: '**',
            loadChildren: () => import('./components/completepayment/completepayment.module').then(m => m.CompletepaymentModule),
            resolve: {senderCustomer: CustomerResolver},
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'paymentfailed',
        loadChildren: () => import('./components/paymentfailed/paymentfailed.module').then(m => m.PaymentfailedModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'senderverification',
        loadChildren: () => import('./components/senderverification/senderverification.module').then(m => m.SenderverificationModule),
        resolve: { senderAddInfoView:  SenderAddInfoViewResolver, senderCustomer: CustomerResolver},
        canActivate: [AuthGuard]
      },
      {
        path: 'senderverified',
        loadChildren: () => import('./components/senderverified/senderverified.module').then(m => m.SenderverifiedModule),
        resolve: {senderCustomer: CustomerResolver},
        canActivate: [AuthGuard]
      },
      {
        path: 'senderverificationinprogress',
        loadChildren: () => import('./components/senderverificationinprogress/senderverificationinprogress.module').then(m => m.SenderverificationinprogressModule),
        resolve: {senderCustomer: CustomerResolver},
        canActivate: [AuthGuard]
      },
      {
        path: 'sendernotverified',
        loadChildren: () => import('./components/sendernotverified/sendernotverified.module').then(m => m.SendernotverifiedModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mylimits',
        loadChildren: () => import('./components/mylimits/mylimits.module').then(m => m.MylimitsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'transferreceipt',
        component: TransferreceiptComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AuthGuard]
      },
      { path: '**',
        component: PagenotfoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(remittanceRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RemittanceRoutingModule { }

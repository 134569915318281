import { Country } from './country';
import { Currency } from './currency';
import { RemittanceType } from './remittancetype';

export class PayingCountry {
  public country: Country;
  public currencies: Currency[];
  public remittanceTypes: RemittanceType[];
  public config: any;
  constructor(country: Country, currencies: Currency[], remittanceTypes: RemittanceType[], config: any) {
    this.country = country;
    this.currencies = currencies;
    this.remittanceTypes = remittanceTypes;
    this.config = config;
  }
  public static fromJsonList(list): PayingCountry[] {
    return list.map(PayingCountry.fromJson);
  }
  public static fromJson({countryId, languageId, countryName, countryCode, countryCallingCode, flagUrl, mobileMinLength, mobileMaxLength,
                           currencies, serviceTypes, config}): PayingCountry { // do data manipulation after getting data from api
    return new PayingCountry(new Country(countryId, countryName, countryCode, countryCallingCode, flagUrl, mobileMinLength, mobileMaxLength), currencies,
      serviceTypes, config);
  }
  public static toJson(toCountry: PayingCountry): PayingCountry { // do data manipulation before sending data to api
    return toCountry;
  }
}

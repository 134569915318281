<main>
  <ng-template [ngIf]="!isCheckoutPage">
  <app-navmenu></app-navmenu>
  </ng-template>
    <div *ngIf="isHomePage || isHomeGeneralPage" class="container-fluid covid-class">
        <p class="mb-0 py-2 text-white text-center">
            <a aria-label="Read more about COVID-19" role="button" href="/news/update-on-covid-19" routerLink="/news/update-on-covid-19">
                <i class="fa fa-info-circle mr-2" aria-hidden="true"></i>
                <strong>Covid-19 UPDATE:</strong> Taaj is here 24/7 with you to support your loved ones. Please use,
                if possible, Mobile Money, Airtime and account transfers instead of cash pickup. </a>
            <a style="color: #0ae3ff;" rel="nofollow" aria-label="Read more about COVID-19" role="button"
                routerLink="/news/update-on-covid-19"> Learn more</a>
        </p>
    </div>
    <ngx-loading [show]="InitialshowProgress" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <app-homeclient *ngIf="isHomePage"></app-homeclient>
    <app-homeclientgeneral *ngIf="isHomeGeneralPage"></app-homeclientgeneral>
    <ng-template [ngIf]="currentUser && !isCheckoutPage">
        <app-pagefooter></app-pagefooter>
    </ng-template>
    <ng-template [ngIf]="!currentUser && !isUnderMaintenance">
        <app-homefooter></app-homefooter>
    </ng-template>
    <app-selectcountry *ngIf="showSelectCountry"></app-selectcountry>
    <cookie-law name="TaajCookieLaw" learnMore="privacypolicy" target="_self"></cookie-law>

</main>

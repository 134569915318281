import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of, Subscription } from 'rxjs';
import {ActivatedRoute, Data, Router} from '@angular/router';
import { CountryPageHTMLContent } from '../../models/countrypagehtmlcontent';
import { SendingCountry } from '../../models/sendingcountry';
import { PayingCountry } from '../../models/payingcountry';
import { RemittanceSubType, RemittanceType } from '../../models/remittancetype';
import { Currency } from '../../models/currency';
import { Calculation } from '../../models/calculation';
import { AppDataService } from '../../shared/app-data.service';
import { CalculationService } from '../../shared/calculation.service';
import { NgStyle } from '@angular/common';
import { SuccessComponent } from '../success/success.component';
import { ErrorComponent } from '../error/error.component';
import { Observable } from 'rxjs';
import { Utilities } from '../../shared/utilities';
import { RemittanceService } from '../../modules/remittance/shared/remittance.service';
import { ApiResponse } from '../../models/apiresponse';
import { RegExes } from '../../shared/regexes';
import { CustomValidators } from '../../shared/CustomValidators';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import {User} from '../../models/user';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {environment} from "../../../environments/environment";

declare var $: any;

@Component({
  selector: 'app-calci',
  templateUrl: './calci.component.html',
  styleUrls: ['./calci.component.css']
})
export class CalciComponent implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup;
  selectedSendingCountry: SendingCountry;
  selectedSenderCountrySubscription: Subscription;
  payingCountriesList: PayingCountry[];
  selectedPayingCountry: PayingCountry;
  selectedPayingCountrySubscription: Subscription;
  remittanceTypeList: RemittanceType[];
  selectedRemittanceType: RemittanceType;
  selectedRemittanceTypeSubscription: Subscription;
  selectedRemittanceSubType: RemittanceSubType;
  selectedRemittanceSubTypeSubscription: Subscription;
  sendingCurrencyList: Currency[];
  selectedSendingCurrency: Currency;
  selectedSendingCurrencySubscription: Subscription;
  payingCurrencyList: Currency[];
  selectedPayingCurrency: Currency;
  selectedPayingCurrencySubscription: Subscription;
  calculatorPageHTMLContent: CountryPageHTMLContent;
  enableCalculationButton = false;
  calculation: Calculation;
  youSendAmount: number;
  youSendAmountSubscription: Subscription;
  theyReceiveAmount: number;
  theyReceiveAmountSubscription: Subscription;
  disableSubmitButton = false;
  showProgress: boolean;
  randomImage: { cBanner: '' };
  ImageArray = [];
  imgPath = '';
  pCountryId: any;
  showMmtAccount = false;
  showVerifyProgress: boolean;
  isVerified: boolean;
  isNotVerified: boolean;
  destinationCurrency: any;
  productListAmount: any;
  showProductList: boolean;
  showAccountFields = true;
  disableVerifyButton = true;
  mmtAccNoSubscription: Subscription;
  name = 'Taaj';
  countryTitle: any;
  currentUser: User;
  showEbirrbankSubtypes: boolean = false;
  selectedBank: [];
  @ViewChild('txtSendingAmount') txtSendingAmount: ElementRef;
  @ViewChild('txtPayingAmount') txtPayingAmount: ElementRef;

  isBrowser: boolean;
  calculateFromSendingAmountTxt = false;
  calculateFromReceivingAmountTxt = false;
  finalPartnersList: any;
  exchangeRate: number;
  serviceNotification: any;
  showExchangeRate = false;
  showSubTypeField = false;
  isDisabled = true;
  remittanceSubTypesList: any = [];
  urlCountryName: any;
  latitude: any;
  longitude: any;
  additionalRemitSubTypeLabels: any = [];
  bankorWalletOption: string = '';
  showPromoCode: boolean = false;
  promoCode: string = '';

  constructor(private fb: FormBuilder, private appDataService: AppDataService, private calculationService: CalculationService,
    private route: ActivatedRoute, private router: Router, private remittanceService: RemittanceService,
    public dialog: MatDialog, private elementRef: ElementRef, @Inject(PLATFORM_ID) platformId: Object,
    private titleService: Title,
    private meta: Meta) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  ngOnInit() {
    this.currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
    this.urlCountryName = decodeURIComponent(this.router.url.split('/')[2]);
    this.urlCountryName = this.capitalizeCountryName(this.urlCountryName);
    this.appDataService.setMatchedBlacklistEntity({
          senderMobileBlackListed: false, senderNameBlackListed: false, senderNameMobileBlackListed: false, senderNameCountryBlackListed: false,
          receiverMobileBlackListed: false, receiverNameBlackListed: false, receiverNameMobileBlackListed: false, receiverNameCountryBlackListed: false});

    this.selectedPayingCountry = this.appDataService.getSelectedPayingCountry();
    this.form = this.fb.group({
      ddlPayingCountry: [null, Validators.required],
      ddlRemittanceType: [null, Validators.required],
      ddlRemittanceSubType: [null],
      ddlBankOrWalletSubType: [null,Validators.required],
      ddlSendReceiveType: [null, Validators.required],
      ddlPayingCurrency: [null, Validators.required],
      ddlSendingCurrency: [null, Validators.required],
      txtSendingAmount: [null, Validators.required],
      txtPayingAmount: [null, Validators.required],
      mmtAccountNo: [null, [Validators.required, Validators.pattern(RegExes.mobileNo),
      CustomValidators.validateMobileLength('', this.selectedPayingCountry ? this.selectedPayingCountry.country.mobileMinLength : null, this.selectedPayingCountry ? this.selectedPayingCountry.country.mobileMaxLength : null)]],
      ddlRechargeType: [null, Validators.required],
      promoCode: [null]
    });
    this.youSendAmountSubscription = this.form.controls['txtSendingAmount'].valueChanges
      .pipe(debounceTime(1000),distinctUntilChanged(),switchMap(num => of(num)))
      .subscribe(
        (value: string) => {
          if (value && +value >= 1) {
            this.youSendAmount = +value;
            this.theyReceiveAmount = null;
            this.calculateFromSendingAmountTxt = true;
            this.form.controls['promoCode'].reset();
            this.calculateFromSendingAmount();
          } else {
            this.calculation = null;
            this.theyReceiveAmount = null;
            this.enableCalculationButton = false;
          }
        }
      );
    this.theyReceiveAmountSubscription = this.form.controls['txtPayingAmount'].valueChanges
    .pipe(debounceTime(1000),distinctUntilChanged(),switchMap(num => of(num)))
      .subscribe(
        (value: string) => {
          if (value && +value >= 1) {
            this.theyReceiveAmount = +value;
            this.youSendAmount = null;
            this.calculateFromReceivingAmountTxt = true;
            this.form.controls['promoCode'].reset();
            this.calculateFromReceivingAmount();
          } else {
            this.youSendAmount = null;
            this.calculation = null;
            this.enableCalculationButton = false;
          }
        }
      );
    this.route.data
      .subscribe(
        (data: Data) => {
          this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
          this.payingCountriesList = this.appDataService.getPayingCountriesList() ?
            this.appDataService.getPayingCountriesList() : this.selectedSendingCountry ?
              this.selectedSendingCountry.payingCountries : [];

          if (this.urlCountryName !== this.selectedPayingCountry.country.countryName) {
            const payingCountryFromUrl = this.payingCountriesList.find(list => list.country.countryName === this.urlCountryName);
            if (payingCountryFromUrl) {
              this.appDataService.getPayingCountryRemittanceTypes_Api(this.selectedSendingCountry.country.countryId, payingCountryFromUrl.country.countryId)
                .then(pCountry => {
                  this.appDataService.setSelectedPayingCountry(pCountry);
                  this.router.navigate(['/send-money/' + pCountry.country.countryName.toLowerCase().replace(/[ ,’]+/g, '-') + '']);
                  this.titleService.setTitle('Send money to ' + pCountry.country.countryName + ' | Online Money Transfer | Taaj');
                  this.meta.updateTag({ name: 'description', content: 'It\'s quick & easy to Send Money To ' + pCountry.country.countryName + ' with Taaj. Use our secure & low-cost online money transfer service for sending money overseas.'});
                  this.imgPath = this.selectedPayingCountry ? this.selectedPayingCountry.country.flagUrl : '';
                  this.appDataService.setSelectedPayingCountry(pCountry);
                  this.ImageArray = this.calculatorPageHTMLContent ? this.calculatorPageHTMLContent.imgUrl : '';
                  this.randomImage = this.ImageArray[Math.floor(Math.random() * this.ImageArray.length)];
                  setTimeout(() => {
                    this.finalPartnersList = this.calculatorPageHTMLContent.countryServices;
                    this.filterPartnersList();
                  }, 100);
                })
                .catch(err => {
                  this.dialog.open(ErrorComponent, {
                    data: {
                      message: err.toString()
                    }
                  });
                });
            }
          }

          this.selectedPayingCountry = this.appDataService.getSelectedPayingCountry();
          this.remittanceTypeList = this.appDataService.getRemittanceTypeList() ?
            this.appDataService.getRemittanceTypeList() : this.selectedPayingCountry ?
              this.selectedPayingCountry.remittanceTypes : [];
          this.selectedRemittanceType = this.appDataService.getSelectedRemittanceType();
          this.selectedRemittanceSubType = this.appDataService.getSelectedRemittanceSubType();
          if (this.selectedPayingCountry?.country?.countryId == 77 || this.selectedPayingCountry?.country?.countryId == 129) {
            this.selectedRemittanceType.remittanceSubTypes.forEach(rSubType => {
            if (rSubType?.remittanceTypeId == 1 || rSubType?.remittanceTypeId == 4) {
              this.additionalRemitSubTypeLabels = this.selectedRemittanceSubType.additionalRemitSubTypeLabels &&
              this.selectedRemittanceSubType.additionalRemitSubTypeLabels.length ?
                this.selectedRemittanceSubType.additionalRemitSubTypeLabels.filter(addi => addi.remittanceSubTypeId == this.selectedRemittanceSubType.remittanceSubTypeId) : this.selectedRemittanceSubType.additionalRemitSubTypeLabels;
              this.showEbirrbankSubtypes = this.additionalRemitSubTypeLabels.length > 0;
              this.selectedBank = [];
              if (rSubType?.remittanceTypeId == 1) {
                this.bankorWalletOption = 'Select Wallet';
              } else if(rSubType?.remittanceTypeId == 4) {
                this.bankorWalletOption = 'Select Bank';
              }
            } else {
              this.showEbirrbankSubtypes = false;
            }
          });
          }else {
            this.showEbirrbankSubtypes = false;
          }

          this.sendingCurrencyList = this.appDataService.getSendingCurrencyList() ?
            this.appDataService.getSendingCurrencyList() : this.selectedSendingCountry ?
              this.selectedSendingCountry.currencies : [];
              this.countryTitle = this.selectedPayingCountry.country.countryName;
             // console.log(this.countryTitle);
          this.selectedSendingCurrency = this.appDataService.getSelectedSendingCurrency();
          this.payingCurrencyList = this.appDataService.getPayingCurrencyList();
          if (!this.payingCurrencyList && this.selectedPayingCountry) {
            this.payingCurrencyList = [];
            this.selectedPayingCountry.currencies.forEach(currency => {
              if (currency.currencyId !== 271
                && (currency.remittanceTypeId === this.selectedRemittanceType.remittanceTypeId || !currency.remittanceTypeId)
                && (currency.remittanceSubTypeId === (this.selectedRemittanceSubType ?
                  this.selectedRemittanceSubType.remittanceSubTypeId : null) || !currency.remittanceSubTypeId)) {
                this.payingCurrencyList.push(currency);
              }
            });
          }
          if ((!this.payingCurrencyList || this.payingCurrencyList.length === 0) && this.selectedPayingCountry) {
            this.payingCurrencyList = [];
            this.selectedPayingCountry.currencies.forEach(currency => {
              if (currency.currencyId === 271) {
                this.payingCurrencyList.push(currency);
              }
            });
          }
          this.selectedPayingCurrency = this.appDataService.getSelectedPayingCurrency();
          this.calculatorPageHTMLContent = this.calculationService.
            getPageHTMLContentByCountryId(this.selectedPayingCountry.country.countryId);
          this.finalPartnersList = this.calculatorPageHTMLContent.countryServices;
          this.filterPartnersList();
          const modifiedRemittanceSubTypes = [];
          this.remittanceTypeList.forEach(rtype => {
                if (rtype.remittanceSubTypes && rtype.remittanceSubTypes.length > 0) {
                  modifiedRemittanceSubTypes.push(rtype.remittanceSubTypes);
                }
              });
          this.remittanceSubTypesList = Array.prototype.concat.apply([], modifiedRemittanceSubTypes);
          this.isDisabled = this.remittanceSubTypesList.length > 1 ? true : false;
          this.showExchangeRate = this.remittanceSubTypesList.length > 1 ? false : true;
          this.isDisabled = (this.selectedPayingCountry.country.countryId == 77 || this.selectedPayingCountry.country.countryId == 129) && (
            this.selectedRemittanceSubType.remittanceSubTypeId == 13 || this.selectedRemittanceSubType.remittanceSubTypeId == 19 || this.selectedRemittanceSubType.remittanceSubTypeId == 21
          ) && this.additionalRemitSubTypeLabels.length > 0 && !this.selectedBank.length ? true : false;
        }
      );
     // console.log(this.countryTitle);
      this.titleService.setTitle('Send money to ' + this.countryTitle + ' | Online Money Transfer | Taaj');
    this.meta.updateTag({ name: 'description', content: 'It\'s quick & easy to Send Money To ' + this.countryTitle + ' with Taaj. Use our secure & low-cost online money transfer service for sending money overseas.'});
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
        this.payingCountriesList = this.appDataService.getPayingCountriesList();
        this.selectedPayingCountry = this.appDataService.getSelectedPayingCountry();
        this.remittanceTypeList = this.appDataService.getRemittanceTypeList();
        this.selectedRemittanceType = this.appDataService.getSelectedRemittanceType();
        this.selectedRemittanceSubType = this.appDataService.getSelectedRemittanceSubType();
        this.sendingCurrencyList = this.appDataService.getSendingCurrencyList();
        this.selectedSendingCurrency = this.appDataService.getSelectedSendingCurrency();
        this.payingCurrencyList = this.appDataService.getPayingCurrencyList();
        this.selectedPayingCurrency = this.appDataService.getSelectedPayingCurrency();
        this.calculatorPageHTMLContent = this.calculationService.getPageHTMLContentByCountryId(
          this.selectedPayingCountry.country.countryId);
        this.ImageArray = this.calculatorPageHTMLContent ? this.calculatorPageHTMLContent.imgUrl : '';
        this.randomImage = this.ImageArray[Math.floor(Math.random() * this.ImageArray.length)];
        if (this.selectedPayingCountry) {
          this.imgPath = this.selectedPayingCountry ? this.selectedPayingCountry.country.flagUrl : '';
        }
        if (this.youSendAmount >= 1) {
          this.calculateFromSendingAmount();
        } else {
          this.calculateFromReceivingAmount();
        }
        this.showAccountFields = true;
        this.showMmtAccount = false;
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
    this.selectedPayingCountrySubscription = this.appDataService.selectedPayingCountrySubject.subscribe(
      (value: PayingCountry) => {

        this.selectedPayingCountry = this.appDataService.getSelectedPayingCountry();
        this.remittanceTypeList = this.appDataService.getRemittanceTypeList();
         const modifiedRemittanceSubTypes = [];
          this.remittanceTypeList.forEach(rtype => {
                if (rtype.remittanceSubTypes && rtype.remittanceSubTypes.length > 0) {
                  modifiedRemittanceSubTypes.push(rtype.remittanceSubTypes);
                }
              });
        this.remittanceSubTypesList = Array.prototype.concat.apply([], modifiedRemittanceSubTypes);
        this.isDisabled = this.remittanceSubTypesList.length > 1 ? true : false;
        this.showExchangeRate = this.remittanceSubTypesList.length > 1 ? false : true;
        this.selectedRemittanceType = this.appDataService.getSelectedRemittanceType();
        this.selectedRemittanceSubType = this.appDataService.getSelectedRemittanceSubType();
        this.payingCurrencyList = this.appDataService.getPayingCurrencyList();
        this.selectedPayingCurrency = this.appDataService.getSelectedPayingCurrency();
        this.isDisabled = (this.selectedPayingCountry.country.countryId == 77 || this.selectedPayingCountry.country.countryId == 129) && (
          this.selectedRemittanceSubType.remittanceSubTypeId == 13 || this.selectedRemittanceSubType.remittanceSubTypeId == 19 || this.selectedRemittanceSubType.remittanceSubTypeId == 21
        ) && this.additionalRemitSubTypeLabels.length > 0 && !this.selectedBank.length ? true : false;
        this.calculatorPageHTMLContent = this.calculationService.
          getPageHTMLContentByCountryId(this.selectedPayingCountry.country.countryId);
        // this.calculateFromReceivingAmount();
        this.ImageArray = this.calculatorPageHTMLContent ? this.calculatorPageHTMLContent.imgUrl : '';
        this.randomImage = this.ImageArray[Math.floor(Math.random() * this.ImageArray.length)];
        if (this.selectedPayingCountry) {
          this.imgPath = this.selectedPayingCountry ? this.selectedPayingCountry.country.flagUrl : '';
        }
        this.form.patchValue({
          ddlPayingCountry: this.selectedPayingCountry ? this.selectedPayingCountry.country.countryId : '',
          ddlRemittanceType: this.selectedRemittanceType ? this.selectedRemittanceType.remittanceTypeId : '',
          ddlRemittanceSubType: this.remittanceSubTypesList.length === 1 ? this.selectedRemittanceSubType.remittanceSubTypeId : null,
        });

        this.exchangeRate = this.calculationService.getSendingToPayingExchangeRate();

        if (this.selectedRemittanceSubType && this.selectedRemittanceSubType.remittanceSubTypeId === 7) {
          this.showMmtAccount = true;
          this.showAccountFields = false;
        } else {
          this.showMmtAccount = false;
          this.showAccountFields = true;
        }
        setTimeout(() => {
          if (this.selectedPayingCountry.country.countryId === 211) {
          this.appDataService.getServiceNotifications(this.selectedSendingCountry.country.countryId, this.selectedPayingCountry.country.countryId,
            this.selectedRemittanceType.remittanceTypeId, this.selectedRemittanceSubType.remittanceSubTypeId)
            .then(resp => {
              if (resp.result) {
                this.serviceNotification = resp.result[0];
                if (this.serviceNotification.isEnable) {
                  if (this.isBrowser) {
                    $('.toast').toast('show');
                  }
                }
              }
            })
            .catch(function(err) {
              console.log(err);
            });
          }  else {
            if (this.isBrowser) {
              $('.toast').toast('hide');
            }
          }
        }, 500);
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
    this.selectedRemittanceTypeSubscription = this.appDataService.selectedRemittanceTypeSubject.subscribe(
      (value: RemittanceType) => {
        this.selectedRemittanceType = this.appDataService.getSelectedRemittanceType();
        this.selectedRemittanceSubType = this.appDataService.getSelectedRemittanceSubType();
        this.payingCurrencyList = this.appDataService.getPayingCurrencyList();
        this.selectedPayingCurrency = this.appDataService.getSelectedPayingCurrency();
        // this.calculateFromSendingAmount();
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
    this.selectedRemittanceSubTypeSubscription = this.appDataService.selectedRemittanceSubTypeSubject.subscribe(
      (value: RemittanceSubType) => {
        this.selectedRemittanceSubType = this.appDataService.getSelectedRemittanceSubType();
        this.payingCurrencyList = this.appDataService.getPayingCurrencyList();
        this.selectedPayingCurrency = this.appDataService.getSelectedPayingCurrency();
        // this.calculateFromSendingAmount();
        if (this.selectedRemittanceSubType && this.selectedRemittanceSubType.remittanceSubTypeId === 7) {
          this.showMmtAccount = true;
          this.showAccountFields = false;
        }

      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
    this.selectedSendingCurrencySubscription = this.appDataService.selectedSendingCurrencySubject.subscribe(
      (value: Currency) => {
        this.exchangeRate = this.calculationService.getSendingToPayingExchangeRate();
        this.selectedSendingCurrency = this.appDataService.getSelectedSendingCurrency();
        if (this.youSendAmount >= 1) {
          this.calculateFromSendingAmount();
        } else {
          this.calculateFromReceivingAmount();
        }
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
    this.selectedPayingCurrencySubscription = this.appDataService.selectedPayingCurrencySubject.subscribe(
      (value: Currency) => {
        this.exchangeRate = this.calculationService.getSendingToPayingExchangeRate();
        this.payingCurrencyList = this.appDataService.getPayingCurrencyList();
        this.selectedPayingCurrency = this.appDataService.getSelectedPayingCurrency();
        if (this.youSendAmount >= 1) {
          this.calculateFromSendingAmount();
        } else {
          this.calculateFromReceivingAmount();
        }
      },
      (err) => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      }
    );
    this.ImageArray = this.calculatorPageHTMLContent ? this.calculatorPageHTMLContent.imgUrl : '';
    this.randomImage = this.ImageArray[Math.floor(Math.random() * this.ImageArray.length)];
    if (this.selectedPayingCountry) {
      // this.imgPath = this.selectedPayingCountry ? this.selectedPayingCountry.country.flagUrl : '';
      this.imgPath = this.selectedPayingCountry ? 'assets/images/flagsTaaj/' + this.selectedPayingCountry.country.countryName + '.png' : '';
    }
    if (this.selectedRemittanceSubType && this.selectedRemittanceSubType.remittanceSubTypeId === 7) {
      this.showMmtAccount = true;
      this.showAccountFields = false;
    }
    this.mmtAccNoSubscription = this.form.controls['mmtAccountNo'].valueChanges
    .pipe(debounceTime(2000),switchMap(num => of(num)))
      .subscribe(
        (value: string) => {
          this.disableVerifyButton = true;
          if (value && this.form.controls['mmtAccountNo'].valid) {
            this.disableVerifyButton = false;
          }
        }
      );
    this.form.patchValue({
      ddlPayingCountry: this.selectedPayingCountry ? this.selectedPayingCountry.country.countryId : '',
      ddlRemittanceType: this.selectedRemittanceType ? this.selectedRemittanceType.remittanceTypeId : '',
      ddlRemittanceSubType: this.remittanceSubTypesList.length === 1 ? this.selectedRemittanceSubType.remittanceSubTypeId : null,
    });

    // Getting exchange rate
    this.exchangeRate = this.calculationService.getSendingToPayingExchangeRate();

    // Reinitializing bootstrap corousal on route change
    if (this.isBrowser) {
      $(document).ready(function () {
        $('.carousel').carousel();
      });
    }
    if (this.selectedPayingCountry.country.countryId === 211) {
    this.appDataService.getServiceNotifications(this.selectedSendingCountry.country.countryId, this.selectedPayingCountry.country.countryId,
      this.selectedRemittanceType.remittanceTypeId, this.selectedRemittanceSubType.remittanceSubTypeId)
      .then(resp => {
        if (resp.result) {
          this.serviceNotification = resp.result[0];
          if (this.serviceNotification.isEnable) {
            setTimeout(() => {
              if (this.isBrowser) {
                $('.toast').toast('show');
              }
            }, 500);
          }
        }
      })
      .catch(function(err) {
        console.log(err);
      });
    }

    if (this.currentUser && this.currentUser.isLocationRequired) {
      this.getUserLocation();
    }
  }

  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
    if (this.selectedPayingCountrySubscription) {
      this.selectedPayingCountrySubscription.unsubscribe();
    }
    if (this.selectedRemittanceTypeSubscription) {
      this.selectedRemittanceTypeSubscription.unsubscribe();
    }
    if (this.selectedRemittanceSubTypeSubscription) {
      this.selectedRemittanceSubTypeSubscription.unsubscribe();
    }
    if (this.youSendAmountSubscription) {
      this.youSendAmountSubscription.unsubscribe();
    }
    if (this.theyReceiveAmountSubscription) {
      this.theyReceiveAmountSubscription.unsubscribe();
    }
    if (this.selectedSendingCurrencySubscription) {
      this.selectedSendingCurrencySubscription.unsubscribe();
    }
    if (this.selectedPayingCurrencySubscription) {
      this.selectedPayingCurrencySubscription.unsubscribe();
    }
    if (this.mmtAccNoSubscription) {
      this.mmtAccNoSubscription.unsubscribe();
    }
  }

  onSelectPayingCountry(event): void {
    // Resetting all item once change
    this.form.controls['ddlRechargeType'].reset();
    this.form.controls['mmtAccountNo'].reset();
    this.form.controls['promoCode'].reset();
    this.showProductList = false;
    this.isNotVerified = false;
    this.isVerified = false;
    // this.showAccountFields = true;
    // this.showMmtAccount = false;
    this.calculation = null;
    this.theyReceiveAmount = null;
    this.youSendAmount = null;
    this.enableCalculationButton = false;
    this.showExchangeRate = false;
    this.showSubTypeField = false;
    this.isDisabled = true;
    // End
    this.pCountryId = this.payingCountriesList[event.target.selectedIndex].country.countryId;
    this.appDataService.getPayingCountryRemittanceTypes_Api(this.selectedSendingCountry.country.countryId, this.pCountryId)
      .then(pCountry => {
        this.finalPartnersList = [];
        this.appDataService.setSelectedPayingCountry(pCountry);

        this.router.navigate(['/send-money/' + pCountry.country.countryName.toLowerCase().replace(/[ ,’]+/g, '-') + '']);

        this.titleService.setTitle('Send money to ' + pCountry.country.countryName + ' | Online Money Transfer | Taaj');
        this.meta.updateTag({ name: 'description', content: 'It\'s quick & easy to Send Money To ' + pCountry.country.countryName + ' with Taaj. Use our secure & low-cost online money transfer service for sending money overseas.'});
        // this.imgPath = this.selectedPayingCountry ? this.selectedPayingCountry.country.flagUrl : '';
        this.imgPath = this.selectedPayingCountry ? 'assets/images/flagsTaaj/' + this.selectedPayingCountry.country.countryName + '.png' : '';
        this.appDataService.setSelectedPayingCountry(pCountry);
        this.ImageArray = this.calculatorPageHTMLContent ? this.calculatorPageHTMLContent.imgUrl : '';
        this.randomImage = this.ImageArray[Math.floor(Math.random() * this.ImageArray.length)];

        if (!(pCountry?.country?.countryId == 77 || pCountry?.country?.countryId == 129)) {
            this.showEbirrbankSubtypes = false;
        }

        setTimeout(() => {
          this.finalPartnersList = this.calculatorPageHTMLContent.countryServices;
          this.filterPartnersList();
        }, 100);
      })
      .catch(err => {
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.toString()
          }
        });
      });
  }

  onSelectRemittanceType(event): void {
    // Resetting all item once change
    this.form.controls['ddlRechargeType'].reset();
    this.form.controls['mmtAccountNo'].reset()
    this.form.controls['promoCode'].reset();
    this.appDataService.setAirtimeNumber(null);
    this.showProductList = false;
    this.isNotVerified = false;
    this.isVerified = false;
    this.showAccountFields = true;
    this.showMmtAccount = false;
    this.calculation = null;
    this.theyReceiveAmount = null;
    this.youSendAmount = null;
    this.enableCalculationButton = false;

    // End
      this.appDataService.setSelectedRemittanceType(this.remittanceTypeList[event.target.selectedIndex]);
  }

  onSelectRemittanceSubType(event): void {
    this.appDataService.delete_app_data_key('selectedBankType','');
    // Resetting all item once change
    this.form.controls['ddlRechargeType'].reset();
    this.form.controls['mmtAccountNo'].reset();
    this.form.controls['txtSendingAmount'].reset();
    this.form.controls['txtPayingAmount'].reset();
    this.form.controls['promoCode'].reset();
    this.showProductList = false;
    this.isNotVerified = false;
    this.isVerified = false;
    this.showAccountFields = true;
    this.showMmtAccount = false;
    this.calculation = null;
    this.theyReceiveAmount = null;
    this.youSendAmount = null;
    this.enableCalculationButton = false;
    this.showExchangeRate = false;
    this.showSubTypeField = false;
    this.isDisabled = true;
    // End
    if (event.target.selectedIndex > 0) {
    const rSubType = this.remittanceSubTypesList[event.target.selectedIndex - 1];
    this.appDataService.setSelectedRemittanceType(this.remittanceTypeList.find(list => list.remittanceTypeId === rSubType.remittanceTypeId));
    this.appDataService.setSelectedRemittanceSubType(rSubType);

    if (this.selectedPayingCountry?.country?.countryId == 77 || this.selectedPayingCountry?.country?.countryId == 129) {
      if (rSubType?.remittanceTypeId == 1 || rSubType?.remittanceTypeId == 4) {
        this.additionalRemitSubTypeLabels = this.selectedRemittanceSubType.additionalRemitSubTypeLabels &&
          this.selectedRemittanceSubType.additionalRemitSubTypeLabels.length ?
          this.selectedRemittanceSubType.additionalRemitSubTypeLabels.filter(addi => addi.remittanceSubTypeId == this.selectedRemittanceSubType.remittanceSubTypeId) : this.selectedRemittanceSubType.additionalRemitSubTypeLabels;
        this.showEbirrbankSubtypes = this.additionalRemitSubTypeLabels.length > 0;
        this.selectedBank = [];
        if (rSubType?.remittanceTypeId == 1) {
          this.bankorWalletOption = 'Select Wallet';
        } else if(rSubType?.remittanceTypeId == 4) {
          this.bankorWalletOption = 'Select Bank';
        }
      } else {
        this.showEbirrbankSubtypes = false;
      }
    } else {
      this.showEbirrbankSubtypes = false;
    }
    this.showExchangeRate = true;
    this.showSubTypeField = true;
    // this.isDisabled = false;
      this.isDisabled = (this.selectedPayingCountry.country.countryId == 77 || this.selectedPayingCountry.country.countryId == 12) && (
        this.selectedRemittanceSubType.remittanceSubTypeId == 13 || this.selectedRemittanceSubType.remittanceSubTypeId == 19 || this.selectedRemittanceSubType.remittanceSubTypeId == 21
      ) && this.additionalRemitSubTypeLabels.length > 0 && !this.selectedBank.length ? true : false;
  }
  }

  onSelectBank(event): void {
    if (event.target.selectedIndex > 0) {
       this.selectedBank  = this.additionalRemitSubTypeLabels[event.target.selectedIndex - 1];
      this.appDataService.setSelectedBankType(this.selectedBank);
    } else{
      this.appDataService.delete_app_data_key('selectedBankType','');
    }
    this.isDisabled = (this.selectedPayingCountry.country.countryId == 77 || this.selectedPayingCountry.country.countryId == 129) && (
      this.selectedRemittanceSubType.remittanceSubTypeId == 13 || this.selectedRemittanceSubType.remittanceSubTypeId == 19 || this.selectedRemittanceSubType.remittanceSubTypeId == 21
    ) && this.additionalRemitSubTypeLabels.length > 0 && this.selectedBank && event.target.selectedIndex == 0  ? true : false;
  }
  onSelectSendingCurrency(event): void {
    this.appDataService.setSelectedSendingCurrency(this.sendingCurrencyList[event.target.selectedIndex]);
  }

  onSelectPayingCurrency(event): void {
    this.appDataService.setSelectedPayingCurrency(this.payingCurrencyList[event.target.selectedIndex]);
  }

  calculateFromSendingAmount(): void {
    if (this.youSendAmount >= 1) {
      this.calculationService.calculateFromSendDirection(this.appDataService.getSelectedSendingCountry().country.countryId, this.selectedPayingCountry.country.countryId, this.selectedRemittanceType.remittanceTypeId, this.selectedRemittanceSubType ? this.selectedRemittanceSubType.remittanceSubTypeId : null, this.youSendAmount, null, this.form.get('promoCode').value)
        .then(calc => {
          this.setAmounts(calc);
          this.disableSubmitButton = false;
        })
        .catch(err => {
          this.dialog.open(ErrorComponent, {
            data: {
              message: err.message.toString()
            }
          });
          this.theyReceiveAmount = null;
          this.youSendAmount = null;
          this.calculation = null;
          this.disableSubmitButton = true;
        });
    }
  }

  calculateFromReceivingAmount(): void {
    if (this.theyReceiveAmount >= 1) {
      this.calculationService.calculateFromPayDirection(this.appDataService.getSelectedSendingCountry().country.countryId, this.selectedPayingCountry.country.countryId, this.selectedRemittanceType.remittanceTypeId, this.selectedRemittanceSubType ? this.selectedRemittanceSubType.remittanceSubTypeId : null, this.theyReceiveAmount, null, this.form.get('promoCode').value)
        .then(calc => {
          this.setAmounts(calc);
          this.disableSubmitButton = false;
        })
        .catch(err => {
          this.dialog.open(ErrorComponent, {
            data: {
              message: err.message.toString()
            }
          });
          this.theyReceiveAmount = null;
          this.youSendAmount = null;
          this.calculation = null;
          this.disableSubmitButton = true;
        });
    }
  }

  setAmounts(calc): void {
    if (this.currentUser && this.currentUser.isLocationRequired && (!this.latitude || !this.longitude)) {
      this.getUserLocation();
    }
    this.calculation = calc;
    let direction;
    let amount;
    if (this.calculateFromReceivingAmountTxt) {
      this.youSendAmount = this.calculation.sendAmount;
      amount = this.youSendAmount;
      direction = 'send';
    }
    if (this.calculateFromSendingAmountTxt) {
      this.theyReceiveAmount = this.calculation.payAmount;
      amount = this.theyReceiveAmount;
      direction = 'pay';
    }
    this.appDataService.validateMinAndMaxAmount_Api(this.appDataService.getSelectedSendingCountry().country.countryId, this.selectedPayingCountry.country.countryId, this.selectedRemittanceType.remittanceTypeId, this.selectedRemittanceSubType ? this.selectedRemittanceSubType.remittanceSubTypeId : null, amount, this.selectedSendingCurrency, this.selectedPayingCurrency, direction)
      .then(resp => {

      }).catch(err => {
        this.theyReceiveAmount = null;
        this.youSendAmount = null;
        this.enableCalculationButton = false;
        this.calculation = null;
        this.dialog.open(ErrorComponent, {
          data: {
            message: err.message.toString()
          }
        });
      });
    this.enableCalculationButton = true;
  }

  onVerifymmtAccount() {
    this.calculation = null;
    this.enableCalculationButton = false;
    this.showProductList = false;
    this.isNotVerified = false;
    this.isVerified = false;
    const q = new Promise((resolve, reject) => {
      if (this.form.get('mmtAccountNo').value) {
        this.disableVerifyButton = true;
        this.showVerifyProgress = true;
        this.remittanceService.verifymmtaccount_Api(this.form.get('mmtAccountNo').value.toString(), this.selectedRemittanceType.remittanceTypeId,
          (this.selectedRemittanceSubType ? this.selectedRemittanceSubType.remittanceSubTypeId : null), '', 432) // air time recharge actionId
          .then((apiResp: ApiResponse) => {
            this.disableVerifyButton = false;
            this.showVerifyProgress = false;
            if (apiResp.err) {
              this.isNotVerified = true;
              resolve(null);
              return;
            } else {
              this.appDataService.setAirtimeNumber(this.form.get('mmtAccountNo').value);
              this.isVerified = true;
              this.showProductList = true;
              this.destinationCurrency = apiResp.result[0].destinationCurrency;
              this.productListAmount = apiResp.result[0].productList;
              this.productListAmount.unshift('Select Amount');
              this.form.patchValue({
                ddlRechargeType: this.productListAmount[0],
              });
            }
          })
          .catch((apiException: ApiResponse) => {
            this.showProgress = false;
            resolve(null);
          });
      }
    });
    return q;
  }

  CheckBlackList(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const matchedEntity = this.appDataService.getMatchedBlacklistEntity();
      let blacklistCheckResult = await this.appDataService.isUserBlacklisted_Api(null, null, null, this.currentUser.mobile, null);
      if (!blacklistCheckResult.isBlackListed) {
        let blacklistCheckResp;
        blacklistCheckResp = await this.appDataService.isUserBlacklisted_Api(this.currentUser.firstName, this.currentUser.middleName, this.currentUser.lastName, null, null);
        if (blacklistCheckResp.isBlackListed) {
          blacklistCheckResult.isBlackListed = blacklistCheckResp.isBlackListed;
        }
        if (blacklistCheckResp.approvalRequired) {
          matchedEntity.senderNameBlackListed = true;
          this.appDataService.setMatchedBlacklistEntity(matchedEntity);
        }
        if (!blacklistCheckResult.isBlackListed) {
          blacklistCheckResp = await this.appDataService.isUserBlacklisted_Api(this.currentUser.firstName, this.currentUser.middleName, this.currentUser.lastName, this.currentUser.mobile, null);
          if (blacklistCheckResp.isBlackListed) {
            blacklistCheckResult.isBlackListed = blacklistCheckResp.isBlackListed;
          }
          if (blacklistCheckResp.approvalRequired) {
            matchedEntity.senderNameMobileBlackListed = true;
            this.appDataService.setMatchedBlacklistEntity(matchedEntity);
          }
          if (!blacklistCheckResult.isBlackListed) {
            blacklistCheckResp = await this.appDataService.isUserBlacklisted_Api(this.currentUser.firstName, this.currentUser.middleName, this.currentUser.lastName, null, this.currentUser.countryId);
            if (blacklistCheckResp.isBlackListed) {
              blacklistCheckResult.isBlackListed = blacklistCheckResp.isBlackListed;
            }
            if (blacklistCheckResp.approvalRequired) {
              matchedEntity.senderNameCountryBlackListed = true;
              this.appDataService.setMatchedBlacklistEntity(matchedEntity);
            }
          }
        }
      }
      if (blacklistCheckResult.isBlackListed === true) {
        this.dialog.open(ErrorComponent, {
          data: {
            message: blacklistCheckResult.blackListedCustomerError.message
          }
        });
        this.theyReceiveAmount = null;
        this.youSendAmount = null;
        resolve(false);
      }
      if (blacklistCheckResult.approvalRequired === true) {
        matchedEntity.senderMobileBlackListed = true;
        this.appDataService.setMatchedBlacklistEntity(matchedEntity);
      }
      resolve(true);
    });
  }
  CheckIP(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const sendingCountryId = this.selectedSendingCountry.country.countryId;
      const isValidIP = await this.appDataService.checkIP_Api(sendingCountryId);
      if (!isValidIP.isValid) {
        this.dialog.open(ErrorComponent, {
          data: {
            message: isValidIP.checkIPError.message
          }
        });
        this.theyReceiveAmount = null;
        this.youSendAmount = null;
        resolve(false);
      }
      resolve(true);
    });
  }

  checkLocation(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const sendingCountryId = this.selectedSendingCountry.country.countryId;
      const latitude = this.latitude;
      const longitude = this.longitude;
      try {
        const locationResult = await this.appDataService.checkLocation_Api(sendingCountryId, latitude, longitude);
        if (!locationResult.result[0].success) {
          this.dialog.open(ErrorComponent, {
            data: {
              message: locationResult.result[0].msg
            }
          });
          this.theyReceiveAmount = null;
          this.youSendAmount = null;
          this.calculation = null;
          this.form.controls['txtSendingAmount'].reset();
          this.form.controls['txtPayingAmount'].reset();
          this.form.controls['promoCode'].reset();
          resolve(false);
          return;
        }
        resolve(true);
      } catch (e) {
          this.dialog.open(ErrorComponent, {
            data: {
              message: e.err.message.toString()
            }
          });
          this.theyReceiveAmount = null;
          this.youSendAmount = null;
          this.calculation = null;
          this.form.controls['txtSendingAmount'].reset();
          this.form.controls['txtPayingAmount'].reset();
          this.form.controls['promoCode'].reset();
          resolve(false);
      }
    });
  }

  onSelectRechargeType(event) {
    this.form.patchValue({
      txtPayingAmount: this.form.controls['ddlRechargeType'].value,
    });
  }

  onSubmit() {
    this.disableSubmitButton = true;
    this.showProgress = true;
    if (localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString())) {
      this.checkLocation().then(value => {
        if (value) {
          this.CheckBlackList().then(value => {
            if (value) {
              this.router.navigate(['/remittance/addrecipient']);
            }
            this.showProgress = false;
          });
        }
        this.showProgress = false;
      });
    } else {
      this.router.navigate(['/remittance/addrecipient']);
    }
  }

  filterPartnersList() {
    if (this.selectedSendingCountry.country.countryId === 13) {
      this.finalPartnersList = this.finalPartnersList.filter(list => list.countryId === null || list.countryId === 13);
    } else if (this.selectedSendingCountry.country.countryId === 39) {
      this.finalPartnersList = this.finalPartnersList.filter(list => list.countryId === null || list.countryId === 39);
    } else if (this.selectedSendingCountry.country.countryId === 246) {
      this.finalPartnersList = this.finalPartnersList.filter(list => list.countryId === null || list.countryId === 246);
    } else {
      this.finalPartnersList = this.finalPartnersList.filter(list => list.countryId === null || list.countryId === 245);
    }
  }

  capitalizeCountryName(countryName) {
    const splitCountryName = countryName.toLowerCase().split(' ');
    for (let i = 0; i < splitCountryName.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitCountryName[i] = splitCountryName[i].charAt(0).toUpperCase() + splitCountryName[i].substring(1);
    }
    // Directly return the joined string
    return splitCountryName.join(' ');
  }

  getUserLocation(): void{
    if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    } else {
      console.log("No support for geolocation")
    }
  }

  ngAfterViewInit() {
    // code to disable keyboard keyup/down and mouse wheel scroll for send and receive amount number text field
    if (this.txtSendingAmount) {
      this.txtSendingAmount.nativeElement.addEventListener('keydown', function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
      this.txtSendingAmount.nativeElement.addEventListener('wheel', function (e) {
        e.preventDefault();
      });
    }
    if (this.txtPayingAmount) {
      this.txtPayingAmount.nativeElement.addEventListener('keydown', function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
      this.txtPayingAmount.nativeElement.addEventListener('wheel', function (e) {
        e.preventDefault();
      });
    }
  }

  showPromotionCode() {
    if(!this.showPromoCode) {
      this.showPromoCode = true;
    } else {
      this.showPromoCode = false;
    }
  }

  applyPromotionCode() {
    this.promoCode = this.form.get('promoCode').value;
    this.calculateFromSendingAmount();
  }

  removePromoCode() {
    this.showPromoCode = false;
    this.form.controls['promoCode'].reset();
    this.calculateFromSendingAmount();
  }
}

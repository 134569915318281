import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDataService } from './shared/app-data.service';
import { ApiService } from './shared/api.service';
import { AuthGuard } from './shared/auth.gaurd';
import { AppRoutingModule } from './app-routing.module';
import { CustomersModule } from './modules/customers/customers.module';
import { RemittanceModule } from './modules/remittance/remittance.module';
import { Session } from './models/session';
import { AppComponent } from './app.component';
import { NavmenuComponent } from './components/navmenu/navmenu.component';
import { CalculationService } from './shared/calculation.service';
import { AuthService } from './shared/auth.service';
import { SendingCountryResolver } from './shared/sendingcountry-resolver.service';
import { PayingCountryResolver } from './shared/payingcountry-resolver.service';
import { SharedModule } from './shared/shared.module';
import { CookieLawModule } from 'angular2-cookie-law';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { UserSessionService } from './shared/usersession.service';
import { HomefooterComponent } from './components/homefooter/homefooter.component';
import { PagefooterComponent } from './components/pagefooter/pagefooter.component';
import { SuccessComponent } from './components/success/success.component';
import { ErrorComponent } from './components/error/error.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { ModalpopupComponent } from './components/modalpopup/modalpopup.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MomentModule } from 'angular2-moment';
import { GoogleAnalyticsService } from './shared/google-analytics.service';
import { HomeclientModule } from './components/homeclient/homeclient.module';
import { NgxLoadingModule } from 'ngx-loading';
import { LoginComponent } from './components/login/login.component';
import { CalculatorcommonComponent } from './components/calculatorcommon/calculatorcommon.component';
import { CalciComponent } from './components/calci/calci.component';
import { SendingCountryForCalcResolver } from './shared/sendingcountryforcalc-resolver.service';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SelectcountryComponent } from './components/selectcountry/selectcountry.component';
import { HomeclientgeneralModule } from './components/homeclientgeneral/homeclientgeneral.module';
import { APP_BASE_HREF } from '@angular/common';
import { getBaseLocation } from './shared/utilities';
import { MigrationComponent } from './components/migration/migration.component';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { LogoutComponent } from './components/logout/logout.component';


@NgModule({
    declarations: [
        AppComponent, NavmenuComponent, HomefooterComponent, PagefooterComponent, SuccessComponent, ErrorComponent, NotificationsComponent, ModalpopupComponent, LoginComponent, CalculatorcommonComponent, CalciComponent,  SelectcountryComponent, MigrationComponent, UnderMaintenanceComponent, LogoutComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }), ReactiveFormsModule,
        CustomersModule, RemittanceModule, AppRoutingModule, NgxLoadingModule,
        SharedModule, CookieLawModule, BrowserAnimationsModule,
        //   FlashMessagesModule.forRoot(),
        MatDialogModule, MatButtonModule, MatCardModule, MatMenuModule,
        MatDatepickerModule, MatNativeDateModule, MatInputModule,
        HttpClientModule, MatDividerModule, MatProgressSpinnerModule,
        MomentModule, HomeclientModule,
        RecaptchaFormsModule, FormsModule, HomeclientgeneralModule, RecaptchaModule

    ],
    entryComponents: [],
    providers: [AppDataService, CalculationService, Session, ApiService, AuthGuard, AuthService,
        SendingCountryResolver, SendingCountryForCalcResolver, PayingCountryResolver, CookieService, UserSessionService, GoogleAnalyticsService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: environment.reCaptchaSiteKey } as RecaptchaSettings,
        },
        { provide: APP_BASE_HREF, useFactory: getBaseLocation }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(protected _googleAnalyticsService: GoogleAnalyticsService) { }
}

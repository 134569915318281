<div class="position-relative overflow-hidden bg-light">
  <div class="col-md-12 mx-auto hOnePageTitle">
    <h1 class="p-4 text-center">Update on COVID-19</h1>
  </div>
</div>
<div class="container single-page my-5">
  <div class="row">
    <div class="col-md-12">
      <small class="text-muted"><i class="fa fa-user-circle fa-lg mr-2"></i><strong>Taaj</strong><span class="mx-2">-</span>30 March, 2020<span class="mx-2">-</span>1 min read</small><br><br>
    </div>
  </div>
  <div class="row">
      <div class="col-md-10" i18n>
          <img class="img-fluid news-thumbnail w-50" alt="Taaj update on covid-19" src="../../../assets/img/news/covid19.png">
      </div>
  </div>
  <div class="row py-5">
    <div class="col-md-10">
      <p class="text-justify">Taaj is with you 24/7 to assist you to continue support your loved one during COVID-19 restriction. We advise our clients to use transfer to Mobile money account, Airtime and bank account instead of cash collection transfers due to the impact of Corona pandemic.</p>
      <h5 class="pt-2 mb-0 pb-0">Taaj</h5>
      <h6 class="pt-0">Customer support</h6>
    </div>
  </div>
</div>

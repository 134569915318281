<div *ngIf="receipt" class="modal-body" style="height: 500px">
  <div class="slPrintReciept" id="slPrintReciept">
    <div id="pageBorder">
      <div class="container salesreceipt">
        <div class="row">
          <div class="col-md-12 pb-3">
            <div
              *ngIf="selectedSendingCountry?.country?.countryId != 64 && selectedSendingCountry?.country?.countryId != 39 && selectedSendingCountry?.country?.countryId != 129">
              <div class="card">
                <img src="/assets/images/Taaj-Logo.svg" style="margin: 20px 0px 10px 20px;" height="55">
                <div class="row">
                  <div class="col-md-6">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">Teleposta Towers, G Floor, Kenyatta Avenue,
                        PO Box 47583-00100, Nairobi - Kenya.<br>
                        <u>Tel:</u> 020-2214722 / 020-2214723, <u>Email:</u> globalfrx@gmail.com
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <p>&nbsp;</p>
                    <h6 i18n>Payment Receipt - Customer Copy</h6>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedSendingCountry?.country?.countryId === 64">
              <div class="card">
                <img src="/assets/images/Taaj-Logo.svg" style="margin: 20px 0px 10px 20px;" height="55">
                <div class="row">
                  <div class="col-md-6">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">Angle de Rue, de Verdon et Rue de Rayon, Immeuble Marouf, Quartier
                        Comercial, Djibouti, Centre Ville Djibouti<br>
                        <u>Tel:</u> +971 4 259 4723, <u>Email:</u> info@taajservices.com
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <p>&nbsp;</p>
                    <h6 i18n>Payment Receipt - Customer Copy</h6>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedSendingCountry?.country?.countryId === 129">
              <div class=" card">
              <img src="/assets/images/Taaj-Logo.svg" style="margin: 20px 0px 10px 20px;" height="55">
              <div class="row">
                <div class="col-md-6">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Teleposta Towers, G Floor, Kenyatta Avenue,
                      PO Box 47583-00100, Nairobi - Kenya.<br>
                      <u>Tel:</u> 020-2214722 / 020-2214723, <u>Email:</u> globalfrx@gmail.com
                    </li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <p>&nbsp;</p>
                  <h6 i18n>Payment Receipt - Customer Copy</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 pb-3">
          <div class="card">
            <div class="card-header">
              <h5 i18n>Sender Details</h5>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" i18n><strong>Customer Number</strong> <span
                  class="float-right">{{receipt.senderId}}</span></li>
              <li class="list-group-item" i18n><strong>Name</strong> <span
                  class="float-right">{{receipt.senderFullName}}</span></li>
              <li class="list-group-item" i18n><strong>Country</strong> <span
                  class="float-right">{{receipt.senBranchCountry}}</span></li>
              <li class="list-group-item" i18n><strong>Address</strong> <span
                  class="float-right">{{receipt.senderAddress}}</span></li>
              <li class="list-group-item" i18n><strong>Mobile</strong> <span
                  class="float-right">{{receipt.senderMobile}}</span></li>
            </ul>
          </div>
          <div class="card mt-2">
            <div class="card-header">
              <h5 i18n>Receiver Details</h5>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" i18n><strong>Name</strong> <span
                  class="float-right">{{receipt.receiverFullName}}</span></li>
              <li class="list-group-item" i18n><strong>Country</strong> <span
                  class="float-right">{{receipt.receiverCountryName}}</span></li>
              <!--<li class="list-group-item" i18n><strong>Address</strong> <span
                  class="float-right">{{receipt.receiverAddress}}</span></li>-->
              <li class="list-group-item" i18n><strong>Mobile</strong> <span
                  class="float-right">{{receipt.receiverMobile}}</span></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 pb-3">
          <div class="card">
            <div class="card-header">
              <h5 i18n>Transfer Details</h5>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" i18n><strong>Order Number</strong> <span
                  class="float-right">{{receipt.orderNumber}}</span></li>
              <li class="list-group-item" i18n><strong>Order Status</strong> <span class="float-right"
                  style="text-transform: capitalize;">{{tranStatus}}</span>
              </li>
              <li class="list-group-item" i18n><strong>Date Time</strong> <span class="float-right">{{receipt.sDate |
                  date}} {{receipt.sDate | date:'shortTime'}}</span></li>
              <li *ngIf="receipt.remittanceSubTypeId === 5" class="list-group-item" i18n><strong>Taaj
                  RID</strong> <span class="float-right">{{receipt.remittanceCode}}</span></li>
              <li class="list-group-item" i18n><strong>Payment Method</strong> <span
                  class="float-right">{{receipt.paymentMethod}}</span></li>
              <li class="list-group-item" i18n><strong>Service</strong> <span
                  class="float-right">{{receipt.remittanceSubTypeLabel}}</span></li>
              <li *ngIf="receipt.sDate" class="list-group-item" i18n><strong>Availability of funds</strong> <span
                  class="float-right">{{receipt.sDate | date}} {{receipt.sDate | date:'shortTime'}}</span></li>
            </ul>
          </div>
          <div class="card mt-2">
            <div class="card-header">
              <h5 i18n>Transfer Breakdown</h5>
            </div>
            <ul class="list-group list-group-flush" style="border-bottom: 1px solid lightgray;">
              <li class="list-group-item" i18n><strong>They Receive</strong> <span
                  class="float-right">{{receipt.receivingCurrencyCode}}
                  {{receipt.receivingLocalAmount| number:'1.1-3'}}</span></li>
              <li class="list-group-item" i18n><strong>Exchange Rate</strong> <span class="float-right">1
                  {{receipt.sendingCurrencyCode}} =
                  {{receipt.currencyToCurrencyExRate | number:'1.1-3'}}
                  {{receipt.receivingCurrencyCode}}</span></li>
              <li class="list-group-item" i18n><strong>You Send</strong> <span
                  class="float-right">{{receipt.sendingCurrencyCode}}
                  {{receipt.sendingLocalAmount | number:'1.1-3'}}</span></li>
              <li class="list-group-item" i18n><strong>Fees</strong> <span
                  class="float-right">{{receipt.sendingCurrencyCode}}
                  {{receipt.sendingLocalServiceCharge | number:'1.1-3'}}</span></li>
              <li class="list-group-item" i18n><strong>Total Paid</strong> <span class="float-right">
                  <h4>{{receipt.sendingCurrencyCode}}
                    {{(receipt.sendingLocalAmount + receipt.sendingLocalServiceCharge) | number:'1.1-3'}}
                  </h4>
                </span></li>
            </ul>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card mt-3">
            <div class="card-body">
              <p class="mb-0"><strong><u>Note:</u> This receipt is not a proof that funds have
                  reached the receiver</strong></p>
            </div>
          </div>
        </div>
        <div
          *ngIf="selectedSendingCountry?.country?.countryId === 246 || selectedSendingCountry?.country?.countryId === 39">
          <div class="col-md-12">
            <div class="card mt-3">
              <div class="card-header">
                <h5 i18n>Disclosure</h5>
              </div>
              <div class="card-body">
                <h6 class="card-title" i18n>Thank you for using Taaj - powered by SafariPay Corp.</h6>
                <p class="card-text" i18n> Recipients may receive less due to fees charged by the recipient's bank and
                  foreign taxes.</p>
                <p>You can cancel for a full refund within 30 minutes of payment, unless the funds have been picked up
                  or deposited.</p>
                <p>You have a right to dispute errors in your transaction. If you think there is an error, contact us
                  within 180 days by calling us at: +1-855-618-0888, or email us at: customerservice@safaripay.com or
                  write us to 'SafariPay Corp. DBA Taaj “Ops. Department” '860 Blue Gentian Road, Suite 200 Eagan, MN
                  55121. </p>
                <p class="card-text" i18n>For more information, please read our <a href="https://www.taaj.com/terms"
                    target="_blank" title="Terms and conditions">Terms &
                    Condition</a>, which should be read in conjuction with our <a
                    href="https://www.taaj.com/privacypolicy" target="_blank" title="Privacy Policy">Privacy
                    Policy.</a></p>
                <p class="card-text" i18n> If after calling SafariPay your concern has not been solved, please contact:
                </p>
                <p class="card-text" i18n> Consumer Financial Protection Bureau: 855-411-2372, 855-729-2372 (TTY/TDD),
                  www.consumerfinance.gov</p>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="selectedSendingCountry?.country?.countryId !== 246 && selectedSendingCountry?.country?.countryId !== 39">
          <div class="col-md-12">
            <div class="card mt-3">
              <div class="card-header">
                <h5 i18n>Terms & conditions</h5>
              </div>
              <div class="card-body">
                <h6 class="card-title" i18n>Thank you for using Taaj</h6>
                <p class="card-text" i18n><strong>Declaration:</strong> Taaj is/will not be
                  responsible for the delays due to incorrect information being provided. Only the
                  amount sent excluding the service charge is refundable. The rate of exchange will be
                  set at the time the recipient(s) receives the funds.<br>
                  I the customer hereby declare the funds were not from illegal sources or activities
                  including but not limited to money laundering, terrorist financing etc…</p>
                <p class="card-text" i18n>For more information, please read our <a routerLink="/terms" target="_blank"
                    title="Terms and conditions">Terms &
                    Condition</a>, which should be read in conjuction with our <a routerLink="/privacypolicy"
                    target="_blank" title="Privacy Policy">Privacy Policy.</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<div class="modal-footer">
  <button (click)="print('slPrintReciept')" class="btn btn-outline-success" i18n type="button">Print</button>
  <button (click)="dialoge()" class="btn btn-outline-success" i18n type="button">Close</button>
</div>
</div>

import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { Utilities } from './utilities';
import { CookieService } from 'ngx-cookie-service';
import { ApiResponse } from '../models/apiresponse';
import { UserSessionService } from './usersession.service';
import { AppDataService } from './app-data.service';
import { SendingCountry } from '../models/sendingcountry';
import { ErrorComponent } from '../components/error/error.component';
import {environment} from "../../environments/environment";

@Injectable()
export class AuthService {
  currentUser: User;
  token: string;
  constructor(private apiService: ApiService, private cookieService: CookieService,
              private userSessionService: UserSessionService, private appDataService: AppDataService) { }

  getUserDetails(): string {
    if (this.currentUser) {
      return this.currentUser.userName;
    }
  }

  /*  API CALLS*/

/*  signupUser_Api(countryId: number, mobile: string, userName: string, password: string,
    firstName: string, middleName: string, lastName: string, email: string, referralCode: string, state: string, countryStateId: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('adduser');
      const payload = {countryId: countryId.toString(), mobile: mobile, username: userName, password: password,
        firstName: firstName, middleName: middleName, lastName: lastName, email: email, referralCode: referralCode,
        state: state, countryStateId: countryStateId};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }*/

  signupUser_Api(countryId: number, mobile: string, userName: string, password: string,
                 firstName: string, middleName: string, lastName: string, email: string, referralCode: string, state: string, countryStateId: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('adduser');
      const payload = {countryId: countryId.toString(), mobile: mobile, username: userName, password: password,
        firstName: firstName, middleName: middleName, lastName: lastName, email: email, referralCode: referralCode,
        state: state, countryStateId: countryStateId};
      this.getEncryptionToken_Api('secretRegistration')
        .then((apiResp: ApiResponse) => {
          let encrypt: boolean;
          let token: string;
          if (apiResp.err) {
            resolve(apiResp);
          }
          if (apiResp.result && apiResp.result.length) {
            encrypt =  apiResp.result[0].encrypt;
            token   =  apiResp.result[0].token;
          }
          this.apiService.sendHttpPostRequest(endpoint, payload, null, encrypt, token)
            .then(resp => {
              resolve(resp);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch((apiResp: ApiResponse) => {
          console.log('START- EXCEPTION ON LOGIN');
          console.log(apiResp);
          console.log('END- EXCEPTION ON LOGIN');
          this.userSessionService.currentUserSubject.next(null);
          reject(apiResp);
        });
    });
  }

  verifyDuplicateUser_Api(username: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('checkemailisregistered');
      const payload = {username: username};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getEncryptionToken_Api(userName: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('createToken');
      const payload = {username: userName};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  signinUser(username: string, password: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('login');
      const payload = {username: username, password: password, isWebLogin: true};
      this.getEncryptionToken_Api(username)
        .then((apiResp: ApiResponse) => {
          let encrypt: boolean;
          let token: string;
          if (apiResp.err) {
            resolve(apiResp);
          }
          if (apiResp.result && apiResp.result.length) {
            encrypt =  apiResp.result[0].encrypt;
            token   =  apiResp.result[0].token;
          }
          this.apiService.sendHttpPostRequest(endpoint, payload, null, encrypt, token)
            .then((resp: ApiResponse) => {
              if (resp.err) {
                console.log('START- ERROR RESPONSE ON LOGIN');
                console.log(resp.err);
                console.log('END- ERROR RESPONSE ON LOGIN');
                resolve(resp);
              }
              if (resp.result) {
                if(resp.result.length && resp.result[0].user && resp.result[0].user.isForcePwdChange == 1){
                  resolve(resp);
                  return;
                }
                if(resp.result.length && resp.result[0].user && resp.result[0].user.isOrmOtpRequired == 1){
                  localStorage.setItem(Utilities.encryptPayLoad2('userInfoForRegistration',environment.clientKey).toString(),
                    Utilities.encryptPayLoad(JSON.stringify({userId : resp.result[0].user.userId}),environment.clientKey).toString());
                  resolve(resp);
                  return;
                }
                if(resp.result.length && resp.result[0].user && resp.result[0].user.isTwoFactorAuthEnabled == 1){
                  localStorage.setItem(Utilities.encryptPayLoad2('twoFactorUserEnabledId',environment.clientKey).toString(),
                    Utilities.encryptPayLoad(JSON.stringify({userId : resp.result[0].user.userId}),environment.clientKey).toString());
                  resolve(resp);
                  return;
                }
                resp.result.forEach( value => {
                    this.currentUser = new User(value.user.userId, value.user.username,
                    value.user.firstName, value.user.middleName, value.user.lastName,
                    value.user.branchDefaultCashAccountTypeId,
                    value.user.branchDefaultCashAccountId,
                    value.user.branchDefaultCashAccountName,
                    value.user.lastLogin,
                    value.user.encrypt, value.user.token,
                    value.user.countryId ? value.user.countryId : value.user.branchCountryId, value.user.customerId,
                    value.user.mobile, value.user.isSenderAddInfoAvailable, value.user.isSenderDocInfoAvailable, null, value.user.availableZeroCommRemits, value.user.isLocationRequired, value.user.ormMigrationStateId, null, null, null, null, null, null, null,  null , value.user.customerStateId) ;
                  localStorage.setItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString(),
                    Utilities.encryptPayLoad(JSON.stringify(this.currentUser),environment.clientKey).toString());
                  const APP_DATA = localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString()) ? JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString()),environment.clientKey)) : {};
                  let selectedSendingCountry: SendingCountry;
                  let sendingCountryList: SendingCountry[] = [];
                  if (this.appDataService.getSendingCountriesList() && this.appDataService.getSelectedSendingCountry()) {
                    sendingCountryList = this.appDataService.getSendingCountriesList();
                    selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
                  } else if (APP_DATA && APP_DATA.sendingCountryList && APP_DATA.selectedSendingCountry) {
                    sendingCountryList = APP_DATA.sendingCountryList;
                    selectedSendingCountry = APP_DATA.selectedSendingCountry;
                  } else {
                    this.appDataService.getSendingCountriesList_Api()
                      .then(list => {
                        sendingCountryList = list;
                        selectedSendingCountry = this.appDataService.getSelectedSendingCountry();
                      })
                      .catch(err => {
                        reject(err);
                      });
                  }
                  if (selectedSendingCountry && selectedSendingCountry.country.countryId !== JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).countryId) {
                    resp.result.unMatchedSelectedSendingCountry = true;
                    sendingCountryList.forEach(sendingCountry => {
                      if (sendingCountry.country.countryId === JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).countryId) {
                        this.appDataService.setSelectedSendingCountry(sendingCountry);
                      }
                    });
                  }
                  this.userSessionService.currentUserSubject.next(this.currentUser);
                });
                resolve(resp);
              } else {
                console.log('START- NO RESPONSE - RESULT ON LOGIN');
                console.log(resp);
                console.log('END- NO RESPONSE - RESULT ON LOGIN');
              }
            })
            .catch((err: ApiResponse) => {
              console.log('START- EXCEPTION ON LOGIN');
              console.log(err);
              console.log('END- EXCEPTION ON LOGIN');
              this.userSessionService.currentUserSubject.next(null);
              reject(err);
            });
        })
        .catch((apiResp: ApiResponse) => {
          console.log('START- EXCEPTION ON LOGIN');
          console.log(apiResp);
          console.log('END- EXCEPTION ON LOGIN');
          this.userSessionService.currentUserSubject.next(null);
          reject(apiResp);
        });
    });
  }

  verifyEmail_Api(id: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('verifyemail');
      const payload = {id: id};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  resendVerificationEmail_Api(id: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('resendverificationemail');
      const payload = {id: id};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  logout_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('logout');
      const payload = {};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          this.cookieService.delete('encrypt');
          this.cookieService.delete('token');
          localStorage.removeItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString());
          localStorage.removeItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString());
          this.currentUser = null;
          this.userSessionService.currentUserSubject.next(null);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  deactivateUserAccount_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('deactivateormuseraccount');
      const payload = {};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          this.cookieService.delete('encrypt');
          this.cookieService.delete('token');
          localStorage.removeItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString());
          localStorage.removeItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString());
          this.currentUser = null;
          this.userSessionService.currentUserSubject.next(null);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  updatePassword(currentPassword: string, upPassword: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('updatepassword');
      const payload = {currentPassword: currentPassword, upPassword: upPassword};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

/*  resetPassword(id: string, newPassword: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('resetpassword');
      const payload = {id: id, newPassword: newPassword};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }*/

  resetPassword_Api(id: string, newPassword: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('resetpassword');
      const payload = {id: id, newPassword: newPassword};
      this.getEncryptionToken_Api('secretResetPassword')
        .then((apiResp: ApiResponse) => {
          let encrypt: boolean;
          let token: string;
          if (apiResp.err) {
            resolve(apiResp);
          }
          if (apiResp.result && apiResp.result.length) {
            encrypt =  apiResp.result[0].encrypt;
            token   =  apiResp.result[0].token;
          }
          this.apiService.sendHttpPostRequest(endpoint, payload, null, encrypt, token)
            .then(resp => {
              resolve(resp);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch((apiResp: ApiResponse) => {
          console.log('START- EXCEPTION ON RESET PASSWORD');
          console.log(apiResp);
          console.log('END- EXCEPTION ON RESET PASSWORD');
          this.userSessionService.currentUserSubject.next(null);
          reject(apiResp);
        });
    });
  }

  forgotPassword_Api(userName: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('forgotpassword');
      const payload = {username: userName};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  isActiveUserSession_Api(): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('isactiveusersession');
      const payload = {};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then((resp: ApiResponse) => {
          resolve(resp);
        })
        .catch((err: ApiResponse) => {
          reject(err);
        });
    });
  }

  getBankIdLoginUrl_Api(state: string, method: string, countryId: number, guid: string, responseCode: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('getbankidloginurl');
      let payload;
      if(guid != null && guid != ''  && responseCode != null && responseCode != '') {
        payload = {state: state, method: method, countryId: countryId, guid, responseCode};
      } else {
        payload = {state: state, method: method, countryId: countryId};
      }
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  signupBankId_Api(fullName: string, mobile: string, email: string, address: string, dateOfBirth: string,
                   locale: string, nationalId: string, password: string, countryId: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('signupbankid');
      const payload = {fullName: fullName, mobile: mobile,
        email: email, address: address, dateOfBirth: dateOfBirth, locale: locale,
        nationalId: nationalId,  password: password, countryId: countryId};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  migrateOnlineCustomer_Api(customerId: number, firstName: string, middleName: string, lastName: string, mobile: string,
                            email: string, password: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('migrateonlinecustomer');
      const payload = {customerId: customerId, firstName: firstName, middleName: middleName,
        lastName: lastName, mobile: mobile, email: email,  password: password};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  loginBankId(id: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('loginbankid');
      const payload = {bankId: id};
      this.getEncryptionToken_Api(id)
        .then((apiResp: ApiResponse) => {
          let encrypt: boolean;
          let token: string;
          if (apiResp.err) {
            resolve(apiResp);
          }
          if (apiResp.result) {
            encrypt =  apiResp.result[0].encrypt;
            token   =  apiResp.result[0].token;
          }
          this.apiService.sendHttpPostRequest(endpoint, payload, null, encrypt, token)
            .then((resp: ApiResponse) => {
              if (resp.err) {
                resolve(resp);
              }
              if (resp.result && resp.result[0].redirectUrl) {
                resolve(resp);
              }
              if (resp.result ) {
                resp.result.forEach( value => {
                  this.currentUser = new User(value.user.userId, value.user.username,
                    value.user.firstName, value.user.lastName,
                    value.user.branchDefaultCashAccountTypeId,
                    value.user.branchDefaultCashAccountId,
                    value.user.branchDefaultCashAccountName,
                    new Date().toDateString(),
                    value.user.encrypt, value.user.token,
                    value.user.countryId ? value.user.countryId : value.user.branchCountryId, value.user.customerId,
                    value.user.mobile, value.user.isSenderAddInfoAvailable, value.user.isSenderDocInfoAvailable, null, value.user.availableZeroCommRemits, value.user.isLocationRequired, value.user.ormMigrationStateId, null , null , null , null, null, null, null, null, value.user.accountCreateDate, value.user.customerStateId);
                  localStorage.setItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString(),
                    Utilities.encryptPayLoad(JSON.stringify(this.currentUser),environment.clientKey).toString());
                  const APP_DATA = localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString()) ? JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString()),environment.clientKey)) : {};
                  if (this.appDataService.getSelectedSendingCountry()) {
                    if (this.appDataService.getSelectedSendingCountry()
                        .country.countryId !== JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).countryId) {
                      this.appDataService.getSendingCountriesList_Api()
                        .then(sendingCountryList => {
                          sendingCountryList.forEach(sendingCountry => {
                            if (sendingCountry.country.countryId === JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).countryId) {
                              this.appDataService.setSelectedSendingCountry(sendingCountry);
                            }
                          });
                        })
                        .catch(err =>  {
                          reject(err);
                        });
                    }
                  } else if (APP_DATA && APP_DATA.sendingCountryList && APP_DATA.selectedSendingCountry) {
                    if (APP_DATA.selectedSendingCountry.country.countryId !== JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).countryId) {
                      this.appDataService.getSendingCountriesList_Api()
                        .then(sendingCountryList => {
                          sendingCountryList.forEach(sendingCountry => {
                            if (sendingCountry.country.countryId === JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey)).countryId) {
                              this.appDataService.setSelectedSendingCountry(sendingCountry);
                            }
                          });
                        })
                        .catch(err =>  {
                          reject(err);
                      });
                    }
                  }
                  this.userSessionService.currentUserSubject.next(this.currentUser);
                });
                resolve(resp);
              }
            })
            .catch((err: ApiResponse) => {
              this.userSessionService.currentUserSubject.next(null);
              reject(err);
            });
        })
        .catch((apiResp: ApiResponse) => {
          this.userSessionService.currentUserSubject.next(null);
          reject(apiResp);
        });
    });
  }

  sendEmail(subject: string, contactName: string, contactEmail: string, contactPhone: string, contactMessage: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('contactusbyemail');
      const payload = {subject: subject, customerName: contactName, customerEmail: contactEmail, customerMobile: contactPhone, customerMessage: contactMessage};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  migrateOrmUser_Api(payload): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('migrateormuser');
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          if(resp && resp.result && resp.result[0] && resp.result[0].isOrmUserMigrated == 1) {
            this.cookieService.delete('encrypt');
            this.cookieService.delete('token');
            localStorage.removeItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString());
            localStorage.removeItem(Utilities.encryptPayLoad2('APP_DATA',environment.clientKey).toString());
            this.currentUser = null;
            this.userSessionService.currentUserSubject.next(null);
          }
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  loginOTPVerification_Api(userName: string, userId: string, loginOTP: string, ormResendMobileVeriOtpCounter: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('loginotpverification');
      const payload = {userName: userName, userId: userId, loginOTP: loginOTP, ormResendMobileVeriOtpCounter};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  resendOtp_Api(userName: string, userId: string, loginOTP: string, mobile: string, countryId: string, userOtpTypeId: number, ormResendMobileVeriOtpCounter: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('sendsmsotptoenableappauth');
      const payload = {username: userName, userId: userId, loginOTP: loginOTP, mobile, countryId, userOtpTypeId, ormResendMobileVeriOtpCounter};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  senOtpByEmail_Api(userName: string, userId: string, loginOTP: string, mobile: string, countryId: string, isLoginOTPEnabled: number, firstName: string): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('sendormotpbyemail');
      const payload = {username: userName, userId: userId, loginOTP: loginOTP, mobile, countryId, isLoginOTPEnabled, firstName};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  verifyOtp_Api(userId: string,userName: string, loginOTP: string, isLoginOTPEnabled: number, isLoginOtp: number): Promise<ApiResponse> {
    return new Promise<ApiResponse>((resolve, reject) => {
      const endpoint = Utilities.getEndpointUrlByApiName('ormotpverification');
      const payload = {userId: userId,userName, loginOTP: loginOTP, isLoginOTPEnabled, isLoginOtp};
      this.apiService.sendHttpPostRequest(endpoint, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

import { Component, OnInit, Inject, PLATFORM_ID, EventEmitter, Output } from '@angular/core';
import { User } from '../app/models/user';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { UserSessionService } from '../app/shared/usersession.service';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Params, ActivatedRoute } from '@angular/router';
import { AuthService } from '../app/shared/auth.service';
import { AppDataService } from '../app/shared/app-data.service';
import { ApiResponse } from '../app/models/apiresponse';
import { ErrorComponent } from '../app/components/error/error.component';
import { environment } from '../environments/environment';
import { Location, isPlatformBrowser } from '@angular/common';
import SmartBanner from 'smart-app-banner';
import { SeoService } from '../app/shared/seo.service';
import { siteLanguages } from '../app/shared/constants';
import { siteRegions } from '../app/shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { SendingCountry } from "./models/sendingcountry";
import {Utilities} from "./shared/utilities";

declare var SmartBanner: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  currentUserSubscription: Subscription;
  title = 'Taaj';
  currentUser: User;
  InitialshowProgress = false;
  timedOut = false;
  isHomePage: boolean;
  route: string;
  isBrowser: boolean;
  showSelectCountry: boolean;
  isHomeGeneralPage: boolean;
  selectedSendingCountry: SendingCountry;
  selectedSenderCountrySubscription: Subscription
  isCheckoutPage: boolean;
  isUnderMaintenance: boolean;

  constructor(private userSessionService: UserSessionService, private router: Router, location: Location,
    private appDataService: AppDataService, private authService: AuthService, public dialog: MatDialog,
    private titleService: Title, @Inject(PLATFORM_ID) platformId: Object, private seoService: SeoService) {
    this.titleService.setTitle('Online Money Transfer | Send Money Abroad | Taaj');
    this.isBrowser = isPlatformBrowser(platformId);
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.InitialshowProgress = true;
          break;
        }
        case event instanceof NavigationEnd: {
          if (this.isBrowser) {
            if (this.route !== '' && this.route !== '/') {
              this.createHrefLangTags(this.route, true);
            }
          }
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.InitialshowProgress = false;
          break;
        }
        default: {
          break;
        }
      }
    });
    router.events.subscribe(val => {
      this.route = location.path();
      this.isHomePage = false;
      this.isHomeGeneralPage = false;
      this.isCheckoutPage = false;
      this.isUnderMaintenance = false;
      if (this.route === '' || this.route === '/' || this.route === '/?sci=246&pci=211') {
        this.isHomePage = true;
      }
      if (this.route === '/home1') {
        this.isHomeGeneralPage = true;
      }
      if (this.route === '/contactus' || this.route === '/aboutus' || this.route === '/terms' || this.route === '/privacypolicy' || this.route === 'complaintspolicy' || this.route === '/refundpolicy') {
        this.showSelectCountry = true;
      }
      if (this.route === '/remittance/checkout') {
        this.isCheckoutPage = true;
      }
      if (this.route === '/under-maintenance' || this.route === "/under-maintenance") {
        this.isUnderMaintenance = true;
      }
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(Utilities.decryptPayLoad2(localStorage.getItem(Utilities.encryptPayLoad2('currentUser',environment.clientKey).toString()),environment.clientKey));
    this.selectedSenderCountrySubscription = this.appDataService.selectedSendingCountrySubject.subscribe(
      (value: SendingCountry) => {
        this.selectedSendingCountry = value;
      });
    this.currentUserSubscription = this.userSessionService.currentUserSubject.subscribe(
      (value: User) => {
        this.currentUser = value;
      });

    this.appDataService.redirectByDomain_Api()
      .then((apiResp: ApiResponse) => {
        if (apiResp.err) {
          return;
        }
        if (apiResp.result) {
          if (apiResp.result[0].isRedirection) {
            window.location.assign(apiResp.result[0].redirectUrl);
          }
        }
      })
      .catch((apiException: ApiResponse) => {
        console.log(apiException);
      });

  }

  onActivate(event) {
    document.body.scrollTop = 0;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.isBrowser) {
        // new SmartBanner({
        //   daysHidden: 1,   // days to hide banner after close button is clicked (defaults to 15)
        //   daysReminder: 30, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        //   appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
        //   title: 'Taaj',
        //   author: 'Real Remit Ltd - Finance',
        //   button: 'INSTALL',
        //   store: {
        //     ios: ' On the App Store',
        //     android: 'In Google Play',
        //     windows: 'In Windows store'
        //   },
        //   price: {
        //     ios: 'FREE',
        //     android: 'FREE',
        //     windows: 'FREE'
        //   }
        //   // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        //   // , icon: '' // full path to icon image if not using website icon image
        //   // , force: 'ios' // Uncomment for platform emulation
        // });
      }
      this.createHrefLangTags(this.route, false);
    }, 3000);
  }

  disabledRightclick() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = "document.addEventListener('contextmenu',event=>event.preventDefault());document.onkeydown=function(e){if(event.keyCode==123){return false}if(e.ctrlKey&&e.shiftKey&&e.keyCode=='I'.charCodeAt(0)){return false}if(e.ctrlKey&&e.shiftKey&&e.keyCode=='J'.charCodeAt(0)){return false}if(e.ctrlKey&&e.shiftKey&&e.keyCode=='C'.charCodeAt(0)){return false}if(e.ctrlKey&&e.keyCode=='U'.charCodeAt(0)){return false}}";
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  createHrefLangTags(link: string, removeOldTags: boolean) {
    this.seoService.createHrefLangTags(link, removeOldTags);
  }

  scrolToTop() {
    document.body.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnDestroy() {
    if (this.selectedSenderCountrySubscription) {
      this.selectedSenderCountrySubscription.unsubscribe();
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { SendingCountryResolver } from './shared/sendingcountry-resolver.service';
import { PayingCountryResolver } from './shared/payingcountry-resolver.service';
import { LoginComponent } from './components/login/login.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { HelpComponent } from './components/help/help.component';
import { CalciComponent } from './components/calci/calci.component';
import { SendingCountryForCalcResolver } from './shared/sendingcountryforcalc-resolver.service';
import { Covid19Component } from './components/covid19/covid19.component'
import {UnderMaintenanceComponent} from './components/under-maintenance/under-maintenance.component';
import {LogoutComponent} from "./components/logout/logout.component";

const appRoutes: Routes = [
//   {
//     path: '',
//     loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
//     resolve: { sendingCountry: SendingCountryResolver },
// },
  {    path: '',
    loadChildren: () => import('./components/homegeneral/homegeneral.module').then(m => m.HomegeneralModule),
    resolve: { sendingCountry: SendingCountryResolver },
  },
  {
    path: 'send-money',
    component: CalciComponent,
    resolve: { sendingCountry: SendingCountryForCalcResolver, payingCountry: PayingCountryResolver },
    children: [
      {
        path: ':country', // :country is dynamic here
        component: CalciComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'HowItWorks',
    loadChildren: () => import('./components/howitworks/howitworks.module').then(m => m.HowitworksModule),
  },
  {
    path: 'contactus',
    component: ContactusComponent,
    loadChildren: () => import('./components/contactus/contactus.module').then(m => m.ContactusModule),

  },
  {
    path: 'aboutus',
    loadChildren: () => import('./components/aboutus/aboutus.module').then(m => m.AboutusModule),
  },
//  {
//    path: 'ceostatement',
//    loadChildren: () => import('./components/ceostatement/ceostatement.module').then(m => m.CeostatementModule),
//   },
  {
    path: 'privacypolicy',
    loadChildren: () => import('./components/privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./components/terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: 'cookiespolicy',
    loadChildren: () => import('./components/cookiespolicy/cookiespolicy.module').then(m => m.CookiespolicyModule),
  },
  {
    path: 'faqs',
    loadChildren: () => import('./components/faqs/faqs.module').then(m => m.FaqsModule),
  },
  {
    path: 'help',
    component: HelpComponent,
    loadChildren: () => import('./components/help/help.module').then(m => m.HelpModule),
  },
  {
    path: 'partners',
    loadChildren: () => import('./components/partners/partners.module').then(m => m.PartnersModule),
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./components/sitemap/sitemap.module').then(m => m.SitemapModule),
  },
  {
    path: 'complaintspolicy',
    loadChildren: () => import('./components/complaintspolicy/complaintspolicy.module').then(m => m.ComplaintspolicyModule),
  },
  {
    path: 'refundpolicy',
    loadChildren: () => import('./components/refundpolicy/refundpolicy.module').then(m => m.RefundpolicyModule),
  },
  {
    path: 'news/update-on-covid-19',
    component: Covid19Component,
    loadChildren: () => import('./components/covid19/covid19.module').then(m => m.Covid19Module),
  },
  {
    path: 'under-maintenance',
    component: UnderMaintenanceComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '**',
    loadChildren: () => import('./components/pagenotfound/pagenotfound.module').then(m => m.PagenotfoundModule),
    pathMatch: 'full'
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabled',
    })],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}

<div *ngIf="selectedSendingCountry?.country?.countryId != 13">
    <div class="container">
        <div class="row mb-5">
<!--            <div class="col-md-12">-->
<!--                <p>-->
<!--                    <button class="btn btn-light btn-block alert-secondary" type="button" data-toggle="collapse"-->
<!--                        data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">-->
<!--                        <i class="fa fa-chevron-down float-right text-success pt-3"></i>-->
<!--                        <h3 class="text-success text-left pt-1" i18n>Support Numbers</h3>-->
<!--                    </button>-->
<!--                </p>-->
<!--                <div class="collapse show " id="collapseExample">-->
<!--                    <div class="col-md-6 card card-body d-inline-flex" *ngFor="let support of supportList">-->
<!--                        <h4>{{support.countryName}}</h4>-->
<!--                        <a *ngFor="let supportNumber of support.supportNumbers" class="nav-link pl-0" href="tel:{{supportNumber.number}}" (click)="gtagEvent(supportNumber.number)"><img alt="flag" src="{{supportNumber.flagUrl}}" class="float-left mr-3" width="30px">-->
<!--                        <h5 class="pt-1">{{supportNumber.number}}</h5></a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeclientComponent } from './homeclient.component';
import { RouterModule } from '@angular/router';
import { HomeModule } from '../home/home.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HowitworksModule } from '../howitworks/howitworks.module';

@NgModule({
  declarations: [HomeclientComponent],
  imports: [
    RouterModule.forChild([
      {
          path: '',
          component: HomeclientComponent
      }
  ]),
    CommonModule,HomeModule,FormsModule,
    ReactiveFormsModule, HowitworksModule,
    MatProgressSpinnerModule,
  ],
  exports: [HomeclientComponent]
})
export class HomeclientModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeclientgeneralComponent } from './homeclientgeneral.component';
import { HomegeneralModule } from '../homegeneral/homegeneral.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { HowitworksModule } from '../howitworks/howitworks.module';

@NgModule({
  declarations: [HomeclientgeneralComponent],
  imports: [
    RouterModule.forChild([
      {
          path: 'home1',
          component: HomeclientgeneralComponent
      }
  ]),
    CommonModule, HomegeneralModule,FormsModule,
    ReactiveFormsModule, HowitworksModule,
    MatProgressSpinnerModule,
  ],
  exports: [HomeclientgeneralComponent]
})
export class HomeclientgeneralModule { }

<div class="position-relative overflow-hidden bg-light">
    <div class="col-md-12 mx-auto" id="title">
        <h2 class="p-4 text-center" i18n>Page Not Found</h2>
    </div>
  </div>

<div class="container minbody">
    <div class="row my-5 py-5">
        <div class="col-md-12">
  <div class="title-bar clearfix" id="title">
    <h1 i18n>Sorry! The page you are looking for is unavailable</h1>
  </div>
  <br/>
  <p i18n>Thank you for using Taaj. </p>
</div>
</div>
</div>

export class ApiResponse {
  constructor(public err: any, public result: any) {}

  static fromJson({err, result}): ApiResponse {
    return new ApiResponse(err, result);
  }

  public static toJson(apiResponse: ApiResponse): ApiResponse { // do data manipulation before sending data to api
    return apiResponse;
  }
}

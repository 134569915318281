<div class="position-relative overflow-hidden bg-light">
  <div class="col-md-12 mx-auto" id="title">
    <h2 class="p-4 text-center" i18n>BankId Signup</h2>
  </div>
</div>

<div class="container minbody my-5">
  <div class="row">
    <div class="col-md-8 py-5">
      <h5 class="mb-5" i18n>Complete your bankid registration</h5>
      <form name="signUpForm" id="signUpForm" class="form-horizontal" [formGroup]="form" *ngIf="form" novalidate
        autocomplete="off" (ngSubmit)="signUp()">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label" i18n>Full Name: <span class="asteriskField">*</span></label>
          <div class="controls col-md-6">
            <input [readonly]="true" type="text" class="form-control" id="fullName" name="fullName" autofocus
              formControlName="fullName" />
            <app-showerror [control]="form.get('fullName')"></app-showerror>
          </div>
        </div>

        <div class="form-group row required">
          <label class="col-sm-4 col-form-label  requiredField" i18n>Confirm Mobile No:
            <span class="asteriskField">*</span>
          </label>

          <div class="controls col-md-6 mobileMoney">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text country_code" style="padding: 0px 10px !important;">{{
                  selectedSendingCountry.country.countryCallingCode}} </div>
              </div>
              <input class="form-control mobilePhone" id="mobileNo" name="mobileNo" type="text" formControlName="mobileNo" />
              <app-showerror [control]="form.get('mobileNo')"></app-showerror>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label" i18n>Email: <span class="asteriskField">*</span></label>
          <div class="controls col-md-6">
            <input #email class="form-control" id="email" name="email" type="email" formControlName="email" />
            <app-showerror [control]="form.get('email')"></app-showerror>
            <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="showProgress1"></mat-progress-spinner>
            <div class="text-danger" *ngIf="isDuplicateUser === 1" i18n>Email already taken ! <a class="link" style="cursor:pointer;width:250px;color:#a94442;" (click)="recoverPassword()">Password recovery</a></div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label" i18n>Password: <span class="asteriskField">*</span></label>
          <div class="controls col-md-6">
            <input class="form-control" id="password" name="password" type="password" formControlName="password" />
            <app-showerror [control]="form.get('password')"></app-showerror>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label" i18n>Confirm Password: <span class="asteriskField">*</span></label>
          <div class="controls col-md-6">
            <input class="form-control" id="confirmPassword" name="confirmPassword" type="password" formControlName="confirmPassword" />
            <app-showerror [control]="form.get('confirmPassword')"></app-showerror>
          </div>
        </div>
        <div class="form-group pt-3">
          <div class="controls col-md-10">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="invalidCheck2" required checked>
              <label class="form-check-label control-label-turms requiredField loginForm" for="invalidCheck2" i18n>
                By submitting this form, you agree and accept the <a routerLink="/terms" title="Terms and conditions">Terms and Conditions</a> and <a routerLink="/privacypolicy" title="Privacy Policy">Privacy
                  Policy</a> and allow us to contact you by email and SMS.
              </label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="controls Signup col-md-8">
            <mat-progress-spinner [diameter]="25" mode="indeterminate" *ngIf="showProgress"></mat-progress-spinner>
            <button class="btn btn-success btn-lg regularButton" type="submit" name="Signup" id="submit-id-signup"
              [disabled]="!form.valid || disableSubmitButton"><b i18n>Submit and Create Account</b></button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-4 py-5">
      <ul class="list-group">
        <li class="list-group-item list-group-item-success"><a i18n>Help?</a></li>
        <li class="list-group-item"><a i18n>customer service</a></li>
        <li class="list-group-item greencolor"><a routerLink="/faqs" title="FAQ's" i18n>FAQ's</a></li>
        <li class="list-group-item greencolor"><a routerLink="/contactus" title="Contact us" i18n>Contact us</a></li>
      </ul>
    </div>
  </div>
</div>

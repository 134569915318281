import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SendingCountry } from '../models/sendingcountry';
import { AppDataService } from './app-data.service';
import { of } from 'rxjs';

@Injectable()
export class SendingCountryResolver  {
  constructor(private appDataService: AppDataService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SendingCountry> {
    return of(this.appDataService.getSelectedSendingCountry());
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStep, MatStepper} from '@angular/material/stepper';
import {ErrorComponent} from '../../../../components/error/error.component';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ApiResponse} from '../../../../models/apiresponse';
import {SuccessComponent} from '../../../../components/success/success.component';
import {AuthService} from '../../../../shared/auth.service';
import {MatRadioChange} from "@angular/material/radio";

@Component({
  selector: 'app-twofactorauthentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.css']
})
export class TwoFactorAuthenticationComponent implements OnInit {
  isLinear = true;
  isEnabled = 1;
  userId: string;
  userName: string;
  mobile: string;
  countryId: string;
  firstName: string;
  public loading = false;
  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('step1') step1: MatStep;
  @ViewChild('step2') step2: MatStep;
  @ViewChild('step3') step3: MatStep;
  step1Completed = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  public disableSubmitButton: boolean;
  private isLoginOTPEnabled: number;
  checkedValue: number;
  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, public dialog: MatDialog, private authService: AuthService) {}

  ngOnInit(): void {
    // this.step1.editable = false;
    // this.step2.editable = false;
    // this.step3.editable = false;

    this.route.data
      .subscribe(
        (data) => {
          if (data && data.customerUser.err) {
            this.dialog.open(ErrorComponent, {
              data: {
                message: data.senderCustomer.err.message.toString()
              }
            });
            return;
          }

          if (data && data.customerUser.result) {
            this.userId = data.customerUser?.result[0]?.userId;
            this.userName = data.customerUser?.result[0]?.userName;
            this.mobile = data.customerUser?.result[0]?.mobile;
            this.countryId = data.customerUser?.result[0]?.countryId;
            this.firstName = data.customerUser?.result[0]?.firstName;
            this.isLoginOTPEnabled = data.customerUser?.result[0]?.isLoginOTPEnabled;
          }
        });
    this.checkedValue = this.isLoginOTPEnabled;
    this.firstFormGroup = this._formBuilder.group({
      isEnabled: [this.isLoginOTPEnabled == 1 ? '1' : '0', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      otp: ['', this.isLoginOTPEnabled == 1 ? Validators.required : null],
    });
  }

  sendOtp() {
    if (this.firstFormGroup.valid) {
      const formValues = this.firstFormGroup.value;
      const loginOTP = formValues.otp;
      this.loading = true;
      this.authService.senOtpByEmail_Api(this.userName, this.userId, loginOTP, this.mobile, this.countryId,
        Number(this.firstFormGroup.get('isEnabled').value), this.firstName)
        .then((apiResp: ApiResponse) => {
          this.loading = false;
          this.step2.editable = true;
          if (apiResp.err) {
            this.disableSubmitButton = false;
            this.dialog.open(ErrorComponent, {
              data: {
                message: apiResp.err.message.toString()
              }
            });
            return;
          }
          if (apiResp.result) {
            this.navigateToLastStep();
          }
        });
    }
  }

  verifyOtp() {
    if (this.secondFormGroup.valid) {
      this.disableSubmitButton = true;
      const formValues = this.secondFormGroup.value;
      const isLoginOTPEnabled = this.firstFormGroup.get('isEnabled').value;
      const loginOTP = formValues.otp;
      this.userName = this.userName;
      this.userId = this.userId;
      this.loading = true;
      this.authService.verifyOtp_Api(this.userId, this.userName, loginOTP , isLoginOTPEnabled, 0)
        .then((apiResp: ApiResponse) => {
          this.loading = false;
          if (apiResp.err) {
            this.disableSubmitButton = false;
            this.dialog.open(ErrorComponent, {
              data: {
                message: apiResp.err.message.toString()
              }
            });
            return;
          }
          this.stepper.next();
        });
    }
  }

  navigateToLastStep() {
    if (this.checkedValue == 1) {
      this.stepper.next();
    } else {
      const lastStepIndex = this.stepper?._steps?.length - 1;
      this.stepper.selectedIndex = lastStepIndex || 2;
    }
  }

  updateValidity(event) {
    this.checkedValue = event;
    if(this.checkedValue == 1){
      this.secondFormGroup.controls['otp'].setValidators(Validators.compose([Validators.required]));
      this.secondFormGroup.controls['otp'].updateValueAndValidity();
      this.step2.completed = false;
    } else {
      this.step2.completed = true;
      this.secondFormGroup.controls['otp'].setValidators(null);
      this.secondFormGroup.controls['otp'].updateValueAndValidity();
    }
  }
}

export const unEncryptedEndpoints = [
  '/orm/lookups/getsendingcountries',
  '/orm/lookups/getsendingcountryconfig',
  '/orm/lookups/getpayingcountryremittancetypes',
  '/orm/lookups/getservicefees',
  '/orm/lookups/getcalculatedamountandfees',
  '/orm/lookups/validateminandmaxamount',
  '/createToken',
  '/getUserToken',
  '/logout',
  '/bankid/no/oidc/login',
  '/orm/usermanagement/signupbankid',
  '/orm/usermanagement/forgotpassword',
  '/orm/usermanagement/verifyemail',
  '/orm/usermanagement/checkemailisregistered',
  '/orm/usermanagement/migrateonlinecustomer',
  '/orm/lookups/getservicenotifications',
  '/orm/lookups/getcountrystates',
  '/orm/lookups/redirectbydomain',
  '/orm/lookups/filtersendingcountriesbydomain',
  '/orm/lookups/checkip',
  '/orm/lookups/checklocation',
  '/orm/lookups/getiplocation',
  '/orm/usermanagement/migrateormuser',
  '/orm/usermanagement/deactivateormuseraccount',
  '/loginotpverification',
  '/sendsmsotptoenableappauth',
  '/sendormotpbyemail',
  '/ormotpverification',
];

export const instantPayingCountries = [
  {pCountryId: 129},
  {pCountryId: 211},
  {pCountryId: 9999},
  {pCountryId: 77},
  {pCountryId: 242},
  {pCountryId: 184}
];

export const paymentGateways = {
  netaxept: 1,
  poli: 2,
  worldpay: 3
};

export const remittanceSubTypes = {
  MPESA: 1,
  SOMALIA: 2,
  Waafi_Call: 3,
  Waafi_Wallet: 4,
  Cash_Collection: 5,
  Bank_Deposit: 6
};

export const languages = {
  en: 1,
  so: 2
};

export const customerStates = {
  not_verified: 1,
  verified: 2,
  rejected: 3
};

export const APP_DATE_FORMATS = {
    parse: {
      dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
      dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
      monthYearLabel: { year: 'numeric' }
    }
};

export const NEW_APP_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
},
display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
},
};

export const checkCountryId = {
  somalia: 211,
  somaliland: 9999
}
export const checkCountryIdValue = Object.values(checkCountryId);

export const remittanceTypes = [
{ remittanceTypeId: 1, remittanceType: "Mobile Money" },
{ remittanceTypeId: 2, remittanceType: "Cash Collection" },
{ remittanceTypeId: 3, remittanceType: "Cash Collection to partner" },
{ remittanceTypeId: 4, remittanceType: "Bank Transfer" },
{ remittanceTypeId: 5, remittanceType: "Airtime recharge" }
]

export const siteLanguages = ['en'];
export const siteRegions = ['gb', 'us', 'ca', 'au'];
